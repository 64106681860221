.suggestion {
  position: relative;
}

.suggestion__input {
  width: 246px;
  height: 30px;

  font-size: 16px;

  &::placeholder {
    color: #c4c4c4;
  }
}

.suggestion__listContainer {
  position: absolute;
  z-index: 1;

  overflow: scroll;

  width: 246px;
  max-height: 210px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.suggestion__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  padding: 6px 15px;

  text-align: center;

  background-color: #fee5a6;

  font-size: 16px;

  &:hover {
    background-color: var(--mock-color);
  }
}
