.teacherAssignment__message {
    border-bottom: 1px dashed #ccc;
    padding: 0 0 18px 0;
}

.teacherAssignment__message:last-child {
    border: none;
}

.teacherAssignment__profile {
    display: flex;
    align-items: center;
    gap: 12px;
}

.teacherAssignment__name {
    font-weight: 600;
    font-size: 16px;
}
