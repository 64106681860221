.minMax__container {
    display: flex;
    align-items: center;
}

.minMax__input {
    width: 59px;
    height: 28px;
}

.blackCrossSpaceMinMax_lessThanTen {
    width: 26px;
    height: 26px;
  }

.blackCrossSpaceMinMax_moreThanTen {
    width: 26px;
    height: 26px;
}