.gradingMenu__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 155px;
    height: 36px;
    margin: 0 auto;
    margin-top: 24px;
    border-radius: 10px;
    font-size: 14px;
    color: #fff;
    position: relative;
}

.gradingMenu__button_isEditButton {
    border: 3px solid transparent;
    background: #fff;
    color: #0B42DC;

    &::before {
        content:attr(data-text);
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: -5px;
        border-radius: inherit;
        background: var(--main-gradient);
    }
}
