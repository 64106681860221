.slotAndModuleParameters {
  width: 786px;
  margin-top: 40px;
  margin-bottom: 80px;
}

.slotAndModuleParameters__buttonGroup {
  display: flex;
  flex-direction: row;

  margin-bottom: 16px;
}

.slotStepButton {
  padding: 0;

  font-size: 24px;
}

.slotStepButton_active {
  font-weight: 700;
}

.moduleStepButton {
  font-size: 24px;
}

.moduleStepButton_active {
  font-weight: bold;
}
