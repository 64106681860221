.skillList {
  width: 786px;
}

.skillList__container {
  position: relative;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: var(--default-shadow);
}

.skillList__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px 0;
  font-size: 24px;
  font-weight: 700;
}

.skillList__editIcon {
  cursor: pointer;
}
