.formatsCardItem {
    display: flex;
}

.formatsCardItem__title {
    display: flex;
    align-items: center;

    width: 80%;
    margin-right: 8px;

    cursor: pointer;
    word-wrap: anywhere;

    font-size: 14px;
    font-weight: 400;

    &:hover {
        text-decoration: underline;
    }
}