

.external-module__form {
    max-width: 1024px;
    padding: 40px;
    display: flex;
    flex-direction: column;
}

.external-module-form__label {
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 12px;
    display: block;

    &_inline {
        margin-bottom: 0;
        max-width: 400px;
    }
}

.external-module-form__status {
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 40px;

    > span {
        font-weight: 400;
    }

    .external-module-status_revision {
        color: #FF0000;
    }

    .external-module-status_approved {
        color: #18A659;
    }

    .external-module-status_verification {
        color: #fca800;
    }

    .external-module-status_draft {
        color: #0B42DC;
    }
}

.external-module-form__row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;
    gap: 15px;
    position: relative;
}

.external-module-form__link {
    text-overflow: ellipsis;
    width: calc(100% - 50px);
}

.external-module-form__copy-button {
    position: relative;
    width: 32px;
    height: 32px;
    margin: 2px;
    cursor: pointer;
}

.copy-button__item {
    background-color: rgba(239, 239, 239, 0.7);
    box-shadow: var(--default-shadow);
    border: 1px solid #D9D9D9;
    width: 24px;
    height: 24px;
    position: absolute;

    &:nth-of-type(2) {
        top: 8px;
        left: 8px;
    }
}

.copy-button__notification {
    position: absolute;
    top: -20px;
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 600;
    color: seagreen;
}

.external-module-form__full-name {
    height: 40px;
    border-radius: 15px;
}

.external-module-form__textarea {
    resize: vertical;
    width: 100%;
    min-height: 56px;
    height: auto;
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    outline: none;
    padding: 8px 12px;
    border: none;
    background: #FFFFFF;

    box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
    border-radius: 15px;

    &::placeholder {
        font-family: 'Manrope';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #C4C4C4;

        letter-spacing: -0.03em;
    }
}

#min-student-count,
#max-student-count,
#max-wave-count,
#credit-count {
    width: 72px;
    border-radius: 10px;
    margin: 0 0 0 15px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -moz-appearance: textfield;
        -webkit-appearance: none;
        margin: 0;
    }
}

.external-module-form__input {
    height: 36px;
    width: 100%;
    padding: 8px 12px;
    background: #FFFFFF;

    box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
    border-radius: 15px;
    border: none;
    outline: none;
    font-size: 14px;

    &::placeholder {
        font-family: 'Manrope';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #C4C4C4;

        letter-spacing: -0.03em;
    }
}

.external-module-form__input-container_column {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    position: relative;
}

.external-module-form__input-container_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0;
    position: relative;
}

.external-module-form__field-error {
    position: absolute;
    color: #E05157;
    font-size: 12px;
    bottom: -2em;
}