.row {
    position: relative;

    display: grid;
    grid-template-columns: 276px 76px max-content;
}

.row__attendance {
    display: flex;
}


.row__absences, .row__student_name {
    border-right: 1px solid #ECEAE9;
    border-bottom: 1px solid #ECEAE9;

    padding: 9px 0 11px 0;

    font-size: 14px;
    line-height: 17px;
    font-family: Exo-2, sans-serif;
    color: #000;
    //opacity: .6;
}

.row__absences {
    position: sticky;
    left: 276px;
    z-index: 3;

    background-color: #F9F9F9;

    padding-left: 9px;
    //opacity: 0.6;

    &_active {
        background-color: #F8F9FE;
    }
}

.row__student {
    position: sticky;
    left: 0;
    z-index: 3;

    background-color: #fff;

    display: grid;
    grid-template-columns: 20px 242px;
    align-items: center;

    padding-left: 14px;

    &_active {
        background-color: #F8F9FE;
    }

    &:hover {
        color: #0B42DC;
    }
}

.row__student_name {
    padding-right: 10px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.row__student:hover {
    display: grid;
    grid-template-columns: 20px 242px;
    align-items: center;

    padding-left: 14px;
}

.row__student:hover > .row__student_link > .row__student_name {
    color: #0B42DC;
}

.row__student_icon {
    position: relative;

    left: -100px;
    z-index: -1;
}

.row__student:hover .row__student_icon {
    z-index: 4;

    left: 0;
}

.row:hover {
    background-color: #F8F9FE;
}

.row:hover .row__student, .row:hover .row__absences {
    background-color: #F8F9FE;
}

.borderBottom_none {
    border-bottom: none;
}
