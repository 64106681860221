.radioButton {
    padding: 8px 12px;

    border-bottom: 1px solid rgba(0, 0, 0, 0.32);
    &:last-child {
        border-bottom: none;
    }
}

.radioButton__label {
    width: 217px;
    cursor: pointer;
    color: #000;
    font-size: 14px;
}

.radioButton__input {
    position: relative;

    width: 12px;
    height: 12px;
    margin-right: 12px;

    outline: none;

    appearance: none;
    &::before {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 12px;
        height: 12px;

        content: '';
        transform: translate(-50%, -50%);

        border: 1px solid #000;
        border-radius: 50%;
        background-color: white;
      }
    &:checked::after {
        position: absolute;
        top: 50%;
        left: 50%;

        visibility: visible;

        width: 8px;
        height: 8px;

        content: '';
        transform: translate(-50%, -50%);

        border-radius: 50%;
        background-color: #000;
      }
}