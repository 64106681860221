.loader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.loader__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.schedulePage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.schedulePage__mainBlock {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 24px;
    margin: 24px 0;
}

@media print {
    .schedulePage {
        margin-top: 0;
    }

    @page {
        margin: 0mm;
        size: auto;
    }
}
