.regularEquipmentList {
    width: 100%;
    padding-top: 16px;
    background-color: white;
}

.regularEquipmentList__title {
    display: flex;
}

.regularEquipmentList__titleLabel {
    font-family: "Exo-2";
    font-size: 17px;
    font-weight: 600;
    position: relative;
    top: -1px;
    cursor: pointer;
}

.regularEquipmentList__plusButton {
    width: 19px;
    height: 19px;
    cursor: pointer;
    position: relative;
    left: 5px;
    top: 3px;
}

.regularEquipmentList__list {
    width: 100%;
    border-top: 1px solid rgba(236, 234, 233, 1);
}
.regularEquipmentList__accordionHeader {
    display: flex;
    align-items: center;
    padding: 0 0 20px;
    width: max-content;

    cursor: pointer;
}

.regularEquipmentList__plusButton svg {
    fill: #6D6E6F;
}

// .regularEquipmentList__list > div:nth-child(1){
//     border-top: 1px solid rgba(236, 234, 233, 1);
// }

.regularEquipmentList__addEquipment {
    width: 100%;
    padding: 11px 0px 12px 19px;
    border-bottom: 1px solid rgba(236, 234, 233, 1);

    cursor: pointer;
}

.regularEquipmentList__addEquipment:hover {
    background: #F8F9FE;
}

.regularEquipmentList__addEquipmentLabel {
    font-family: 'Exo-2';
    font-size: 13px;
    color: rgba(11, 66, 220, 1);
}

.regularEquipmentList__title svg {
    display: none;
}

.regularEquipmentList__title:hover svg {
    display: block;
}

.regularEquipment__lineContainer {
    width: 100%;
    overflow: hidden;
    position: relative;
    top: 0;
}

.regularEquipment__line {
    height: 1px;
    background-color: rgba(236, 234, 233, 1);
    margin-left: 30px;
}

.regularEquipment__line_hidden {
    background-color: rgba(11, 66, 220, 0.03);
}
