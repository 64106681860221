%fs18 {
    font-size: 18px;
  }

.select {
    display: flex;
    flex-direction: column;
    @extend %fs18;
}

.select_number {
    align-items: center;
    flex-direction: row;
}

.select__item {
    box-sizing: border-box;
    width: 180px;
    height: 30px;
    margin: 10px auto;
    cursor: pointer;
    outline: none;
    border: 1px solid #000;
    background-color: #fff;
    font-size: 16px;
}

.select__item_number {
    width: 48px;
}

.select__item_empty {
    color: #E5E5E5;
}

.select__label {
    max-width: 390px;
    margin: 0 10px 5px 0;

    color: #000;

    @extend %fs18;
}

.select__label_number {
    margin-bottom: 0;
}

.select__option {
    color: #000;

    @extend %fs18;
}

.select__defaultOption {
    display: none;
}