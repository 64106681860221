.nonWorkingHours {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.nonWorkingHours__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.nonWorkingHours__title {
  font-size: 18px;
  font-family: 'Exo-2';
  font-weight: 600;
}

.nonWorkingHours__titiles {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.nonWorkingHours__subtitle {
  display: flex;
}

.nonWorkingHours__title_bold {
  font-weight: 700;
}

.nonWorkingHours__preview {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.nonWorkingHours__item {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 8px;
}

.nonWorkingHours__input {
  max-width: 213px;
  width: 100%;
  height: 30px;
  padding: 7px 4px;
  margin-bottom: 12px;
  border: 1px solid #000;

  &::placeholder {
    font-size: 14px;
    color: #C4C4C4;
  }
}

.nonWorkingHours__time {
  font-size: 14px;
}
