.trajectoryContent {
    max-width: 516px;
    width: 100%;
}

.trajectoryContent__title {
    font-family: 'Exo-2';
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 24px;
}

.trajectoryContent__area {
    min-height: 381px;
    background: #FFF;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trajectoryContent__area_edit {
    box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
}

.trajectoryContent__area_active {
    align-items: flex-start;
    padding: 40px 55px 88px;
    justify-content: flex-start;
}

.trajectoryContent__disabled {
    max-width: 356px;
    color: #C4C4C4;
    text-align: center;
    align-self: center;
}

.trajectoryContent__inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.trajectoryContent__input {
    margin-bottom: 24px;
}