.filters {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    // width: 50%;
}

.filter__submitFilters {
    width: 110px;
    margin-top: 30px;
    height: 40px;
}

.filters__item {
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-right: 40px;
    margin-bottom: 20px;
}

.filters__conflictTypes {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-right: 40px;
  margin-bottom: 20px;
}

.filters__itemName {
  width: 100%;
}
.filters__select {
  box-shadow: var(--default-shadow);
  border-radius: 15px;
}
.filters__checkbox {
  display: flex;
  align-items: center;
}

.filters__checkbox > div {
  margin-left: 10px;
}