.module {
    display: flex;
    flex-direction: column;
    // height: 656px;
    border: 1px solid #ccc;
    border-radius: 15px;
    background-color: #fff;
    width: 100%;

    &__buttons {
        display: flex;
        justify-content: space-around;
        padding: 18px 18px 0 18px;
    }

    &__button {
        margin-bottom: 18px;
    }
}
