.notDistributed {
    width: 390px;
}

.notDistributed__accordionHeader {
    display: flex;
    align-items: center;
    padding: 20px 0;

    cursor: pointer;
}

.notDistributed__header {
    font-size: 17px;
    line-height: 20px;
    font-weight: 600;
    color: #C11E1E;

    &_valid {
        color: #000;
    }
}

.notDistributed__item {
    padding: 10px 18px 10px 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-top: 1px solid #ECEAE9;

    &_title {
        line-height: 16px;
        font-size: 13px;
        font-weight: 500;
        color: #C11E1E;
    }

    &_valid {
        color: #0B42DC;
    }

}

.notDistributed__scroll {
    max-height: calc(100vh - 244px);
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 15px;
    }

    &::-webkit-scrollbar-track {

        background-color: #FAFAFA;
        padding-bottom: 1px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E3E3E3;

        border-radius: 10px;
        border: 3.5px solid #FAFAFA;
    }

}

.notDistributed__sort {

}

.notDistributed__sort {
    display: flex;
    align-items: center;
    gap: 3px;
}

.notDistributed__sort_txt {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: #6D6E6F;
}

.notDistributed__sort_btn {
    padding: 0;

    display: flex;
    align-items: center;
}

.notDistributed__sort_btn:hover > svg > rect {
    fill: #F3F6FD;
}

.notDistributed__sort_btn:hover > svg > path {
    fill: #161719;
}

.notDistributed__sort_btn:active > svg > path {
    fill: #0B42DC;
}