.trajectoryCard {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    background: #fff;
    border-radius: 15px;
    padding: 8px 36px 62px 8px;
    margin-bottom: 24px;
    &:last-child {
        margin-bottom: 0;
    }
}

.trajectoryCard__container {
    padding-left: 32px;
    display: grid;
    grid-template-columns: 436px 1fr;
    gap: 80px;
}

.trajectoryCard__content {
    max-width: 436px;
}

.trajectoryCard__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.trajectoryCard__label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 16px;
}

.trajectoryCard__title {
    font-family: "Exo-2";
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 41px;
    word-wrap: break-word;
}

.trajectoryCard__wrapper {
    max-height: 220px;
    overflow-y: auto;
    padding-right: 10px;

    &::-webkit-scrollbar-track {
        border: 1px solid #f6f6f6;
        border-radius: 10px 30px 10px 10px;
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(196, 196, 196, 0.1);
        border-radius: 6px;
    }
}
.trajectoryCard__text {
    font-size: 14px;
    word-wrap: break-word;
}

.trajectoryCard__textAccent {
    font-weight: 600;
    font-size: 14px;
    margin-right: 4px;
}

.trajectoryCard__status {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.trajectoryCard__statusText {
    font-weight: 600;
    font-size: 14px;
}

.trajectoryCard__colorSign {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 3px;
    background-color: #ffa07a;
}

.trajectoryCard__colorSign_generation {
    background-color: var(--mock-color);
}

.trajectoryCard__subspaces {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
}

.trajectoryCard__subspace {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &:last-child {
        margin-bottom: 0;
    }
}

.trajectoryCard__SubspaceColorSign {
    height: 12px;
    width: 12px;
    border-radius: 4px;
    margin-right: 4px;
    margin-top: 4px;
}

.trajectoryCard__edit {
    position: relative;
    margin-bottom: 24px;
    background-color: #fff;
    border-radius: 15px;
    padding: 52px 24px 48px 40px;
}

.trajectoryCard__icon {
    position: absolute;
    top: 15px;
    right: 13px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.trajectoryCard__forwardIcon {
    position: absolute;
    right: 36px;
    bottom: 20px;
    cursor: pointer;
    width: 30px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
