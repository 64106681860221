.schedulePage_meetingSection_addNote {
    width: 100%;
    padding: 0;
    text-align: left;
    font-size: 14px;
}

.schedulePage_meetingSection_saveNote {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    position: relative;
}

.schedulePage__meetingSection_textNote {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 0;
    background: var(--main-gradient);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    justify-content: flex-end;
    font-weight: 600;
}

.textNote__btn_edit {
    position: absolute;
    transform: translateY(-30px) translateX(25px);
}

.schedulePage_eventsSection_saveNote {
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
}