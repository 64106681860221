.slot {
  position: relative;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  width: 24px;
  height: 24px;

  cursor: pointer;

  border: 1px solid #000000;
}

.slot_active {
  border: 4px solid #000000;
}

.slot_withoutPointer {
  cursor: auto;
}

.slot_readyToRemove {
  cursor: pointer;

  border: 4px solid #ff0000;
}

.slot_relationMode {
  border: 1px solid #c4c4c4;
  background: #ffffff;
}

.slot_setPrevious {
  cursor: auto;

  border: 1px solid var(--mock-color);
  background: var(--mock-color);
}

.slot_readyToConnect {
  border: 1px solid #c4c4c4;
}

.slot_showSelectedRelatedSlots {
  cursor: auto;

  border: 2px solid #000000;
}

.slot__moduleName {
  position: absolute;
  font-size: 14px;
}

.slot__content {
  display: flex;
  align-content: center;
  justify-content: center;
}

.slot__content_readyToConnect {
  width: 16px;
  height: 16px;
  padding-top: 2px;

  text-align: center;
  vertical-align: center;

  border: 1px solid #000000;

  font-size: 10px;
}

.slot_filledByModule {
  background-color: var(--mock-color);
}

.slot_waitingForChangeToGroupSlot {
  cursor: pointer;
  border: 2px dashed #ff0000;
}

.slot .slot__toolTipText {
  position: absolute;
  z-index: 100;
  bottom: 125%;
  left: 50%;

  visibility: hidden;

  width: 120px;
  margin-left: -60px;
  padding: 5px 0;

  transition: opacity 0.3s;
  text-align: center;

  opacity: 0;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 6px;
  background-color: #ffffff;
}

.slot .slot__content_waitingForChangeToGroupSlot {
  border: 2px solid #ff0000;
}

.slot:hover .slot__toolTipText {
  display: block;
  visibility: visible;

  opacity: 1;
}

.slot .slot__toolTipText::after {
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 50%;

  margin-left: -5px;

  content: "";

  border-width: 5px;
  border-style: solid;
  border-color: #000000 transparent transparent transparent;
}

.slot:hover .slot__toolTipText {
  font-size: 12px;
  display: block;
  visibility: visible;

  opacity: 1;
}
