.schedulePagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;
    gap: 12px;
    // position: absolute;
    // right: 1px;
    // bottom: 1px;
    // display: flex;
    // height: 40px;
    // min-width: 520px;
    // padding-left: 20px;
}

.schedulePagination__select {
    width: 100%;
    max-width: 148px;
}

.schedulePagination__label {
    position: relative;
    top: 5px;
    margin-right: 20px;
}

.schedulePagination__pagination {
    // position: absolute;
    // right: 1px;
    // width: 420px;
    // padding-left: 30px;
}

@media screen and (max-width: 768px) {
    .pagination_default {
        width: 100%;
        min-width: auto;
    }

    .schedulePagination {
        width: 100%;
        display: flex;
        flex-direction: column;

        ul {
            width: 100%;
            max-width: 300px;
            align-items: center;
            justify-content: center;
            gap: 12px;
            margin: 0 auto;
        }
    }
}
