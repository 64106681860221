.moduleSkills__container {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  height: 400px;
  width: 100%;

  &_prerequisite{
    padding-left: 0;
    padding-right: 10px;
  }
  &_output {
    padding-left: 10px;
    padding-right: 0;
  }
}

.moduleSkills__defaultText {
  font-family: 'Exo-2';
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.03em;

  padding: 40px 0 50px;
}

.skills__container {
  overflow-y: auto;
}

.skills__container::-webkit-scrollbar {
  width: 5px;
  max-height: 15px;
}

.skills__container::-webkit-scrollbar-track {
  border: 1px solid #f6f6f6;
  border-radius: 15px;
}

.skills__container::-webkit-scrollbar-thumb {
  background: rgba(177, 177, 177, 0.2);
  border-radius: 6px;
}

.moduleSkills__title {
  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.03em;
  cursor: default;

  border-radius: 4px;

  padding: 4px 0 5px;

  &_prerequisite {
    background-color: #DBE1F0;
  }

  &_output {
    background-color: #DFEDFD;
  }
}

.skillType {
  display: flex;
  flex-direction: row;

  margin-bottom: 10px;

  font-family: "Exo-2";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  cursor: pointer;
}

.skillType__open {
  margin-bottom: 12px;
}

.skillType__title {
  margin-left: 8px;
}

.skill {
  margin-left: 27px;
  font-size: 14px;
}

.skill__level {
  display: flex;
  justify-content: space-between;

  margin-left: 8px;
}


.skill__list {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-bottom: 22px;
  max-width: 270px;
  padding-right: 10px;
}

@media screen and (max-width: 800px) {
  .moduleSkills__container {
    height: 100%;
    width: 100%;
    &_prerequisite {
      padding-right: 8px;
    }
    &_output {
      padding-left: 0px;
      padding-right: 8px;
    }
  }
}