.userCard {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 24px;
    margin-bottom: 78px;
    position: relative;
    min-width: 744px;
    border-radius: 15px;
    background-color: #fff;
}

.userCard__flex {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
}

.userCard__photo {
    margin-right: 30px;
}


.userCard__name {
    padding-bottom: 40px;
    font-family: 'Exo-2';
    font-weight: bold;
    font-size: 22px;
    letter-spacing: -0.02em;
}

.userCard__navbar {
    padding-bottom: 40px;

    font-family: 'Exo-2';
    font-weight: bold;
    font-size: 22px;
    letter-spacing: -0.02em;
}

.userCard__link {
    font-size: 22px;
}

.userCard__button {
    display: flex;
    justify-content: center;
}

.userCard__title {
    font-size: 18px;
    margin-bottom: 48px;
}

.userCard__roles_value {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    white-space: pre-line;
    font-size: 18px;
}

.userCard__login {
    position: absolute;
    top: 86px;
    right: 25px;
}
