.meetingView {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 90%;
}

.meetingView__pen {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  cursor: pointer;
  flex-shrink: 0;
}