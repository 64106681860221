.availableInterval {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 6px 20px 6px 11px;
    background-color: #B6D5FB;
}

.availableInterval__text {
    color: var(--black, #161719);
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &_bold {
        font-weight: bold;
    }
}