.equipmentTableItem {
    display: flex;
}

.equipmentTableItem > div {
    padding-top: 10px;
    padding-bottom: 8px;
}

.equipmentTableItem__name {
    width: 448px;

    padding-left: 10px;

    border-right: 1px solid #000;
}

.equipmentTableItem__unit {
    width: 155px;

    text-align: center;

    border-right: 1px solid #000;
}

.equipmentTableItem__count {
    width: 181px;
    text-align: center;
}