@import "reset";
@import "variables";
@import "fonts";

#portal-edit-root {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
}

.tooltip {
    color: #333;
    padding: 10px 20px;
    text-align: center;
    border: 1px solid #b3c9ce;
    position: fixed;
    border-radius: 4px;
    background: #fff;
    box-shadow: var(--default-shadow);
    z-index: 100;
}

* {
    backface-visibility: hidden;
}

table {
    width: 100%;
    margin: 24px 0;
    font-size: 14px;
    text-align: left;
    overflow-x: auto;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 15px;
    border: none;
    box-shadow: var(--default-shadow);

    td, th {
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 4px 12px;
        vertical-align: middle;
        background-color: #fff;

        &:last-child {
            border-right: none;
        }
    }

    thead {
        position: sticky;
        top: -6px;
        left: 0;

        tr {
            &:first-child {
                th {
                    font-family: 'Exo-2';
                    font-weight: 600;
                    font-size: 16px;
                    padding: 8px 12px;

                    &:first-child {
                        border-top-left-radius: 15px;
                    }

                    &:last-child {
                        border-top-right-radius: 15px;
                    }
                }
            }
        }
    }

    tbody {
        td {
            &:first-child {
                padding: 0;
            }
        }
    }

    tfoot {
        td {
            padding: 12px;
            border: none;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
        }
    }

    input[type="search"],
    input[type="text"] {
        width: 100%;
        height: 100%;
        font-family: "Exo-2";
        font-size: 16px;
        padding: 0;
        border: none;
        outline: none;
        box-shadow: none;
        background: none;

        &::placeholder {
            color: #bbb;
            font-family: "Exo-2";
            font-size: 16px;
        }
    }

    select {
        width: 100%;
        height: 100%;
        color: #bbb;
        font-family: "Exo-2";
        font-size: 16px;
        padding: 0;
        outline: none;
        border: none;
        box-shadow: none;
        background: none;

        &:hover {
            color: #333;
        }
    }

    .react-datepicker__close-icon {
        padding: 0 !important;
    }

    .react-datepicker-wrapper {
        width: 100% !important;
        height: 100% !important;
        box-shadow: none !important;
    }

    .react-datepicker__input-container {
        width: 100% !important;
        height: 100% !important;
        box-shadow: none !important;
    }

    .calendar__input {
        width: 100% !important;
        height: 100% !important;
        color: #bbb;
        font-family: "Exo-2" !important;
        font-size: 16px !important;
        padding: 0 !important;
        outline: none;
        border: none;
        box-shadow: none !important;
        background: none !important;

        &::placeholder {
            color: #bbb !important;
            font-family: "Exo-2" !important;
            font-size: 16px !important;
        }
    }
}
