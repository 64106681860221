.educationPeriodSchedule {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 48px;

    &__header {
        font-size: 17px;
        font-weight: 600;
    }

    &__schedule {

    }

    &__title {
        font-size: 14px;
        padding-bottom: 16px;
    }

    &__interval {
        display: flex;
        flex-direction: column;
    }

    &__addInterval {
        width: fit-content;
        font-size: 13px;
        padding: 0;
        margin: 10px 0 0 30px;
        color: #0B42DC;
    }

    &__items {
        //border-top: 1px solid #ECEAE9;
        padding: 0;
        margin-top: 15px;

        display: flex;
        flex-direction: column;
        gap: 36px;
    }
}