.tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.tab {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    padding: 18px 24px;
    background: #fff;
    border-radius: 12px;
    font-weight: 700;
    box-shadow: var(--default-shadow);
    cursor: pointer;

    &_active {
        background: var(--extra-gradient);
    }
}
