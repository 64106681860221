.evaluationHeader {
    margin: 15px 20px 34px 20px;
}

.evaluationHeader__text {
    margin-bottom: 8px;

    font-size: 14px;
    font-weight: 700;
}
