.userAbsences {
    display: flex;
    flex-direction: column;

    width: max-content;
}

.userAbsences__title {

    display: flex;
    gap: 7px;
    align-items: center;

    padding-bottom: 13px;

    border-bottom: 1px solid #ECEAE9;
}

.userAbsences__title_text {
    font-size: 17px;
    line-height: 20px;
    font-weight: 600;
}

.userAbsences__accordions {

}