.privacyPolicyPage {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px 48px;
    background: #fff;
    border-radius: 30px;
    box-shadow: var(--default-shadow);
}

.privacyPolicyPage__title {
    font-size: 22px;
    font-family: 'Manrope';
}

.privacyPolicyPage__text {
   font-size: 16px;
}
