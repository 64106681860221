@import "../../../scheduleStyles/filterColors";

.schedulePage_meetingSection_meetingData{
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    font-size: 12px;
}

.attendanceSaveEditBtn {
    text-align: center;
}

.attendanceSaveEditBtn_isEdit {
    background: #FFF;
    border: 3px solid $color_selection;

    &:hover {
        background: inherit;
    }
}

.note__button_disabled {
    opacity: .7;
}

@media screen and (max-width: 1200px) {
    .schedulePage_meetingSection {
        width: 100%;
        max-width: inherit;
        max-height: inherit;
    }
}
