@import "../../../scheduleStyles/filterColors";

.filterOption {
    display: flex;
    align-items: center;
    width: 108px;
    padding: 10px 0;
    position: relative;
    cursor: pointer;
    user-select: none;

    &::after {
        content: '';
        width: 96px;
        height: 1px;
        position: absolute;
        left: 6px;
        bottom: 0;
        background: #F6F6F6;;
    }

    &:last-of-type::after {
        display: none;
    }
}

.filterOption__circle {
    width: 10px;
    height: 10px;
    margin-left: 16px;
    border-radius: 50%;
}

.filterOption__circle_filter_all {
    background: linear-gradient(
        90deg,
        $color_filter_assignment 50%,
        $color_filter_meeting 50%,
    );

    &.filterOption__circle_isTeacher {
        background: linear-gradient(
            90deg,
            $color_filter_meeting 50%,
            $color_filter_evaluationPoint 50%,
        );
    }
}

.filterOption__circle_filter_meeting {
    background: $color_filter_meeting;
}

.filterOption__circle_filter_evaluationPoint {
    background: $color_filter_evaluationPoint;
}

.filterOption__circle_filter_assignment {
    background: $color_filter_assignment;
}

.filterOption__description {
    margin-left: 4px;
    font-size: 14px;
}
