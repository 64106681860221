.suitableModules{
    margin-bottom: 84px;
}

.suitableModules__title {
    margin-bottom: 32px;

    font-size: 18px;
    font-weight: 700;
}

.suitableModules__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.suitableModules__listItem {
    margin-bottom: 40px;
}