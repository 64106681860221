[placeholder]:focus::-webkit-input-placeholder {
    opacity: 1;
}

.input__inputItself {
    all: unset;
    width: 100%;
}

.input__inputItself::-webkit-input-placeholder {
    opacity: 1;
}