.cover__input {
  display: inline-block;

  width: 100%;
  height: 100%;

  opacity: 0;
}

.cover {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 150px;
  height: 150px;

  background-repeat: no-repeat;
  background-position: center;
}

.cover__image {
  width: 150px;
  height: 154px;
}

.cover__image:hover {
  z-index: -3;

  display: none;
  visibility: hidden;
}

.cover__input:hover {
  z-index: 3;
}
  