.commonCalendar .react-datepicker__day--selected {
    color: #FFF;
    border-radius: 0;
}

.commonCalendar .react-datepicker {
    font-family: Exo-2, sans-serif;

    width: 265px;
    height: 287px;
    max-height: none !important;

    overflow: hidden;

    border: 2px solid rgba(60, 104, 227, 1);

    border-radius: 0;
}

.commonCalendar .react-datepicker-popper[data-placement^="top"] {
    padding-bottom: 4px;
}
.commonCalendar .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 4px;
}

.commonCalendar .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
    padding-bottom: 0;
}
.commonCalendar .react-datepicker__triangle::before, .react-datepicker__triangle::after {
    //content: none;
    display: none;
}

.commonCalendar .react-datepicker__input-time-container .react-datepicker-time__input-container {
    width: 100%;
    display: flex;
    align-items: center;
}

.commonCalendar .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
}

.commonCalendar .react-datepicker__day--selected,
.commonCalendar .react-datepicker__year-text--selected,
.commonCalendar .react-datepicker__year-text--keyboard-selected {
    background-color: #0B42DC;
    color: #FFF;

    &:hover {
        background-color: #0B42DC;
        opacity: .9;
    }
}

.react-datepicker__day:hover {
    border-radius: 0;
    background-color: #DFEDFD;
}

.calendar__popperCommon .react-datepicker__day--keyboard-selected {
    background: #0B42DC;
    color: #fff;
    border-radius: 0;

    &:hover {
        background-color: #0B42DC;
        opacity: .9;
    }
}


.commonCalendar .react-datepicker__close-icon::after {
    color: #fff;
    background-color: #0B42DC;

    font-size: 13px;
    line-height: 16px;
}
.commonCalendar .react-datepicker__input-container {
    display: flex;
    align-items: center;

    margin-bottom: 0;
    border-radius: 0;
    height: 38px;
    width: 100%;

    box-shadow: none;
}
.calendar__wrappercommon .calendar__input {
    width: 100%;
    height: 100%;
    padding: 9px 14px 8px 10px;
    margin: 0;

    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.03em;
    font-weight: 500;
    font-family: Exo-2, sans-serif;

    border: 1px solid #DBE1F0;

    &::placeholder {
        color: #c4c4c4;
        font-size: 13px;
    }

    &:focus {

    }

}

.commonCalendar .react-datepicker__month-container {
    height: 100%;
    float: none;
}

.commonCalendar .react-datepicker__month {
    margin: 7px 14px 8px 14px;
}

.commonCalendar .react-datepicker__day-name, .react-datepicker__day {
    margin: 2px;

    width: 30px;
    height: 30px;

    font-size: 13px;
    font-weight: 500;
    color: #161719;
    line-height: 30px;
}

.commonCalendar .react-datepicker__day-name {
    color: #A7A8AA;
    text-transform: capitalize;
}

.commonCalendar .react-datepicker__close-icon {
    padding-right: 10px;
}

.commonCalendar .react-datepicker__navigation-icon::before {
    border: none;
    width: 6px;
    height: 10px;
    background-image: url("PreviousArrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.commonCalendar .react-datepicker__year-read-view--selected-year {
    font-size: 16px;
    line-height: 19px;
    font-family: Exo-2, sans-serif;
    font-weight: 500;
}

.commonCalendar .react-datepicker__header__dropdown, .commonCalendar .react-datepicker__header__dropdown--scroll {
    position: absolute;
    top: 14px;
    right: 110px;
}

.commonCalendar .react-datepicker__year-read-view--down-arrow {
    border: none;

    top: 8px;

    background-image: url("YearDropDownArrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    transform: none;
}

.commonCalendar .react-datepicker__year-dropdown {
    width: 72px;
    height: 148px;

    overflow-y: scroll;

    background-color: #FFF;

    top: 22px;
    left: 4px;

    border: 1px solid rgba(11, 66, 220, 0.3);
}

.commonCalendar .react-datepicker__year-option--selected {
    left: 0;
    top: 0;

    color: transparent;

    background-color: rgba(11, 66, 220, 0.05);

    padding: 5px 0 8px 0;

    width: 100%;

    &:hover {
        background-color: transparent;
    }
}

.commonCalendar .react-datepicker__year-option {
    position: relative;

    padding: 5px 19px 8px 11px;
    margin: 0;

    width: 60px;

    font-size: 13px;
    line-height: 16px;
    color: #161719;
    font-family: Exo-2, sans-serif;

    &:first-child {
        display: none;
    }

    &:last-child {
        display: none;
    }
}

.commonCalendar .react-datepicker__year-read-view {
    visibility: visible !important;
}

.commonCalendar .react-datepicker__year-option:hover {
    background-color: rgba(11, 66, 220, 0.05);
}

.commonCalendar .react-datepicker__navigation--years {
    display: none;
}

.commonCalendar .react-datepicker__navigation--previous {
    right: 39px;
    left: unset;
}

.commonCalendar .react-datepicker__navigation-icon--next {
    left: 0;
}

.commonCalendar .react-datepicker__navigation-icon--previous::before {
    transform: none;
}

.commonCalendar .react-datepicker__navigation-icon--next::before {
    transform: rotate(180deg);
}

.calendar__popperCommon .react-datepicker__current-month {
    width: fit-content;
    margin-left: 20px;

    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: #161719;
    text-transform: capitalize;
}

.commonCalendar .react-datepicker__header {
    padding-top: 15px;
}

.commonCalendar .react-datepicker__navigation {
    top: 11px;
}

.commonCalendar .react-datepicker__day--disabled, .commonCalendar .react-datepicker__day--excluded {
    color: #AF5555;
    opacity: .3;

    &:hover {
        background-color: transparent;
    }
}

.commonCalendar .react-datepicker__day--outside-month {
    color: #C7C7C7;
}

