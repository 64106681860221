.moduleMainCharactersTeacher__title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  
  .moduleMainCharactersTeacher__empty {
    font-size: 20px;
    font-weight: 700;
    margin-top: 40px;
    text-align: center;
  }
  
  .moduleMainCharactersTeacher__list {
    height: 147px;
    font-size: 14px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-left: 12px;
  }
  
  .moduleMainCharactersTeacher__list::-webkit-scrollbar {
    display: none;
  }
  
  .moduleMainCharactersTeacher__item {
    display: flex;
    align-items: center;
    min-height: 55px;
    padding: 7px 7px 7px 23px;
    margin:0 5px 4px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
  }
  
  .moduleMainCharactersTeacher__photo {
    min-width: 40px;
    min-height: 40px;
    margin-right: 14px;
  }
  
  .moduleMainCharactersTeacher__teacherName {
    font-weight: 700;
    word-break: break-word;
  }
  
  .moduleMainCharactersTeacher__teacherRole {
    word-break: break-all;
  }
  