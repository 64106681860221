.actionButton {
    height: 42px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    border-radius: 12px;
    padding: 0 24px;
    cursor: pointer;
    position: relative;
    background: transparent;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 12px;
        padding: 3px;
        box-sizing: border-box;
        background: var(--main-gradient);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
    }
}

.actionButton__primary {
    color: #fff;
    background: var(--main-gradient);
}

.actionButton__secondary {
    border-radius: 10px;
    text-align: center;
}

.actionButton__text {
    color: transparent;
    background-clip: text;
    font-weight: 600;
    background-image: var(--main-gradient);
}
.actionButton__text_disabled {
    background-image: var(--disable-gradient);
}

.actionButton_disabled {
    cursor: default;
    background: var(--disable-gradient);
}

@media screen and (max-width: 480px) {
    .actionButton_primary {
        width: 120px;
        font-size: 12px;
    }

    .actionButton_secondary {
        width: 120px;
        font-size: 12px;
    }
}
