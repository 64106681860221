.prewiew_timeintervals {
  display: flex;
  flex-flow: column;
  gap: 12px;
}
.prewiew_title {
  display: flex;
}

.prewiew__time {
  font-weight: 600;
  font-size: 14px;
}

.prewiew_iterval {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
}

.prewiew_holiday {
  color: #c4c4c4;
}

.prewiew_box-interval {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.workingHours {
  margin-bottom: 68px;
}

.workingHours__wrapper {
  display: flex;
  gap: 24px;
}

.workingHours__title {
  font-family: 'Exo-2';
  font-weight: 600;
  font-size: 18px;
}

.workingHours__title_bold {
  font-weight: 700;
}

.workingHours__switcher {
  margin-bottom: 16px;
}

.workingHours__intervals {
  display: flex;
  justify-content: center;
}
