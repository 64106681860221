.userTypeMenu {
  padding: 6px 21px;
  width: 180px;
  left: 110px;
  border-radius: 15px;
  background: #FFFFFF;
  animation: scale-up-hor-left 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  z-index: 1;
}

@keyframes scale-up-hor-left {
  0% {
    transform: scaleX(0.4);
    transform-origin: 0% 0%;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
  }
}

.userTypeMenu__selectionDescription {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.userTypeMenu__text {
  font-weight: 400;
  color: #C4C4C4;
  font-size: 14px;
}

.userTypeMenu__icon {
  width: 14px;
  height: 14px;
  color: #C4C4C4;
  cursor: pointer;
}

.userTypeMenu__selectionList {
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100%;
}

.userTypeMenu_selectionItem {
  display: block;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}

.userTypeMenu_selectionItem_active {
  font-weight: 700;
}