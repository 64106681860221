.hidden {
    position: relative;
}

.hiddenTabs {
    display: flex;
    align-items: center;
    gap: 5px;

    padding: 7px 0 9px 0;

    text-align: center;

    pointer-events: all;

    &_open {
        display: flex;
        gap: 0;

        height: 35px;

        border-bottom: 2px solid #0B42DC;
        color: #000;
    }

}

.hiddenTabs > svg {
    pointer-events: none;
}

.hiddenTabs_active {
    display: flex;
    gap: 5px;

    height: 35px;

    width: 140px;

    border-bottom: 2px solid #0B42DC;
    color: #000;
}

.hiddenTabs__title {
    line-height: 17px;
    font-size: 14px;
    color: #6D6E6F;
    font-weight: 500;
    font-family: Exo-2, serif;

    max-width: 121px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    pointer-events: none;
}

.hiddenOptions {
    position: absolute;
    z-index: 100;
    top: 120%;
    left: -550px;

    width: max-content;

    display: flex;
    flex-direction: column;

    background-color: #fff;

    border: 1px solid rgba(11, 66, 220, 0.3);
    border-radius: 6px;

    &_single {
        left: -320px;
    }
}

.hiddenOption {

}

.hiddenOption__link {
    font-size: 14px;
    line-height: 17px;

    padding: 6.5px 12px 6.5px 12px;

    color: #6D6E6F;

    &:hover {
        background-color: #F8F9FE;
        color: #000;
    }

    &:first-child {
        border-radius: 6px 6px 0 0;
    }

    &:last-child {
        border-radius: 0 0 6px 6px;
    }
}

.hiddenOption__link_selected {
    background-color: #F8F9FE;
    color: #000;
}
