.modulesStatistic {
    display: flex;
    flex-direction: column;

    height: calc(100vh - 250px);

    &__header {
        position: sticky;
        top: 0;
        z-index: 1;

        background-color: #fff;

        display: grid;
        grid-template-columns: 419px 103px 100px;

        border-bottom: 1px solid #ECEAE9;
    }

    &__item {
        padding: 9px 0 11px 10px;

        font-size: 13px;
        line-height: 16px;
        color: #000;
        opacity: .6;

        border-right: 1px solid #ECEAE9;

        &:last-child {
            border-right: none;
        }

        &:first-child {
            padding-left: 71px;
        }
        &_bright {
            opacity: 1;
        }
    }

    &__accordions {
        display: flex;
        flex-direction: column;
        gap: 36px;

        height: 100%;
        overflow-y: auto;

        padding: 16px 0 0 30px;

        &_unScroll {
            overflow-y: unset;
        }

        &::-webkit-scrollbar {
            width: 15px;
        }

        &::-webkit-scrollbar-track {

            background-color: #FAFAFA;
            padding-bottom: 1px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #E3E3E3;

            border-radius: 10px;
            border: 3.5px solid #FAFAFA;
        }
    }
}