.field {
  display: flex;
  flex-direction: column;

  margin-top: 24px;
}

.field__label {
  font-size: 18px;
}

.field__suggestionRow {
  display: flex;
  flex-direction: row;

  height: 30px;
}

.field__input {
  width: 400px;
  min-width: 320px;
  margin-top: 3px;

  font-size: 16px;
}

.field__addButton {
  margin-top: 6px;
  margin-left: 8px;
}

.suggestionList {
  width: 100%;
}

.suggestionList__item {
  margin: 0;
  padding: 4px;

  font-size: 16px;
}

.selectedList {
  width: 400px;
  min-width: 320px;
  margin-top: 8px;
}

.selectedList__item {
  position: relative;

  margin-top: 2px;
  padding: 4px;

  background-color: #ffd97e;
}

.selectedList__name {
  margin-right: 4px;
}

.selectedList__level {
  font-weight: 700;
  margin-right: 2px;
}

.selectedList__item:hover {
  background-color: var(--mock-color);
}

.selectedList__icon {
  position: absolute;
  right: -10px;
}
