.module__name {
    border: 1px solid #B0B0B0;
    border-bottom: none;
    font-size: 12px;
    width: 150px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.module__name::-webkit-scrollbar {
    display: none;
}