.Controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 768px) {
    .Controls {
        justify-content: stretch;
        flex-direction: column-reverse;
        gap: 24px;
    }
}
