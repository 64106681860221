.formatsCardDialog {
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, .4);
}

.formatsCardDialog__container {
    position: relative;

    min-width: 786px;

    border: 1px solid #000;
    background-color: #fff;
}

.formatsCardDialog__content {
    display: flex;
    align-items: center;
    flex-direction: column;

    margin: 21px 60px 26px;

    font-size: 18px;
}

.formatsCardDialog__title {
    margin-bottom: 34px;

    font-size: 24px;
    font-weight: 700;
}

.formatsCardDialog__input {
    width: 360px;
    height: 30px;
    margin-bottom: 30px;
}

.formatsCardDialog__icon {
    position: absolute;
    top: 22px;
    right: 22px;
}