.specialDays {
    padding: 40px 50px;
    background: #FFF;
    box-shadow: var(--default-shadow);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}

.specialDays__title {
    font-weight: 700;
    font-size: 24px;
}

.specialDays__buttonPanel {
    align-self: flex-end;
    margin-right: 91px;
}


.specialDays__saveButton {
    margin-left: 25px;
}

