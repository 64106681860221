.meetingFilter {
    position: relative;
}

.menu {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 1;
    background: #FFF;
    border-radius: 15px;
    box-shadow: var(--default-shadow);
}

@media screen and (max-width: 768px) {
    .meetingFilter {
        position: relative;
        justify-content: center;
    }

    .menu {
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 15px;
        background: #FFF;
        box-shadow: var(--default-shadow);
        z-index: 2;
    }
}