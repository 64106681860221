.accordion {
    display: flex;
    flex-direction: column;
}

.accordion__header {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.accordion__polygon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    position: relative;
    left: 1px;
    margin-right: 9px;
    cursor: pointer;
}

.accordion__polygon svg {
    fill: #6D6E6F;
    min-width: 7px;
    width: 100%;
    height: 100%;
}

.accordion__polygon:hover {
    background: rgba(11, 66, 220, 0.06);
}

.accordion__polygon_highlight svg {
    fill: #0B42DC;
}

.accordion__polygon:active svg {
    fill: #0B42DC;
}

.accordion__polygon_open {
    transform: rotate(90deg);
}

.accordion__body_close {
    display: none;

    .accordion__body {
        overflow: visible;
        height: 100%;

        &_close {
            overflow: hidden;
            height: 0;
        }
    }
}