.module {
    display: flex;
    flex-direction: column;

    width: 380px;
    height: 640px;

    border: 1px solid black;
}

.module__button {
    display: flex;
    justify-content: center;

    margin-bottom: 14px;
}