.calendar__popperEducationPeriod .react-datepicker {
    font-family: Exo-2, sans-serif;
    max-height: 232.75px;
    overflow: hidden;
}

.calendar__popperEducationPeriod .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
    padding-bottom: 0;
}
.calendar__popperEducationPeriod .react-datepicker__triangle::before, .calendar__popperEducationPeriod .react-datepicker__triangle::after {
    //content: none;
    display: none;
}

.calendar__popperEducationPeriod .react-datepicker__input-time-container .react-datepicker-time__input-container {
    width: 100%;
    display: flex;
    align-items: center;
}

.calendar__popperEducationPeriod .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
}

.calendar__popperEducationPeriod .react-datepicker__day--selected,
.calendar__popperEducationPeriod .react-datepicker__year-text--selected,
.calendar__popperEducationPeriod .react-datepicker__year-text--keyboard-selected {
    background-color: #0B42DC;
    border-radius: 0;
    color: #fff;


    &:hover {
        background-color: #0B42DC;
        opacity: .9;
    }
}
.calendar__popperEducationPeriod .react-datepicker__day--keyboard-selected {
    border-radius: 0;
    background: #0B42DC;
    color: #fff;

    &:hover {
        background-color: #0B42DC;
        opacity: .9;
    }
}

.calendar__popperEducationPeriod .react-datepicker__input-time-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: calc(100% - 30px);
    margin: 5px 15px;
    padding-top: 8px;
    border-top: 1px solid #ECEAE9;
}
.calendar__popperEducationPeriod {
    z-index: 100;
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__day--in-range {
        color: #000;
        background: #afd6f0;
        border-radius: 0;
        margin: 0.166rem;
    }
    //.react-datepicker__navigation--years-previous {
    //    background-image: url(../../../../../../assets/UpDown.svg);
    //    background-repeat: no-repeat;
    //    background-position: center;
    //}
    //.react-datepicker__navigation--years-upcoming {
    //    background-image: url(../../../../../../assets/UpDown.svg);
    //    background-repeat: no-repeat;
    //    background-position: center;
    //    transform: rotate(180deg);
    //}
    .react-datepicker__current-month {
        margin-bottom: 6px;
    }
}

.calendar__wrapperEducationPeriod {
    .react-datepicker__close-icon::after {
        color: #fff;
        background-color: #0B42DC;

        font-size: 13px;
        line-height: 16px;
    }
    .react-datepicker__input-container {
        display: flex;
        align-items: center;

        margin-bottom: 0;
        border-radius: 0;
        height: 39px;
        width: 100%;

        box-shadow: none;
    }
    .calendar__input {
        width: 100%;
        height: 100%;
        padding-left: 9px;
        margin: 0;

        font-size: 13px;
        line-height: 16px;

        border: 1px solid #0B42DC;
        //border-top: none;
        //border-bottom: none;
        padding-bottom: 2px;
        padding-top: 0;

        outline: none;

        &::placeholder {
            color: #c4c4c4;
            font-size: 13px;
        }

        &:focus {

        }

    }
}

.calendar__popperEducationPeriod .calendar__input_timer {
    width: 313px;
}

.calendar__popperEducationPeriod .react-datepicker__close-icon {
    padding-right: 10px;
}
