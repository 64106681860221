.confirmPopUp {
    padding: 51px 66px 56px 66px;
    width: 521px;

    background-color: #FFF;

    border-radius: 4px;

    box-shadow: 0 2px 6px 0 rgba(24, 20, 50, 0.13);
}

.confirmPopUp__title {
    color: #000;
    text-align: center;
    font-family: Exo2, sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.51px;

    &_bold {
        font-weight: 700;
    }

    &_blue {
        color: #0B42DC;
        cursor: pointer;

        &:hover {
            text-decoration: underline #0B42DC;
        }
    }
}

.confirmPopUp__date {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #F3F6FD;
    padding: 8px 0;

    margin-top: 13px;

    color: #161719;
    font-family: Exo2, sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.51px;
}

.confirmPopUp__buttons {
    display: flex;
    justify-content: center;
    gap: 20px;

    margin-top: 20px;
}

.confirmPopUp__buttonsText {
    color: #FFF;
    font-family: Exo2, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.39px;

    &_balck {
        color: #000;
    }
}
