.Profile {
  padding: 48px;
  border-radius: 30px;
  box-shadow: var(--default-shadow);
  background: #fff;
}

.Profile__title {
  font-family: "Exo-2";
  font-weight: 700;
  font-size: 26px;
  margin: 0 0 24px 0;
}

@media screen and (max-width: 768px) {
  .Profile {
    padding: 18px;
    border-radius: 18px;
  }
}
