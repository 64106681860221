.tabs {
    margin-top: 20px;
    position: relative;
}

.tabs__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.tabs__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 3px 0;
    margin: 0 16px -1px 0;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #0d0d0d;
}

.tabs__tab_selected {
    cursor: default;
    background: var(--main-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-image-source: var(--main-gradient);
    border-bottom: 2px solid;
    border-image-slice: 1;
    border-width: 2px;
}