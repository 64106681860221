.teacher__name {
    border: 1px solid#B0B0B0;
    border-left: none;
    border-bottom: none;
    font-size: 12px;
    width: 250px;
    height: 19px;
}

.teacher__tooltip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        position: relative;
        text-overflow: initial;
        height: fit-content;
        background: #ffffff;
        white-space: normal;
        text-decoration: underline;
    }
}

.teacher__name::-webkit-scrollbar {
    display: none;
}