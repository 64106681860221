.votedChartAccordion {

    &__header {
        padding: 20px 0;

        font-size: 17px;
        font-weight: 600;
        color: #0B42DC;
        line-height: 20px;

        &_small {
            font-size: 14px;
            line-height: 17px;
            font-weight: 500;
            color: #6D6E6F;
        }
    }

    &__items {
        height: calc(100vh - 213px);

    //    80 + 72 + 61
    }

    &__scroll {

        height: 100%;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 15px;
        }

        &::-webkit-scrollbar-track {

            background-color: #FAFAFA;
            padding-bottom: 1px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #E3E3E3;

            border-radius: 10px;
            border: 3.5px solid #FAFAFA;
        }
    }
}

.accordion {
    &__header {
        padding-left: 44px;

        display: flex;
        align-items: center;

        border-bottom: 1px solid #ECEAE9;
    }
}
