.room-occupation {
  display: flex;
  flex-direction: column;
  padding: 30px 24px;
  background: #ffffff;
  border-radius: 15px;
  width: fit-content;
  min-width: 100%;
  box-shadow: var(--default-shadow);
}

.room-occupation__filters {
  width: 100%;
  display: flex;
  margin-bottom: 24px;
}

.filters__checkbox {
  width: fit-content;

  input {
    margin: 10px;
    cursor: pointer;
  }

  label {
    cursor: pointer;
  }
}

.filters__capacity {
  width: 300px;
  height: 40px;
  box-shadow: var(--default-shadow);
  font-size: 14px;
  border: 0px solid;
  padding: 0 12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  &:focus {
    outline: none;
  }
}

.filters__item {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 3;

  &:not(:last-of-type) {
    margin-right: 40px;
  }
}

.filters__itemName {
  width: 100%;
}

.filters__submit-button {
  width: 110px;
  margin: 24px 0;
  height: 40px;
}

.room-occupation__report th {
  position: sticky;
  top: 0;
  left: 0;
}

.report__head {
  top: 0;
  left: 0;
  position: sticky;
  background: #ffffff;
  z-index: 2;
}

.report-head__row:first-child .report-head__cell:last-child {
  border-top-right-radius: 10px;
}

.report-head__row:first-child .report-head__cell:first-child {
  border-top-left-radius: 10px;
}

.report-body__row:last-child .report-body__timeslot-cell:last-child {
  border-bottom-right-radius: 10px;
}

.report-head__cell {
  padding: 2px;
  font-family: 'Exo-2';
  font-weight: 600;
  font-size: 10px;
  text-align: center;
}

.report-head__legend {
  font-family: 'Exo-2';
  font-weight: 600;
  font-size: 10px;
  border: 1px solid #ccc;
}

.report-legend__item {
  display: flex;
  align-items: center;
}

.report-legend__mark {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: 1px solid black;
  margin-right: 5px;
}

.report-legend__mark_red {
  background-color: #ffcdd2e6;
}

.report-legend__mark_gray {
  background-color: #d0d0d0cc;
}

.report-legend__mark_srtiped {
  background-image: linear-gradient(135deg, #fff 37.50%, #0b42dc 37.50%, #0b42dc 50%, #fff 50%, #fff 87.50%, #0b42dc 87.50%, #0b42dc 100%);
  background-size: 3.66px 3.66px;
}

.report-body__title-cell {
  padding: 0;
  margin: 0;
  width: 150px;
  height: 20px;
  font-family: 'Exo-2';
  font-weight: 600;
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.report-body__room-title-cell {
  width: 250px;
}

.title-cell-tooltip__inner-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 100%;
  width: inherit;
  padding: 2px 5px;
}

.report-body__title-cell-tooltip {
  width: inherit;
  position: relative;
  width: 150px;
  height: inherit;
  overflow: hidden;

  .title-cell-tooltip__inner-text:hover {
    position: relative;
    text-overflow: initial;
    height: fit-content;
    background: #ffffff;
    white-space: normal;
    z-index: 2;
  }
}

.report-body__title-cell-tooltip:hover {
  overflow: visible;
  // border-bottom: 1px solid #ccc;
}

.report-body__timeslot-cell {
  width: 20px;
  height: 20px;
  padding: 0;
}

.report-body__timeslot-cell_available {
  background-color: #ffffff;
}

.report-body__timeslot-cell_non-available {
  background-color: #ffcdd2e6;
}

.report-body__timeslot-cell_reserved-by-classic {
  background-color: #d0d0d0cc;
}

.report-body__timeslot-cell_reserved-by-t {
  background-image: linear-gradient(135deg, #fff0 37.50%, #0b42dc 37.50%, #0b42dc 50%, #fff0 50%, #fff0 87.50%, #0b42dc 87.50%, #0b42dc 100%);
  background-size: 5.66px 5.66px;
}

.report-head__timeslot-cell {
  writing-mode: vertical-rl;
  text-orientation: sideways;
  transform: rotate(-180deg);
  padding: 2px;
  font-family: 'Exo-2';
  font-weight: 600;
  font-size: 10px;
  border: 1px solid #ccc;
}

.timeslot-cell__link {
  width: 100%;
  height: 100%;
  display: flex;
}