.moduleSkillsListSwitchParams {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
}

.moduleSkillsListSwitchParams__list {
    display: flex;
}

.moduleSkillsListSwitchParams__item {
    cursor: pointer;
    width: 100px;
    font-size: 14px;
}

.moduleSkillsListSwitchParams__params {
    border-bottom: 1px solid rgba(196, 196, 196, 1);
    font-weight: 400;
}

.moduleSkillsListSwitchParams__params_active {
    border-color: #000;
    font-weight: 700;
}
