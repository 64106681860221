.menuNavigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
}

.menuNavigation__item {
  width: 100%;
  padding: 8px 12px;
  font-family: "Exo-2";
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

.menuNavigation__item_point {
  position: absolute;
  right: 20px;
  bottom: 18px;

  > svg {
    display: block
  }
}

.menuNavigation__item_exit {
  font-weight: 200;
}

.menuNavigation__item_margin_zero {
  display: none;
  margin-bottom: 0;
}

.menuNavigation__roleList {
  display: flex;
  flex-direction: column;
}

.menuNavigation__roleItem {
  padding: 4px 15px;

  color: gray;

  cursor: pointer;

  &:hover {
    color: #000;
  }
}

.menuNavigation__roleItem_color_black {
  color: #000;
}

.menuNavigation__item:last-child {
  margin-bottom: 23px;
}

.menuNavigation__itemSelected {
  cursor: default;
  background: var(--main-gradient);
  box-shadow: 0 2px 6px rgba(24, 20, 50, 0.13);
  border-radius: 13px;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .menuNavigation__item_margin_zero {
    display: block;
  }

  .menuNavigation {
    padding: 12px;
  }
}
