.tables {
   display: flex;
    width: 100%;
}

.header {
    position: sticky;
    top: 37px;
    left: 0;
    z-index: 4;

    display: flex;
    align-items: center;

    border-bottom: 1px solid #ECEAE9;

    background-color: #fff;

    & > button {
        position: sticky;
        left: 0;
        z-index: 3;
        background-color: #fff;
    }
}

.table__container {
    position: relative;

    width: max-content;

    border-bottom: 1px solid #D6D6D6;
}

.table__container_noBorder {
    border: none;
}

.table {

}

.table__empty {
    position: sticky;
    left: 275px;
    z-index: 3;
    background-color: #F9F9F9;

    border-right: 1px solid #ECEAE9;
    border-left: 1px solid #ECEAE9;
}

.table__dates_container {
    position: relative;

    display: grid;
    grid-template-columns: 248px 77px max-content;
}

.table__dates {
    display: flex;

    //border-bottom: 1px solid #F3F3F3;
}

.table__rows {
}

.table__wave {
    position: sticky;
    left: 28px;
    z-index: 2;

    background-color: #fff;

    display: flex;
    align-items: center;

    padding: 19px 0 20px 0;

    font-size: 17px;
    line-height: 20px;
    font-weight: 600;
    font-family: Exo-2, sans-serif;

    &::before {
        content: "";

        position: absolute;
        z-index: 2;
        left: -28px;

        width: 28px;
        height: 58px;
        background-color: #fff;
    }
}

