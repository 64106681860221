.formatsCard {
    width: 100%;
}

.formatsCard__content {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.formatsCard__letter {
    font-size: 24px;
    font-weight: 700;
    margin: -4px 0 0 0;
}

.formatsCard__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}