.adminInfo__form {
    margin-bottom: 28px;
}

.adminInfo__item {
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
}

.adminInfo {
    padding-bottom: 50px;
}

.adminInfo__title {
    font-family: 'Exo-2';
    margin-bottom: 22px;
    font-weight: 700;
    font-size: 18px;
}

.adminInfo__title_normal {
    padding-left: 175px;
}

.adminInfo__roles {
    padding-left: 175px;
}

.adminInfo__role {
    margin-bottom: 10px;
}