.header {
    //width: calc(100% - 27px);
    display: grid;
    height: 29px;
    grid-template-columns: calc(227px - 27px) 189px 306px;

    &__item {
        padding-left: 10px;
        display: flex;
        align-items: center;

        &:first-child {
            padding-left: 0;
        }

        &_title {
            font-size: 17px;
            font-weight: 600;
        }

        &_date {
            font-size: 14px;
        }

    }

    &__schedule {
        display: flex;
        align-items: center;
        gap: 8px;

        &_txt {
            font-size: 13px;
            color: #0B42DC;
        }

        &_inactive {
            color: red;
        }
    }
}