.form {
  margin-top: 24px;
  padding: 20px 54px;
  text-align: left;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
}


.formGroupSelect {
  display: inline-block;

}

.formGroupSelect__label {
  width: 25px;
}

.formGroupSelect__select {
  width: 62px;
  height: 30px;
  margin-left: 10px;

  text-align: center;

  font-size: 16px;
}

.form__buttonsBlock {
  display: flex;
  justify-content: space-between;

  width: 350px;
  min-width: 320px;
  margin-top: 32px;
  margin-bottom: 22px;
  margin-left: 20%;
}
