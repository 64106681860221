.time {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__switcher {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__title {
            font-size: 14px;
            font-weight: 500;
        }
    }

    &__datesWithTime {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    &__dateWithTime {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;

        border: 1px solid #d2d2d2;
        background-color: #ECEAE9;
        padding: 0 5px;
        border-radius: 2px;
    }

    &__date {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: default;

        &::after {
            content: '';
            width: 1px;
            height: 70%;
            display: inline-block;
            background-color: #d2d2d2;
        }
    }

    &__input {
        cursor: pointer;
        background-color: transparent;
        outline: none;
        border: none;

        &::-webkit-calendar-picker-indicator {
            display: none;
            width: 0;
        }

        &:focus {

        }
    }
}