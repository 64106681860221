.module-statistics-report__wave-cell {
    padding: 4px 12px !important;
    font-family: 'Exo-2';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.module-statistics-report__student-cell {
    position: sticky;
    top: 0;
    left: 0;
    padding: 12px!important;
    max-width: 230px;
    width: fit-content;
    font-family: 'Manrope';
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    &:hover {
        text-decoration: underline;
    }
}

.module-statistics-report__result-cell {
    width: 170px;
}