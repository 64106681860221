.moduleCards {
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 60px;
  }

}

@media screen and (max-width: 768px) {
  .moduleCards {
    &__list {
  
      margin-bottom: 40px;
    }
  
  }
}



