.formatsList {
    width: 100%;
}

.formatsList__container {
    position: relative;
}

.formatsList__cardList {
    width: 100%;
    column-count: 3;
    align-items: flex-start;
    gap: 24px;
}

.formatsList__cardItem {
    width: 100%;
    display: inline-flex;
    margin: 0 0 24px 0;
    padding: 18px;
    border-radius: 15px;
    background: #fff;
    box-shadow: var(--default-shadow);
}

.formatsList__title {
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 24px 0;
}

.formatsList__icon {
    position: absolute;
    top: 0;
    right: 0;
}