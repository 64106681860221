.inputFilter {
    padding: 10px;
}

.inputFilter_active:focus {
    padding-left: 10px;
}



.inputFilter_valueTyped {
    background-color: #0B42DC0D;
}

.inputFilter__typedValueShowplace {
    width: 100%;
    display: flex;
}

.inputFilter__value {
    font-family: Exo-2;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #0B42DC;

    padding-left: 10px;
}

.inputFilter__delete {
    font-family: 'Exo-2';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.03em;
    color: #000000;
    cursor: pointer;
    position: relative;
    left: 5px;
}