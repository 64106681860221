.items {
    margin-top: 15px;

    display: flex;
    flex-direction: column;

    &__module {
        display: flex;
        gap: 45px;
        align-items: center;
    }

    &__dot {
        width: 16px;
        height: 16px;

        border: 1.7px solid #333333;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        &_notActive {
            display: none;
        }

        &_active {
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #0C43DD;
        }
    }

    &__container {
        display: grid;
        grid-template-columns: 414px 119px 189px;
        cursor: pointer;
        border-bottom: 1px solid #ECEAE9;
        border-top: 1px solid #ECEAE9;
        margin-bottom: -1px;

        &_double {
            grid-template-columns: 533px 189px;
        }

        &_selected {
            position: relative;
            z-index: 3;

            height: 39px;

            background-color: #F8F9FE;
            border: none;
        }

        &:hover {
            position: relative;
            z-index: 2;

            height: 39px;

            background-color: #F8F9FE;
            border: none;
        }
    }

    &__item {
        display: flex;
        align-items: center;

        padding: 9px 0 11px 10px;

        font-size: 14px;
        line-height: 17px;

        &:first-child {
            padding-left: 44px;
        }

        &_disabled {
            opacity: .6;
        }

        &_s {
            font-size: 13px;
            line-height: 16px;

            &:hover {
                border: 1px solid #DBE1F0;
                padding-left: 9px;
            }

            &:last-child {
                margin-right: 29px;
            }
        }

        &_active {
            padding: 0;
        }

        &_label {
            gap: 7px;
        }
    }
}