.additionalGeneration_back {
  max-width: 350px;

  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  background: var(--main-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  cursor: pointer;
  margin-bottom: 20px;
  font-size: 20px;
}

.additionalGeneration {
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.additionalGeneration_item {
  margin-left: 4px;
  max-width: 700px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 24px;
  }
}

.additionalGeneration_itemName {
  font-size: 20px;
}

.additionalGeneration_name {
  font-size: 22px;
}

.additionalGeneration_actions {
  margin-left: 60px;
  // max-width: 350px;
  display: flex;
  flex-direction: column;
}

.additionalGeneration__mainActions {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.additionalGeneration__finish {
  display: flex;
  gap: 10px;
}

.button {
  width: 240px;
}


.filters__item {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-right: 40px;
  margin-bottom: 20px;
}

.filters__conflictTypes {
  // width: 400px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-right: 40px;
  margin-bottom: 20px;
}

.filters__itemName {
  width: 100%;
}
.filters__select {
  box-shadow: var(--default-shadow);
  border-radius: 15px;
}
.filters__checkbox {
  display: flex;
  align-items: center;
}

.filters__checkbox > div {
  margin-left: 10px;
  cursor: pointer;
}

.filters__checkbox > input {
  cursor: pointer;
}

.conflicts {
  max-height: 300px;
  overflow-y: scroll;
}

.conflicts > li {
  margin-bottom: 20px;
}

.score {
  // max-height: 300px;
  // overflow-y: scroll;
}

.score > li {
  margin-bottom: 20px;
}

.warning {
  display: flex;
  flex-direction: column;
  color: red;
}