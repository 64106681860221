.roomAvailable {
    padding-bottom: 47px;
    max-width: 600px;
}

.roomAvailable__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    min-width: 460px;
}

.roomAvailable__header_text {
    display: flex;
    align-items: center;
    gap: 9px;

    color: #000;
    font-family: Exo2, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.8px;
}

.roomAvailable__header_title {
}

.roomAvailable__header_questionMark {
    position: relative;

    display: flex;
    align-items: center;
}

.roomAvailable__header_switch {
    display: flex;
    align-items: center;
    gap: 5px;
}

.roomAvailable__header_switchText {
    color: #000;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.roomAvailable__additional {
    position: absolute;
    top: 18px;
    left: 16px;
    z-index: 2;

    max-width: 247px;
    width: max-content;
    padding: 10px;

    background-color: #FFF;

    border-radius: 6px;

    border: 1px solid #F4F4F4;

    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.07);
}

.roomAvailable__additional_bold, .roomAvailable__additional_interval {
    color: #000;
    font-family: Exo2, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 14.2px; /* 129.091% */
    letter-spacing: -0.33px;
}

.roomAvailable__additional_interval {
    font-weight: 400;
}

.roomAvailable__intervals {
    //margin-top: 14px;

    // временное решение, Дизайнер (Женя) не сказал как этот блок на экране
    //margin-left: -26px;
    //margin-right: -26px;
}
