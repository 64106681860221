.weekDayInterval {
    display: flex;
    gap: 10px;
    font-size: 14px;
    //justify-content: space-between;

    &__day {
        width: 20px;
    }

    &__interval {
        display: flex;
    }

    &__time {
        display: flex;
    }
}