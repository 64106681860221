.button {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    width: 155px;
    max-height: 52px;
    margin-right: 24px;
    padding: 10px 11px;
    cursor: pointer;
    color: #000;
    border: none;
    border-radius: 10px;
    background-color: #fff;
    font-size: 14px;
}

.button_userCard {
    width: 156px;
    height: min-content;
    border: 3px solid var(--mock-color);
    margin-right: 0;
}

.button_send {
    width: max-content;
    background-color: var(--mock-color);
}

.button_send_module {
    background-color: var(--mock-color);
}

.button_ok {
    align-self: center;
    width: 78px;
    height: 28px;
    margin-top: 18px;
    border-radius: 16px;
    background-color: var(--mock-color);
}

.button_add {
    width: max-content;
    height: 100%;
    padding: 0;
}

.button_add_border {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: 30px;
    margin-right: 11px;
    padding: 5px 5px;
    border: 1px solid #aaaaaa;
    border-radius: 8px;
}

.button_calendar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 280px;
    width: max-content;
    height: 30px;
    padding: 7px 5px;
    border-radius: 0;
    border: 1px solid #000;
    color: #000;
    margin: 0 7px 0 0;
    font-size: 14px;

    &:last-child {
        margin-right: 7px;
    }
}

.button_filterCalendar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
    padding: 0 12px;
    border-radius: 10px;
    box-shadow: var(--default-shadow);
    color: #c4c4c4;
    font-size: 14px;
    margin: 0;

    &:last-child {
        margin-right: 0;
    }
}

.button_filterCalendarBlack {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    height: 40px;
    padding: 0 12px;
    border-radius: 10px;
    // box-shadow: 2px 2px 6px rgba(217, 217, 217, 0.25);
    color: #000;
    font-size: 14px;
    margin: 0;

    &:last-child {
        margin-right: 0;
    }
}

.button_add_border_fixSize {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 155px;
    height: 30px;
    margin-right: 11px;
    padding: 5px 5px;
    border: 1px solid #aaaaaa;
    border-radius: 8px;
}

.button_disabled {
    height: 36px;
    color: #c4c4c4;
    border: 3px solid #c4c4c4;
}

.button_check {
    width: 25px;
    height: 25px;
    background-color: var(--mock-color);
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-image: url("../../../../../assets/Check.svg");
    background-repeat: no-repeat;
    background-position: center;
}
