.Sort {
    display: flex;
    align-items: center;
    gap: 8px;
}

.Sort__button {
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    background: var(--main-gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
}

.Sort__icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}
