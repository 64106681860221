.article {
}

.article svg {
    flex-shrink: 0;
}

.article__header {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 48px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: var(--default-shadow);
    cursor: pointer;
    user-select: none;
}

.article__error {
    color: grey;
    font-weight: 700;
    margin: 18px 0;
}

.article_active .article__header {
    background-image: var(--main-gradient);
    box-shadow: none;
}

.article_active .article__content {
    // visibility: visible;
    // pointer-events: auto;
}

.article__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: -24px 0 0 0;
    padding: 24px 48px 48px;
    border-radius: 15px;
    background: #fff;
    // visibility: hidden;
    // pointer-events: none;
}

.article__title {
    font-weight: 600;
    font-size: 16px;
    margin: 18px 0 0 0;
}

.controls {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 48px;
}

.controls > div:first-child {
    width: 100%;
}

.sort {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}

.sort__button {
    font-weight: 600;
    height: 19px;
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    background: var(--main-gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
}

.sort__section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
}

.article__wrap {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    flex-shrink: 0;
}

.article__select {
    width: 100%;
    max-width: 150px;
    flex-shrink: 0;
    box-shadow: var(--default-shadow);
    font-size: 14px;
    border-radius: 10px;
}

.article__select > div:first-child {
    width: 100%;
    max-width: 900px;
    height: 40px;
}

.article__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

@media screen and (max-width: 768px) {
    .article__header {
        padding: 0 18px;
    }

    .article__content {
        padding: 24px 12px;
    }

    .article__wrapper {
        align-items: flex-start;
        flex-direction: column;
        gap: 0;
    }

    .sort__section {
        width: 100%;
        flex-direction: column;
        gap: 0;
    }

    .controls > input {
        width: 100%;
    }

    .article__tab {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .controls {
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
    }
}

.selectAssigment__box {
    width: 100%;
    max-width: 250px;
    height: 40px;

}

.selectAssigment__selectedOption {
    // max-width: 200px;
    height: 40px;
    padding: 0 12px;
    display: inline-flex;
    align-items: center;
    box-shadow: var(--default-shadow);
    font-size: 14px;
    border: 0px solid;
    padding: 0 12px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: white;
}

.selectAssignment__text {
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.selectAssigment__selectedOption > h5 {
    width: 100%;
    max-width: 900px;
    // height: 40px;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    align-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.selectAssigment__crossButton {
    height: 24px;
}

.switcher {
    display: inline-flex;
    height: 24px;
    user-select: none;
}
