body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  //to save space behind the scrollbar
  // overflow-y: overlay;
  background: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  font-display: auto;
  backface-visibility: hidden;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

#index {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 700;
}

h2 {
  font-family: 'Exo-2';
  font-weight: bold;
  font-size: 24px;
  margin: 24px 0;
}

input::-webkit-input-placeholder {
  opacity: 1;
}

input::-moz-placeholder {
  opacity: 1;
}

input:-moz-placeholder {
  opacity: 1;
}

input:-ms-input-placeholder {
  opacity: 1;
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
}

input:focus::-moz-placeholder {
  opacity: 0;
}

input:focus:-moz-placeholder {
  opacity: 0;
}

input:focus:-ms-input-placeholder {
  opacity: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
}

section {
  width: 100%;
  height: 100%;
}

img {
  max-width: 100%;
  display: block;
}
