@import "../../../../../scheduleStyles/filterColors";

.schedulePage_eventsSection_students{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px 0;
}

.schedulePage_eventsSection_userContainer{
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    margin: 0 20px;
}

.schedulePage_eventsSection_userInfo{
    display: flex;
    align-items: center;
    flex: 0 1 86%;
}

.schedulePage_eventsSection_userPicture{
    margin-right: 14px;
}

.schedulePage_eventsSection_avatar {
    display: block;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 10px;
}

.schedulePage_eventsSection_btnCenter{
    display: flex;
    justify-content: center;
}

.schedulePage_eventsSection_checkboxStudent{
    width: 20px;
    height: 20px;
    margin-left: 5px;
    border: 1px solid #5F5F5F;
    border-radius: 5px;
    cursor: pointer;
}

.schedulePage_eventsSection_checkMark{
    width: 18px;
    height: 18px;
    fill: none;
}

@media screen and (max-width: 1200px) {
    .schedulePage_eventsSection_userContainer{
        width: 100%;
        max-width: inherit;
    }
}
