.arrow {
  width: 20px;
  height: 10px;
}

.arrow_reverse {
  transform: rotate(180deg);
}

.newArrow {
  width: 16px;
  height: 22px;
}

.newArrow_reverse {
  transform: rotate(90deg);
}