.header {
    display: flex;
    gap: 9px;
    align-items: center;

    //border-bottom: 1px solid #ECEAE9;
    &__content {}

    &__title {
        font-size: 23px;
        line-height: 28px;
        font-family: Exo-2, sans-serif;

        padding: 0;

        cursor: default;
    }

    &__image {
        display: flex;
        //justify-content: center;
        //align-items: center;
        width: 44px;
        height: 44px;
        background-color: #D9D9D9;
        border-radius: 6px;

        &_hasImage {
            background-color: transparent;
        }

        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .header {
        position: sticky;
        top: 0;
        padding-left: 37px;
        border-bottom: 1px solid #ECEAE9;
        background-color: white;

        z-index: 2;

        height: 64px;

        &__image {
            display: none;
        }
    }
}