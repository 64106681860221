.subSpaceCommonParams {
    padding: 21px 0 41px 0;
}

.subSpaceCommonParams__container {
    display: flex;
    flex-direction: column;

    font-size: 18px;
}

.subSpaceCommonParams__tittle {
    display: flex;

    font-weight: 700;
}

.subSpaceCommonParams__tittleWithValue {
    margin-left: 16px;

    font-weight: 400;
}

.subSpaceCommonParams__list {
    margin-top: 17px;
}

.subSpaceCommonParams__item {
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }
}