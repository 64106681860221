.external-module__action-panel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 45px;
    margin-top: 20px;
    position: relative;
}

.external-module__action-panel-loader {
    position: absolute;
    margin-left: 100px;
    margin-top: 3px;
}

.external-module__back {
    display: flex;
    align-items: center;
    background: var(--main-gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    margin-bottom: 15px;
}

.external-module__back-title {
    margin-left: 9px;
    font-weight: 700;
    font-size: 16px;
    background: var(--main-gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}