.processChooser {
}

.processChooser__description {
    margin-bottom: 12px;
}

.processChooser__processes {
    display: flex;
}

.processChooser__process {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 25px;
    height: 25px;

    cursor: pointer;
    border: 1px solid #C4C4C4;

    font-family: 'Exo-2';

    &:not(:last-child) {
        border-right: none;
    }
}

.processChooser__process.processChooser__process_selected {
    border: 3px solid #000;
}

.processChooser__process_disabled {    
    opacity: 0.5;

    cursor: default;
    pointer-events: none;
}
