.teacher__penIcon {
  width: 18px;
  height: 18px;
  position: relative;
  top: 3px;
  left: 5px;
  cursor: pointer;
}

.teacher__suggestion {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
}

.suggestion {
  width: 360px;
}

.suggestion_list {
  display: flex;
  justify-content: flex-start;
  width: 360px;
  text-align: left;
}

.teacher__switcher {
  margin-bottom: 10px;
}

.teacher__container {
  background: var(--extra-gradient);
  box-shadow: var(--default-shadow);
  border-radius: 10px;
  min-height: 28px;
  padding: 10px;
  margin-top: 10px;
}

.teacher__text {
  color: #000;
}
