.equipmentCard {
  padding: 40px;
  border-bottom: 1px dashed #c4c4c4;
}

.equipmentCard__content {
  display: flex;
}

.equipmentCard__letter {
  margin-right: 20px;
  font-size: 24px;
  font-weight: 700;
}

.equipmentCard__list {
  width: 100%;
}

.equipmentCard__item {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}
