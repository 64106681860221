.item {
  display: flex;
  flex-direction: column;

  margin-top: 24px;
}

.item__typeName {
  font-size: 18px;
}

.skillsList {
  display: flex;
  flex-direction: column;
}

.skillsList__item {
  display: block;

  width: fit-content;
  margin-bottom: 6px;
  padding: 4px;

  background-color: var(--mock-color);
}

.skillsList__name {
  display: inline-block;

  margin-right: 2px;

  font-size: 14px;
}

.skillsList__level {
  display: inline-block;

  font-size: 14px;
  font-weight: 700;
}
