.timePicker {
    position: absolute;
    left: -1px;
    top: 49px;
    width: 448px;
    min-height: 280px;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-top: none;
    display: flex;
    flex-direction: row;
    z-index: 2;
}

.timePicker__daysNavigation {
    width:100px;
    border-right: 1px solid #000000;
}

.timePicker__saveButton {
    width: 25px;
    height: 25px;
    position: absolute;
    bottom:39px;
    right:10px;
    cursor: pointer;
}
