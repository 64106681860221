.minmax {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 32px;
}

.minmax__max {
  margin: 0 0 0 100px;
}

.minmax__warning {
  position: relative;
  margin: -20px 0 80px;
}

.minmax__warningText {
  font-size: 12px;
}
