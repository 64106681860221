.moduleTabs {
    margin-bottom: 20px;
}

.moduleTabs__list {
    display: flex;
    border-top: 1px solid #ccc;
}

.moduleTabs__item {
    cursor: pointer;
    border-right: 1px solid black;
    border-bottom: 1px solid black;

    &:last-child {
        border-right: none;
    }
}

.moduleTabs__item_active {
    border-bottom: none;
}

.moduleTabs__text {
    font-size: 12px;
    font-weight: 500;
    padding: 8px 12px;
}
