.subSpaceSlotDiagram {
  position: relative;

  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: center;

  width: 786px;
  padding-right: 12px;
  padding-left: 12px;

  border: 1px solid;
}

.subSpaceSlotDiagram_withOverflow {
  position: relative;

  overflow-x: scroll;
}

.subSpaceSlotDiagram__settings {
  position: sticky;
  top: 0;
  left: 0;

  width: 100%;
  height: 186px;
}

.subSpaceSlotDiagram__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-left: 66px;
}

.subSpaceSlotDiagram__title {
  margin-top: 36px;

  font-size: 24px;
  font-weight: 700;
}

.subSpaceSlotDiagram__controlPanel {
  height: 120px;
  margin-left: 66px;
}

.subSpaceSlotDiagram_editButton {
  display: flex;
  align-items: flex-start;

  width: 40px;
  height: 40px;
  margin-top: 10px;

  cursor: pointer;
}

.subSpaceSlotDiagram__prompt {
  margin-bottom: 31px;

  color: #C4C4C4;

  font-size: 16px;
}

.diagram {
  display: flex;
  align-items: flex-end;
  flex-direction: row;

  margin-top: 14px;
  margin-bottom: 24px;
}

.diagram_centered {
  justify-content: center;
}

.diagram_flexStart {
  position: relative;

  justify-content: flex-start;
}
