.subSpaceCurrentParams {
  padding: 15px 26px;

  border: 1px solid black;
}

.subSpaceCurrentParams__tittle {
  display: flex;
  align-items: center;

  cursor: pointer;
}

.subSpaceCurrentParams__triangle {
  position: relative;

  width: 0;
  height: 0;
  margin-right: 24px;

  transition: transform 0.5s;
  transform: rotate(0deg);

  border: 10px solid transparent;
  border-top-width: 6px;
  border-right-width: 0px;
  border-bottom-width: 6px;
  border-left-color: black;

  &::after {
    position: absolute;
    top: -3px;
    right: 3px;

    width: 0;
    height: 0;

    content: "";
    transition: opacity 0.7s;

    opacity: 1;
    border: 5px solid transparent;
    border-top-width: 3px;
    border-right-width: 0px;
    border-bottom-width: 3px;
    border-left-color: white;
  }
}

.subSpaceCurrentParams__staticTriangle {
  &::after {
    border-left-color: white;
  }
}

.subSpaceCurrentParams__staticTriangle_withContent {
  &::after {
    border-left-color: black;
  }
}

.subSpaceCurrentParams__triangle_active {
  transform: rotate(90deg);

  &::after {
    opacity: 1;
    border-left-color: white;
  }
}

.subSpaceCurrentParams__skillsList {
  overflow: hidden;

  max-height: 500px;
  margin-top: 12px;
  margin-left: 65px;

  transition: max-height 0.7s ease-in;
}

.subSpaceCurrentParams__skillsList_height_null {
  max-height: 0;

  transition: max-height 0.2s ease-out;
}

.subSpaceCurrentParams__skillsItem {
  display: flex;
  justify-content: space-between;

  width: 450px;
  margin-bottom: 5px;
  padding: 4px 12px;

  background-color: #afd6f0;

  &:last-child {
    margin-bottom: 0;
  }
}
