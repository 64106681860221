.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.popup_opened {
  display: flex;
  z-index: 9999;
}

.popup__container {
  position: relative;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  min-width: 659px;
  padding: 48px 54px 32px;

  background: #ffffff;
}

.popup__title {
  margin: 0 0 40px 0;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.popup__fieldset {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  min-height: 310px;
  margin: 0 0 48px;
  padding: 32px 24px 24px;

  border: none;
  border: 1px solid #000;
}

.controls {
  display: flex;
  justify-content: center;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 36px;
  border-radius: 10px;
  cursor: pointer;
}

.deleteButton {
  margin-right: 24px;
  border: 3px solid var(--mock-color);

  &_disabled {
    border-color: #c4c4c4;
    cursor: default;
  }
}

.submitButton {
  background: var(--mock-color);

  &_disabled {
    background: #c4c4c4;
    cursor: default;
  }
}
