.intervalByWeekDays {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.intervalByWeekDays__picker {
    display: flex;
    justify-content: space-between;
}

.intervalByWeekDays__options {
}

.intervalByWeekDays__options_list {
    z-index: 1;
}