.external-module-review {
    margin-bottom: 40px;
    position: relative;
}

.external-module-review__header {
    display: flex;
    width: fit-content;
    margin-bottom: 10px;

    cursor: pointer;
    user-select: none;
}

.external-module-review__icon {
    margin-right: 10px;
    width: 16px;
    height: 16px;

    &_active {
        transform: rotate(-45deg);
    }
}

.external-module-review__title {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    background: var(--main-gradient);

    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.external-module-review__save-button {
    background: linear-gradient(248.68deg, #0B42DC 7.24%, #0B42DC 10.99%, #0B42DC 18.97%, #57B2C6 73.4%, #EED660 91.23%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    margin-top: 10px;
    span {
        cursor: pointer;
        padding: 5px;
    }
}

.external-module-review__error {
    position: absolute;
}

.external-module-review-list {
    background: #FFFFFF;
    border-radius: 30px;
    padding: 32px 24px;
    margin-bottom: 40px;
}

.external-module-review-list__title {
    font-family: "Manrope";
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;
}

.review-card {
    position: relative;

    &:not(:last-of-type) {
        margin-bottom: 30px;
    }
}

.review-card__reviewer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
}

.reviewer__avatar {
    margin-right: 12px;
    svg {
        padding-left: 0 !important;
    }
}

.reviewer__full-name {
    font-family: 'Exo-2';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.review-card__message {
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    ul {
        margin-block-start: 1em;
        margin-block-end: 1em;
        padding-inline-start: 40px;
    }

    ul li {
        list-style: disc;
    }
}

.review-card__remove {
    position: absolute;
    right: 10px;
    top: 0;

    padding: 5px;
    display: flex;
}