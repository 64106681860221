.info {
    display: flex;
    flex-direction: column;
    gap: 5px;

    max-width: 292px;
    width: max-content;
    padding: 10px 10px 10px 15px;

    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #EBEBEB;
}

.info__time, .info__meeting, .info__teacher, .info__meeting_dark {
    font-family: Exo-2, sans-serif;
    font-size: 11px;
    line-height: 13px;
    font-weight: 400;
}

.info__time {

}

.info__meeting {

    &_dark {
        font-weight: bold;
    }
}

.info__teacher {

}