.wrapper {
    width: 100%;
    height: 820px;

    display: flex;
    align-items: center;
    justify-content: center;
}


.moduleSelection {
    background-color: #fff;


    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-right: 20px;
    }

    &__content {
        display: flex;
        border-top: 1px solid #ECEAE9;
    }

    &__students {
        //padding-left: 25px;
    }

    &__modules {
        border-left: 1px solid #ECEAE9;
        border-bottom: 1px solid #ECEAE9;

        display: flex;
        height: calc(100vh - 144px);
        max-width: calc(100vw - 845px);
        overflow-x: scroll;
        overflow-y: hidden;

        &_required {
            max-width: calc(100vw - 455px);
            border: none;
        }

        &::-webkit-scrollbar {
            width: 15px;
        }

        &::-webkit-scrollbar-track {

            background-color: #FAFAFA;
            padding-bottom: 1px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #E3E3E3;

            border-radius: 10px;
            border: 3.5px solid #FAFAFA;
        }
    }
}