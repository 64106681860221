@import "src/admin/NewModule/assets/styles/colors";

.table {
  display: flex;
  overflow: auto;

  max-width: 1140px;
  margin: 20px 0 20px 0;

  text-align: center;

  font-size: 14px;
}

.table__cell {
  // min-width: 200px;
  // max-width: 200px;
  // min-height: 30px;
  // box-sizing: border-box;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // text-align: center;
  // border-right: 1px solid rgb(8, 8, 8);
  position: relative;

  &:first-child {
    min-width: 44px;
  }

  &:last-child {
    border-right: none;
  }

  &:nth-child(2) {
    justify-content: flex-start;
    padding-left: 5px;
  }
}

.table__cell_header {
  height: 72px;
  &:nth-child(2) {
    justify-content: center;
  }
}

.table__row {
  display: flex;

  border: 1px solid rgb(8, 8, 8);
  border-top: none;
  &:first-child {
    border-top: 1px solid rgb(8, 8, 8);
  }
}

.table__loader {
  border-bottom: none;
}

.table__name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 180px;
  position: relative;
  // top: 8px;
  // left: 5px;
  // background-color: #fafafa;
  // height: 20px;

  // &:hover {
  //   text-overflow: inherit;
  //   max-width: max-content;
  //   font-size: 15px;
  //   text-shadow: #494743 1px 0 1px 0;
  //   padding: 0 3px;
  //   top: 7px;
  //   z-index: 1;
  //   border-image-source: var(--main-gradient);
  //   border-bottom: 1px solid;
  //   border-image-slice: 1;
  //   border-width: 1px;
  // }
}

.table__name_text {
  padding: 0;

  // &:hover {
  //   font-size: 14px;
  //   border: none;
  //   padding: 0;
  //   top: 8px;
  //   padding: 0 10px 0 0;
  // }
}

.table__row_seachForm {
  height: 31px;
}

.table__showMore {
  margin: 20px 0 30px 0;
  display: flex;
  justify-content: center;
}

.table__showMoreSkills {
  width: 250px;
  max-height: 250px;
  box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  overflow-y: auto;
  background: #fff;
}

.table__showMoreSkillsText {
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.86);
  overflow-y: auto;
  padding: 12px;

  &::-webkit-scrollbar-track {
    border: 1px solid #f6f6f6;
    border-radius: 10px 30px 10px 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(196, 196, 196, 0.2);
    border-radius: 6px;
    height: calc(100% - 15px);
  }
}
.table__showMoreSkills_visible {
  opacity: 1;
  pointer-events: auto;
}

.toolBar {
  display: flex;
  flex-direction: row;
}

.toolBar__button {
  padding: 5px;
}

.toolBar__buttonText {
  padding-left: 5px;
}

.toolBar__icon {
  min-width: 20px;
  min-height: 20px;
}

.checkbox {
  position: absolute;
  opacity: 0;
}

.checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.checkbox + label::before {
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  width: 11px;
  height: 11px;
  content: "";
  border: 1px solid #adb5bd;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.checkbox:checked + label::before {
  border-color: rgb(68, 66, 66);
  background-image: url("../../../../assets/Vector.svg");
}

/* стили при наведении курсора на checkbox */
.checkbox:not(:disabled):not(:checked) + label:hover::before {
  border-color: rgb(68, 66, 66);
}
/* стили для активного состояния чекбокса (при нажатии на него) */
.checkbox:not(:disabled):active + label::before {
  border-color: rgb(68, 66, 66);
}

/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.checkbox:focus:not(:checked) + label::before {
  border-color: rgb(68, 66, 66);
}
/* стили для чекбокса, находящегося в состоянии disabled */
.checkbox:disabled + label::before {
  background-color: rgb(68, 66, 66);
}

.checkbox_some:checked + label::before {
  background-image: url("../../../../assets/Rectangle\ 988.svg");
}

.checkbox_some + label::before {
  background-image: url("../../../../assets/Rectangle\ 988.svg");
}

.search__input {
  width: 200px;
  box-sizing: border-box;
  border: 0 solid rgb(8, 8, 8);
  padding: 0 0 0 5px;
  border-right: 1px solid rgb(8, 8, 8);
  // background-color: #fafafa;

  &:first-child {
    border: 1px solid rgb(8, 8, 8);
  }

  &:last-child {
    border-right: none;
  }

  &::placeholder {
    color: #c4c4c4;
    font-size: 14px;
  }
}

.search__input_select {
  padding: 0;
}
