.assignments {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.title {
    font-family: "Exo-2";
    font-size: 26px;
    margin: 0;
}
