.weekDay {
    background-color: #C7C7C7;

    font-size: 12px;
    font-weight: 500;
    color: #fff;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 20px;
    height: 20px;

    border-radius: 50%;

    &_active {
        background-color: #0B42DC;
    }
}