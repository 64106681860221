$yellow-color: var(--mock-color);
$grey-color: #c4c4c4;
$black-color: #000;
$white-color: #fff;
$green-color: #cdffe4;

.control-panel {
  display: flex;
}

.control-panel__btn {
  margin-right: 16px;
}

.btn {
  width: 155px;
  height: 36px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.btn_normal {
  border: 3px solid $yellow-color;
}

.btn_active {
  border: 3px solid $yellow-color;
  background-color: $yellow-color;
}

.btn_disable {
  border: 3px solid $grey-color;
  pointer-events: none;
}

.diagram {
  position: relative;
  height: 402px; // js
  width: 1460px; // js
  margin: 0 80px 80px 0;
}

.diagram > ul.bti_list {
  position: absolute;
  width: fit-content;
  display: flex;
  color: $grey-color;
  width: 100%;
  height: 100%;
}

li.bti_item {
  height: 100%;
}

li.bti_item > .bti_title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 22px;
  border-left: 1px solid $grey-color;
  border-bottom: 1px solid $grey-color;
  border-top: 1px solid $grey-color;
}

li.bti_item:last-of-type > .bti_title {
  border-right: 1px solid $grey-color;
}

li.bti_item > .bti_divider {
  width: 1px;
  height: calc(100% - 44px);
  background: repeating-linear-gradient(
    to bottom,
    $grey-color 10px,
    $grey-color 20px,
    transparent 20px,
    transparent 30px
  );
  position: relative;
  left: 180px;
}

li.bti_item:last-of-type > .bti_divider {
  opacity: 0;
}

.diagram > ul.subspace-types {
}

.subspace-type {
  position: absolute;
}

.subspaces {
}

.subspace {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: $black-color;
  background-color: $white-color;
  padding: 3px 14px;
  margin-bottom: 13px;
  word-break: break-word;
  opacity: 1;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}

.subspace:hover {
  cursor: pointer;
}

.subspace.muted {
  opacity: 0.1;
  pointer-events: none;
}

.diagram > .diagram-connections {
  position: absolute;
  width: 100%;
  height: 100%;
}

.diagram-connections > svg.connections {
  width: 100%;
  height: 100%;
}

.subspaces-link {
  stroke-width: 1;
  stroke: $black-color;
  opacity: 1;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}

.subspaces-link.muted {
  opacity: 0.1;
}

.subspaces-link.disable {
  opacity: 0;
}

.subspaces-link.temp {
  stroke-dasharray: 2 2;
}

.diagram-navigation {
  display: flex;
  font-size: 24px;
  margin-bottom: 24px;
}

.diagram-navigation-divider {
  margin: 0 12px;
}

.diagram-navigation > .connections-info {
  font-weight: bold;
}
