.tabsComponent__list{
    position: relative;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #C4C4C4;
    font-size: 14px;
    font-weight: 700;
    &::after {
        position: absolute;
        bottom: -8px;
        width: 100%;
        height: 1px;
        content: '';
        background-color:#C4C4C4;
    }
}

.tabsComponent__listItem {
    display: flex;
    flex: 1;
    justify-content: center;
    &:hover {
        position: relative;
        transition: all 0.3s ease-in;
        text-align: center;
        color: black;
        background: linear-gradient(
            248.68deg, #0B42DC 18.97%, #57B2C6 73.4%, #EED660 91.23%);
                background-clip: border-box;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
        &::after {
            position: absolute;
            z-index: 2;
            bottom: -8px;
            left: 50%;
            width: 100%;
            height: 1px;
            content: '';
            transform: translate(-50%);
            background-color:#C4C4C4;
        }
    }
    &:first-child {
        justify-content: flex-start;
    }
    &:last-child {
        justify-content: flex-end;
    }
}

.tabsComponent__value {
    display: flex;
}

.tabsComponent__listItem_chosen {
    position: relative;
    display: flex;
    flex: 1;
    justify-content: center;
    transition: all 0.3s ease-in;
    text-align: center;
    background: var(--main-gradient);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &::after {
        position: absolute;
        z-index: 5;
        bottom: -8px;
        left: 50%;
        width: 100%;
        height: 1px;
        content: '';
        transform: translate(-50%);
        background: var(--main-gradient);
    }
}

.tabsComponent__listItem_itemChosen {
    background: linear-gradient(
            248.68deg, #0B42DC 18.97%, #57B2C6 73.4%, #EED660 91.23%);
                background-clip: border-box;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 480px) {
    .tabsComponent__list{
        font-size: 12px;
    }
}