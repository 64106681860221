.roomTitle {
	margin-bottom: 24px;
    max-width: 600px;
}

.formRoom__input {
	font-size: 18px;
    input:required {
        box-shadow: #6BA3CE; // possible to add image
    }
    input:invalid {
        box-shadow: 0 0 5px 1px red;
    }

    input:focus:invalid {
        box-shadow: none;
    }
}

.roomTitle__label {
    margin-bottom: 16px;

    color: #000;
    font-family: Exo2, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.8px;
}