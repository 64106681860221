.pendant {
  position: absolute;
  border-radius: 10px;
  padding: 8px;
}

.pendant_success {
  border: 1px solid #43E28CD1;
  color: #43E28CD1;
}

.pendant_warning {
  border: 1px solid #E49702;
  color: #E49702;
}

.pendant_danger {
  border: 1px solid #FF0000;
  color: #FF0000;
}
