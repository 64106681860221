.subSpaceCurrentProfileParams {
  display: flex;
  flex-direction: column;
}

.subSpaceCurrentProfileParams__tittle {
  margin-bottom: 10px;

  font-size: 18px;
}

.subSpaceCurrentProfileParams__form {
  position: relative;

  display: flex;
  align-items: center;

  width: 550px;

  font-size: 18px;
}

.subSpaceCurrentProfileParams__form > .subSpaceCurrentProfileParams__formLvl {
  position: absolute;
  right: 0;
}

.subSpaceCurrentProfileParams__soloPlus {
  display: flex;

  margin-left: 15px;
}

.subSpaceCurrentProfileParams__formInput {
  width: 246px;
  height: 30px;

  font-size: 16px;
}

.subSpaceCurrentProfileParams__formLvl {
  display: flex;
  align-items: center;
}

.subSpaceCurrentProfileParams__formSelect {
  width: 48px;
  height: 30px;
  margin-right: 15px;
  margin-left: 10px;
}

.subSpaceCurrentProfileParams__addedParamsList {
  display: grid;

  grid-template-columns: repeat(3, 177px);
  grid-template-rows: repeat(auto, 1fr);
}

.subSpaceCurrentProfileParams__addedParamsItem {
  position: relative;

  display: inline-flex;
  align-items: center;

  width: 156px;
  margin-top: 10px;
  margin-right: 24px;
  padding: 3px 30px 3px 4px;

  background-color: var(--mock-color);

  font-size: 14px;
}

.subSpaceCurrentProfileParams__addedParamsItemLvl {
  position: absolute;
  right: 15px;

  font-weight: 700;
}

.subSpaceCurrentProfileParams__iconContainer {
  position: absolute;
  right: -9px;

  display: flex;
}

.yellowPlus_26px {
  width: 26px;
  height: 26px;

  cursor: pointer;
}

.greyCross_18 {
  width: 18px;
  height: 18px;

  cursor: pointer;

  fill: #c4c4c4;
}
