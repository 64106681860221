.selectedSubspaceCard__container {
    width: 100%;
    background: #FFF;
    box-shadow: var(--default-shadow);
    border-radius: 10px;
    padding: 4px 12px;
    font-size: 14px;
    margin-bottom: 16px;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }
}

.selectedSubspaceCard__container_noData {
    min-height: 48px;
    display: flex;
    align-items: center;
}

.selectedSubspaceCard__icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}