.tooltip {
  width: max-content;
  max-width: 264px;

  padding: 10px;

  content: attr(data-hint);

  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.03em;

  position: absolute;
  top: 30px;
  z-index: 3;

  padding: 10px 10px;

  pointer-events: none;
  transition: opacity 0.2s;

  color: black;
  background: #FFFFFF;
  border: 1px solid #F4F4F4;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
  border-radius: 6px;

  z-index: 3;
}

.tooltip_error {
  color: #AF5555;
}

.tooltip__container {
  position: relative;
}

.tooltip__title {
  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.03em;
}
