.teacherAssignment {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.teacherAssignment__title {
    font-size: 26px;
    font-weight: 700;
    font-family: "Exo-2";
    margin: 0;
}

.teacherAssignment__subtitle {
    font-size: 22px;
    font-weight: 600;
    font-family: "Exo-2";
    margin: 0;
}

.teacherAssignment__textGradient {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 600;
    background-clip: text;
    background-image: var(--main-gradient);
    -webkit-text-fill-color: transparent;
}

.teacherAssignment__profile {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.teacherAssignment__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
}

.teacherAssignment__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.teacherAssignment__sidebar {
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex-shrink: 0;
}

.teacherAssignment__comments {
    display: flex;
    flex-direction: column;
    gap: 18px;
    background-color: #fff;
    padding: 12px 24px;
    border-radius: 15px;
    box-shadow: var(--default-shadow);
}

.teacherAssignment__button {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    font-weight: 600;
    background-clip: text;
    background-image: var(--main-gradient);
    -webkit-text-fill-color: transparent;
}

.teacherAssignment__name {
    font-weight: 600;
    font-size: 16px;
}

.teacherAssignment__position {
    color: #777;
}

.teacherAssignment__message {
    border-bottom: 1px dashed #ccc;
    padding: 0 0 18px 0;
}

.teacherAssignment__message:last-child {
    border: none;
}

.teacherAssignment__textarea {
    width: 100%;
    min-height: 120px;
    border: none;
    border-bottom: 2px solid lightgrey;
    resize: vertical;
    outline: none;
    line-height: 1.5;
    // width: 100%;
    // min-height: 200px;
    // padding: 12px 24px;
    // border-radius: 18px;
    // box-shadow: var(--default-shadow);
    // resize: none;
    // border: none;
    // outline: none;
}

.teacherAssignment__textarea:focus {
    border-color: grey;
}

@media screen and (max-width: 768px) {
    .teacherAssignment__wrapper {
        // display: flex;
        flex-direction: column;
    }

    .teacherAssignment__subtitle {
        font-size: 18px;
    }
}
