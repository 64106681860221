.skill-add-with-nest {
  margin-bottom: 20px;
}

.skill-add-with-nest__text-separator {
  margin-top: 45px;
}

.skill-add-with-nest__form {
  position: relative;

  margin-top: 20px;
}

.skill-add-with-nest__label {
  display: flex;
  flex-direction: column;

  width: 270px;
  margin-bottom: 17px;
}

.skill-add-with-nest__input {
  height: 30px;
  margin-top: 5px;
}

.skill-add-with-nest__input--red-border {
  height: 30px;
  margin-top: 5px;

  border: 1px solid red;

  &:focus {
    outline: none !important;
    box-shadow: 0 0 2px red;
  }
}

.skill-add-with-nest__textarea {
  height: 95px;
  margin-top: 5px;

  resize: none;
}

.skill-add-with-nest__item {
  display: flex;
  flex-direction: column;

  margin-top: 25px;
  margin-bottom: 10px;

  border: 1px solid #000;
}

.skill-with-nest__head {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;
}

.skill-add-with-nest__head-title {
  width: 100%;
  padding-top: 7px;

  cursor: pointer;
  text-align: center;

  font-size: 27px;
}

.skill-add-with-nest__head-title--hidden {
  display: none;
}

.skill-add-with-nest__head-number {
  position: absolute;
  left: 0;

  padding-left: 35px;

  background: var(--mock-color);
}

.item__input__group {
  margin-bottom: 2rem;
}

.skill-with-nest__hidden {
  display: none;
}

.skill-add-with-nest__input-block {
  padding: 0 50px;
}

.skill-add-with-nest__section-wrapper {
  display: flex;
}

.skill-with-neat__button-delete {
  position: absolute;
  top: 10px;
  right: 10px;

  cursor: pointer;
}

.skill-with-nest__button-delete--hidden {
  display: none;
}

.skill-with-nest__icon-container {
  display: flex;
  align-items: flex-start;

  margin-top: 7px;

  cursor: pointer;
}

.skill-with-nest__bottom-icon-container {
  display: inline-flex;
  align-items: center;

  margin-top: 20px;

  cursor: pointer;
}

.skill-with-nest__icon-text {
  margin-left: 10px;
}
