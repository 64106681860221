.filterStudents__container {
    box-shadow: var(--default-shadow);
    border-radius: 10px;
    border: 1px solid rgba(217, 217, 217, 0.25);
    background-color: #fff;
    width: 85%;
    display: flex;
    align-items: center;
}

.schedulePage_eventsSection_searchImg{
    width: 20px;
    height: 15px;
    margin: 0 0 0 10px;
}

.schedulePage_eventsSection_searchStudents{
    max-width: 135px;
    margin: 5px 0px;
    border: 0px solid;
    outline: 0px solid;
}
