.moduleOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 111px;

  &__mode {
    margin-bottom: 1.25rem;
  }
}

.moduleOptions__avatarPreview {
  margin: 0 32px 0 0;
}

.moduleOptions__form {
  width: 606px;
  display: flex;
  flex-direction: column;
}

.moduleOptions__rolesInput {
  margin: 55px 0 0 0;
  max-width: 470px;
}

.moduleOptions__professors {
  margin: 0 0 80px;
}

.moduleOptions__professorsTitle {
  margin: 50px 0 0 0;
}

.moduleOptions__description {
  margin: 20px 0 25px;
}

.moduleOptions__wavesContainer {
  display: flex;
  flex-direction: row;
}

.moduleOptions__wavesContainerTitle {
  max-width: 390px;
  margin: 0 26px 0 0;
}
