.checkbox__container {
    display: flex;
    align-items: center;
    margin-right: 17px;
    padding: 22px 24px;
}

.checkbox__text {
    margin-left: 20px;
}

.checkbox__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 0;
    border: 1px solid #5F5F5F;
    border-radius: 5px;
    cursor: pointer;
}

.checkbox__button_none {
    display: none;
}

.checkbox__button_line {
    display: block;
    width: 16px;
    height: 3px;
    background-color: black;
}

.evaluation__checkmark {
    width: 18px;
    height: 14px;
    fill: none;
}
