.menu_hide {
    display: none;
}

.attendance {
    height: calc(100vh - 80px);

    padding: 0 24px;
    background-color: #fff;
}

.attendance__header {
    border-bottom: 1px solid #ECEAE9;
}

.attendance__header_title {
    cursor: pointer;

    &:hover {
        color: #0B42DC;

        border-bottom: 1px solid #0B42DC;
    }
}

.attendance__options {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 20px 0;

    border-bottom: 1px solid #ECEAE9;
}

.attendance__options_dropDown {
    width: 217px;
}

.attendance__options_mainDropDown {
    z-index: 7;
}

.attendance__options_download {
    width: fit-content;

    display: flex;
    align-items: center;
    justify-content: center;
}

.attendance__tables {
    position: relative;
    
    display: flex;
    flex-direction: column;

    height: calc(100vh - 228px);
    width: calc(100vw - 128px);

    overflow-x: scroll;
    overflow-y: scroll;
}

.attendance__tableHeader {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 5;
    
    display: grid;
    grid-template-columns: 96px 180px 76px 93px auto;

    background-color: #fff;

    border-bottom: 1px solid #ECEAE9;
}

.attendance__tableHeader_item {
    padding: 9px 0 11px 9px;

    border-right: 1px solid #ECEAE9;

    font-size: 13px;
    color: #6D6E6F;
    line-height: 16px;
    letter-spacing: -0.03em;
    font-family: Exo-2, sans-serif;

    &:last-child {
        border-right: none;
    }
}

.attendance__tableHeader_black {
    padding-right: 9px;
    color: #000;
}

@media screen and (min-width: 1920px){
    .attendance__tables {
        width: 1792px;
    }
}
