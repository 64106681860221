.Tasks {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 0 40px 0;
}

.Tasks__date {
    font-size: 18px;
    font-family: "Exo-2";
    font-weight: 600;
    display: flex;
    flex-direction: column;

    &::after {
        content: attr(data-term);
        color: #717171;
        font-size: 14px;
        font-weight: 400;
    }
}

.Tasks__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 0 0 24px 0;
}

@media screen and (max-width: 768px) {
    .Tasks {
        padding: 0;
    }
}
