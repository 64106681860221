.schedule {
    padding: 0 24px 24px;
}

.schedule__loader {
    margin: 0 0 34px 24px;
}

.schedule__strangeButton {
    display: inline-block;
    justify-content: center;
    margin: 0 8px 30px 0;
    padding: 1px;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    background: var(--main-gradient);
    box-shadow: var(--default-shadow);
}

.schedule__statistics {
    &_title {
        margin-bottom: 24px;
    }
}

.schedule__strangeContent {
    display: flex;
    padding: 10px;
    background: #fff;
}

.schedule__secretSection {
    margin: 0 0 60px;
}

.schedule__secretSubsection {
    margin: 0 0 40px;
}

.schedule__secretDisclaimer {
    margin: 0 0 20px;
}

.schedule__secretTitle {
    margin-bottom: 20px;
}

.schedule__secretList {
    padding-left: 30px;
}

.schedule__secretItem {
    max-width: 400px;
    margin-bottom: 20px;

    list-style: auto;
}

.schedule__secretDescription {
    margin-bottom: 10px;
}

.schedule__button {
    margin: 0 8px 0 0;
}

.schedule__icon {
    height: 24px;
    width: 27px;
    margin-right: 15px;
}

.schedule__day {
    margin-bottom: 20px;
}

.schedule__dayTitle {
    z-index: 2;
    position: sticky;
    top: 0;
    padding: 10px;
    margin-bottom: 30px;
    text-align: center;
}

.schedule__time {
    display: flex;
    gap: 12px;
}


.schedule__events {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.schedule__meetings {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.schedule__event {
    position: relative;
    flex: 1 0 17%;
    padding: 10px;
    gap: 12px;
    box-shadow: var(--default-shadow);
    background: rgba($color: #fff, $alpha: 0.75);
    cursor: pointer;
}

.schedule__event_deleted {
    background-color: #f2f2f2;
}

.schedule__event_assignment {
    background: #e2fdfb;
}

.schedule__event_evaluationPoint {
    background: #fef7d8;
}

.schedule__user {
    display: inline-block;
    padding: 4px 8px;
    margin: 4px;
    border-radius: 4px;
    background: rgba($color: #fff, $alpha: 0.5);
}

.schedule__p {
    margin-bottom: 10px;
}

.schedule__p_module {
    margin-bottom: 0;
    margin-right: 35px;
    text-transform: uppercase;
    font-size: 10px;
}

.schedule__p_wave {
    text-transform: uppercase;
    font-size: 10px;
}

.schedule__p_type {
    font-size: 14px;
    margin-bottom: 0;
}

.schedule__p_format {
    font-size: 14px;
}

.schedule__p_meeting {
    overflow: hidden;
    margin-bottom: 2px;
    padding-bottom: 2px;
}

.schedule__p_format {
    margin-bottom: 20px;
    opacity: 0.6;
    font-size: 14px;
}

.schedule__p_endTime {
    margin-bottom: 20px;
}

.schedule__p_info {
    margin-bottom: 0;
    font-size: 14px;
    letter-spacing: 0.25px;
}

.schedule__infoItem {
    display: inline-block;
    margin-right: 5px;

    &:not(:last-child):after {
        content: " |";
    }
}

.schedule__infoItem_teachers {
    display: inline;
    white-space: break-spaces;
}

.schedule__infoTeacher {
    &:not(:last-child):after {
        content: ", ";
    }
}

.schedule__eventStatus {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 6px;
    height: 6px;
}

.schedule__eventPinned {
    position: absolute;
    right: 30px;
    top: 10px;
    width: 6px;
    height: 6px;
}

.schedule__b {
    display: inline-block;
    margin-right: 4px;
    font-weight: 600;
}

// scheduleGenerationTabs

.scheduleGenerationTabs {
    display: flex;
    margin: 24px 0;
    gap: 12px;
}

.scheduleGenerationTabs__item {
    min-width: 154px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-align: center;
    box-shadow: var(--default-shadow);
    border-radius: 10px;
    background: #fff;
}

.scheduleGenerationTabs__item_active {
    cursor: default;
    background: var(--extra-gradient);
    mix-blend-mode: normal;
}

.scheduleGenerationTabs__text {
    padding: 20px 30px;
    cursor: pointer;
}

.schedule__controls {
    display: flex;
    justify-content: flex-end;
}

.schedule__setConflictButton {
    padding: 10px 12px;
}

.schedule__validate {
    margin-top: -25px;
    margin-bottom: 10px;
}

.schedule__popup_calendare {
    box-shadow: var(--default-shadow);
    border-radius: 10px;
    font-size: 14px;
    border: 0px solid;
    padding: 5px 10px 4px 11px;

    &::placeholder {
        color: #c4c4c4;
    }
}

.schedule__conflict_button {
    margin-bottom: 32px;
}

.schedule__topButtonsPanel {
    display: flex;
}

.schedule__scheduleValidateButton {
    margin-top: 8px;
    margin-left: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.schedule__scheduleValidateButtonLabel {
    font-size: 14px;
    margin-top: 4px;
}

@media screen and (max-width: 768px) {
    .scheduleGenerationTabs {
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0 0 12px 0;
        margin: 12px 0;
    }

    .schedule {
        padding: 0;
    }

    .schedule__meetings {
        flex-direction: column;
    }

    .schedule__event {
        flex: 0;
    }

    .scheduleGenerationTabs__item {
        height: 40px;
    }

    .scheduleGenerationTabs__text {
        padding: 0 12px;
    }
}
