.inputRange {
    width: 100%;
    height: 10px;
    background: #FFF;
    border-radius: 15px;
    box-shadow: var(--default-shadow);
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
    cursor: pointer;

    @mixin track() {
        height: 10px;
    }
    &::-webkit-slider-runnable-track { @include track }
    &::-moz-range-track { @include track }
    &::-ms-track { @include track }

    @mixin thumb() {
        position: relative;
        top: -3px;

        width: 16px;
        height: 16px;

        cursor: pointer;

        border: 1px solid #000;
        background-image: linear-gradient(227.47deg, rgba(11, 66, 220, 0.5) 17.19%, rgba(87, 178, 198, 0.5) 77.97%, rgba(238, 214, 96, 0.5) 97.88%);
        border-radius: 50%;
        background: #FFF;
    }
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        @include thumb;
    }
    &::-moz-range-thumb { @include thumb }
}
