.role__name {
    border: 1px solid 	#B0B0B0;
    border-left: none;
    border-bottom: none;
    font-size: 12px;
    width: 180px;
}

.role__tooltip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        position: relative;
        text-overflow: initial;
        height: fit-content;
        background: #ffffff;
        white-space: normal;
    }
}

.role__name::-webkit-scrollbar {
    display: none;
}