.Assignment {
    display: flex;
    flex-direction: column;

    button {
        pointer-events: auto;
    }
}

.Assignment__wrap {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 12px;
}

.Assignment__header {
    display: flex;
    flex-direction: column;
    padding: 24px 40px;
    gap: 12px;
    box-shadow: var(--default-shadow);
    border-radius: 15px;
    background: #fff;
    cursor: pointer;
    z-index: 2;
}

.Assignment__content {
    display: none;
    flex-direction: column;
    padding: 44px 40px 24px 40px;
    margin: -12px 0 0 0;
    pointer-events: none;
    box-shadow: var(--default-shadow);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background: #fff;
    gap: 12px;

    button {
        pointer-events: auto;
    }
}

.Assignment__subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    padding: 0 18px 0 0;
}

.Assignment__title {
    font-family: "Exo-2";
    font-weight: 600;
    word-break: break-all;
    cursor: pointer;
}

.Assignment__status {
    color: #717171;
    font-size: 14px;
    flex-shrink: 0;
}

.Assignment__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Assignment__text {
    font-size: 14px;
}

.Assignment__button {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    background: var(--main-gradient);
    border-radius: 10px;
    border: none;
    box-shadow: none;
    padding: 12px 18px;
    cursor: pointer;
    pointer-events: auto;
}

.Assignment__button_active {
    color: transparent;
    background-clip: text;
    background-image: var(--main-gradient);
    position: relative;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        padding: 3px;
        box-sizing: border-box;
        background: var(--main-gradient);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
    }
}

.Assignment__arrow {
    align-self: flex-end;
}

.Assignment__input {
    display: none;

    &:checked ~ .Assignment__content {
        display: flex;
        box-shadow: none;
    }

    &:checked + .Assignment__header {
        background: var(--extra-gradient);

        .Assignment__status {
            color: #000;
        }

        .Assignment__arrow {
            transform: rotate(180deg);

            path {
                fill: #fff;
            }
        }

        .Assignment__subtitle,
        .Assignment__title,
        .Assignment__status {
            color: #000;
        }
    }
}

.Assignment_active {
    .Assignment__status {
        color: #000;
    }

    .Assignment__content {
        color: #999;
    }

    .Assignment__subtitle,
    .Assignment__title,
    .Assignment__status {
        color: #999;
    }
}

@media screen and (max-width: 768px) {
    .Assignment__header {
        padding: 16px;
    }

    .Assignment__wrapper {
        align-items: flex-start;
        flex-direction: column;
        gap: 12px;
    }

    .Assignment__content {
        align-items: flex-start;
        padding: 24px;
    }

    .Assignment__subtitle {
        white-space: normal;
    }
}
