.button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
}

.button__roomText {
    padding: 0 12px;
}
