.schedulePage_eventsSection_noteContainer {
    margin: 12px 0;
}

.schedulePage_eventsSection_noteArea {
    display: flex;
    flex: 1;
    width: 100%;
    min-height: 110px;
    height: auto;
    resize: none;
    outline: none;
    overflow-y: visible;
    border: none;
    padding: 8px 12px;
    box-shadow: var(--default-shadow);
    border-radius: 15px;
    margin-bottom: 5px;
}

.schedulePage_eventsSection_saveNote {
    width: 100%;
    text-align: right;
    text-decoration: underline;
    font-size: 14px;
}

.schedulePage_eventsSection_addNote{
    font-size: 14px;
    position: relative;
}

.schedulePage_eventsSection_noteHeader {
    margin: 0 10px 16px 0;
    display: flex;
    align-items: center;
}

.eventNote__btn {
    display: flex;
    align-items: center;
    padding: 0;
    background: var(--main-gradient);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.schedulePage_eventsSection_noteText {
    margin-bottom: 5px;
    font-size: 14px;
    display: inline;
    background: var(--main-gradient);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.schedulePage_eventsSection_note{
    margin-top: 16px;
    word-wrap: break-word;
    word-break: break-all;
    text-overflow: ellipsis;
    font-size: 14px;
    display: flex;
    width: 100%;
    min-height: 110px;
    height: auto;
    resize: none;
    outline: none;
    overflow-y: visible;
    border: none;
    padding: 8px 12px 25px 12px;
    box-shadow: var(--default-shadow);
    border-radius: 15px;
}

.svg__Image_active {
    transform: rotate(45deg);
    margin: 0 0 0 5px;
}
