.mainDropDownOption {
    padding: 6px 11px 7px 11px;

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-size: 13px;
    line-height: 16px;
    color: #000;
    font-family: Exo-2, sans-serif;
    letter-spacing: -0.03em;
    font-weight: 500;
}

.mainDropDownOption:hover {
    background-color: rgba(11, 66, 220, 0.05);
}