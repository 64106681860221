.list {
  width: 100%;
  column-count: 2;
  column-gap: 24px;
}

.item {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 18px;
  padding: 24px;
  margin: 0 0 24px 0;
  background: #fff;
  border-radius: 15px;
  box-shadow: var(--default-shadow);
}

.main {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrap {
  display: flex;
  align-items: center;
  gap: 18px;
}

.title {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}

.subtitle {
  font-weight: 700;
  flex-shrink: 0;
}

.equipmentToRoom {
  display: flex;
  flex-direction: row;
}

.row {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.tabs {
  margin-top: 20px;

  position: relative;
}

.tabs__list {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 15px;
}

.tabs__tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 3px 0;
  margin: 0 16px -1px 0;

  cursor: pointer;

  text-align: center;
  font-weight: 600;
  font-size: 14px;
  text-align: center;

  color: #0d0d0d;
}

.tabs__tab_selected {
  cursor: default;

  background: var(--main-gradient);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  border-image-source: var(--main-gradient);
  border-bottom: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
}