.modalContainer {
    padding: 40px;
    box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
    border-radius: 15px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(calc(-50% - 100px));
}

.crossIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
