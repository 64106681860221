.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &__text {
        font-size: 13px;
        font-family: Exo-2, sans-serif;
        background: linear-gradient(270deg, #00733B 57.19%, #00AA57 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}