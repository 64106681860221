.evaluationResultsAdd {
  display: flex;
  align-items: center;
  margin-top: 18px;
}

.buttonAddEvaluationResult {
  margin-left: 15px;
}

.evaluationResult__skill {
  position: relative;
}

.skillInfo {
  display: flex;
  align-items: center;
  width: 360px;
  height: 24px;
  margin-top: 10px;
  padding: 0 3px;
  background: var(--mock-color);
}

.skillInfo__text {
  max-width: 92%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    text-overflow: inherit;
    max-width: max-content;
    position: absolute;
    text-shadow: #494743 1px 0 1px 0;
    z-index: 1;

    & ~ .iconCheckMark {
      display: none;
    }
  }
}

.skillLevel {
  font-weight: bold;
}

.iconCheckMark {
  display: inline;
  position: relative;
  top: 6px;
  & svg {
    cursor: default;
  }
}

.evaluationResult__skillRemove {
  position: absolute;
  right: -10px;
  top: 2px;
}
