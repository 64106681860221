.universitySpecialDaysEdit__title {
    font-family: 'Exo-2';
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 11px;
}

.universitySpecialDaysEdit__buttonPanel {
    align-self: flex-end;
}


.universitySpecialDaysEdit__saveButton {
    margin-left: 25px;
}