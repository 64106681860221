.infoColumns {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 12px;
}

.infoColumns__item {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border: 1px solid #ccc;
    padding: 12px 18px;
    border-radius: 15px;
}

.infoColumns__selectPoint {
    display: inline-block;
    box-sizing: border-box;
    border: 1px dashed #ccc;
    border-radius: 15px;
    font-size: 14px;
    padding: 4px 12px;
}

.infoColumns__itemTitle {
    display: inline-flex;
    font-size: 18px;
}

.infoColumns__intervalTitle {
    font-weight: 700;
}

.infoColumns__intervalCredits {
    margin-left: 4px;
}

.infoColumns__itemSubtypeList {
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
}

.infoColumns__itemSubtype {
    width: 200px;
    word-wrap: break-word;
}

.infoColumns__itemSubtypeBTI {
    margin-left:5px;
}
