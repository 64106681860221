.moduleCardHeader {
  color: black;

  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  padding: 0 25px 0;

  cursor: pointer;

}


.moduleCardHeader__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}


.moduleCardHeader__content {
  display: flex;
  position: relative;
  margin: auto 0;

  text-align: center;
  align-items: center;


}

.moduleCardHeader__point {
  position: absolute;
  left: 20px;

  svg {
    display: block;
  }
}

.moduleCardHeader__subtitle {
  font-size: 14px;
  margin: 10px 0 4px 10px;
}

.moduleCardHeader__title {
  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.03em;

  text-align: initial;

  color: #000000;
}

.moduleCardHeader__count {
  display: flex;
  align-items: center;
  gap: 8px;

  min-width: 42px;
  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  text-align: center;

  letter-spacing: -0.03em;

  padding-right: 14px;

  color: #6D6E6F;
}

.moduleCardHeader__mobileModuleName {
  display: none;
}

.moduleCardHeader__mobile {
  display: flex;
}

.eventView_arrow {
  color: blanchedalmond;
}

.moduleContent__priorities {
  display: flex;
  flex-direction: column;
  padding-left: 32px;
}

.moduleContent__priorities_title {
  font-family: "Exo-2";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #c4c4c4;

  cursor: default;
}

.moduleContent__priorities_list {
  display: flex;
  flex-direction: row;

  column-gap: 20px;

  align-items: center;

  margin: 0 10px 0 0;
}

.moduleContent__priorities_priority {
  width: 42px;
  height: 26px;

  cursor: pointer;

  background: #FFFFFF;
  border: 1px solid #BCBCBC;
  border-radius: 6px;

  font-family: "Exo-2";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  text-align: center;
}

.moduleContent__priorities_legend {
  display: flex;
  justify-content: space-between;
  padding: 5px 5px 0 0;
}

.moduleContent__priorities_description {
  font-family: 'Exo-2';
  font-style: italic;
  font-weight: 400;
  font-size: 11px;

  letter-spacing: -0.03em;

  color: #313131;
}

.moduleContent__priorities_priorityDisable {
  color: #0B42DC;
  cursor: pointer;
}

.moduleContent__priorities_priorityActive {
  background: #0B42DC;
  color: #ffffff;
  cursor: pointer;
}

.priority__container_mobile {
  display: none;
}

.priority__container {
  margin: 0;
}

@media screen and (max-width: 768px) {
  .moduleCardHeader {
    padding: 0;
    width: 80%;
  }

  .moduleCardHeader__count {
    margin-right: 0;
  }
  .moduleCardHeader__title{
    padding: 5px 0 5px 8px;
  }

  .priority__container_mobile {
    display: flex;
  }

  .priority__container {
    display: none;
  }

  .moduleCardHeader__container {
    flex-direction: row;
    justify-content: space-between;

    padding-right: 4%;
  }

  .moduleCardHeader__point {
    left: 5px;
  }

  .moduleContent__priorities {
    margin: 0;
    padding: 0;
  }

  .moduleContent__priorities_list {
    margin: 0;
  }

}