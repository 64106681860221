
.schedulePage_eventsSection_checkboxContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 30px 0 0 5px;
}

.schedulePage_eventsSection_checkboxText{
    margin-left: 17px;
}

.schedulePage_eventsSection_customCheckbox{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 0;
    border: 1px solid #5F5F5F;
    border-radius: 5px;
    cursor: pointer;
}

.schedulePage_eventsSection_checkboxNone{
    display: none;
}

.schedulePage_eventsSection_checkboxLine{
    display: block;

    width: 16px;
    height: 3px;

    background-color: black;
}

.schedulePage_eventsSection_checkboxFill{
    width: 18px;
    height: 14px;
    fill: none;
}