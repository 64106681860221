.moduleSkillsListSkillTypes {
  margin-right: 30px;
}

.moduleSkillsListSkillTypes__item {
  cursor: pointer;
  width: 126px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;

  &:last-child {
    border-bottom: 1px solid #000;
  }
}

.moduleSkillsListSkillTypes__title {
  padding: 8px 12px;
}

.moduleSkillsListSkillTypes__title_active {
  background-color: var(--mock-color);
}
