.additional {
    position: absolute;
    z-index: -1;

    width: max-content;

    padding: 10px;

    background-color: transparent;

    border: none;

    color: transparent;

    pointer-events: none;
}

.additional__info {
    line-height: 14px;

    //color: #000;

    font-size: 11px;
    font-weight: 400;
    font-family: Exo-2, sans-serif;
}
