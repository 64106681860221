.moduleSpecificMeeting__title {
    display: flex;

    cursor: pointer;
}

.moduleSpecificMeeting__name {
    display: flex;

    width: 90%;
    margin-right: 10%;
}

// .moduleSpecificMeeting__iconContainer {
//     transform: rotate(0deg);
//     transition: transform .3s;
// }

// .moduleSpecificMeeting__iconContainer_rotate {
//     transform-origin: -50% -50%;
//     transform: rotate(90deg);
// }

.moduleSpecificMeeting__icon {
    width: 6px;
    height: 12px;
}

.moduleSpecificMeeting__options {
    overflow: hidden;
}

.moduleSpecificMeeting__options_height_null {
    height: 0;
}

.moduleSpecificMeeting__formatAndDuration {
    display: flex;
    justify-content: space-between;

    margin-top: 40px;
    margin-bottom: 28px;
}

.moduleSpecificMeeting__format {
    display: flex;
    flex-direction: column;
}

.moduleSpecificMeeting__duration {
    display: flex;
    flex-direction: column;
}

.moduleSpecificMeeting__skillsItem {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}