.intervalByDates {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 5px;
}

.intervalByDates__picker {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_error {
        color: #CF1F1F;
    }
}

.intervalByDates__options {
    display: flex;
    align-items: center;
}

.intervalByDates__options_list {
    z-index: 1;
}

.intervalByDates__additional {
    position: absolute;
    z-index: 1;
    left: 232px;
    top: 43px;

    background-color: #FFF;

    padding: 10px;

    border: 1px solid #F4F4F4;
    border-radius: 6px;

    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.07);
}

.intervalByDates__additional_text {
    font-family: Exo-2, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.2px; /* 129.091% */
    letter-spacing: -0.33px;
    color: #AF5555;
}