.select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}

.select_default {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.select__header {
    width: 100%;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    background: #ffffff;
    border-radius: 15px;
    padding: 0 12px;

    &_active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
}

.select__selectedOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
}

.select__arrow {
    margin-left: 8px;
    margin-top: -4px;
    width: 16px;
    height: 16px;
}

.select__arrow_reverse {
    position: relative;
    top: 8px;
    transform: rotate(180deg);
}

.list {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 3;

    .select__selectedOption {
        padding: 0 12px;
    }
}