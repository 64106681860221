.slotParametersForm {
  margin-top: 32px;
}

.prerequisiteAndOutputGroups {
  display: flex;
  justify-content: space-between;
}

.prerequisiteGroup {
  display: flex;
  flex-direction: column;

  margin-top: 20px;
}

.outputGroup {
  display: flex;
  flex-direction: row;

  margin-top: 20px;
}

.prerequisiteAndOutputGroups__name {
  font-size: 20px;
  font-weight: 700;
}

.slotParameters__button {
  display: flex;
  justify-content: center;

  margin-top: 40px;
}
