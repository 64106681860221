.schedulePage_eventsSection_noteArea {
    display: flex;
    flex: 1;
    width: 100%;
    min-height: 110px;
    height: auto;
    resize: none;
    outline: none;
    overflow-y: visible;
    border: none;
    padding: 8px 12px;
    box-shadow: var(--default-shadow);
    border-radius: 15px;
    margin-bottom: 5px;
}