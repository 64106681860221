.create-invitation-form {
    position: relative;
    padding: 40px 55px;
    box-shadow: var(--default-shadow);
    border-radius: 30px;
    background: #FFFFFF;
}

.create-invitation-form__title {
    font-family: 'Exo-2';
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.02em;

    margin-bottom: 40px;
}

.create-invitation-form__subspace {
    box-shadow: var(--default-shadow);
    border-radius: 15px;
    width: 350px;
    margin-bottom: 40px;
}

.create-invitation-form__label {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
}

.create-invitation-form__link-container {
    display: flex;
    margin-bottom: 60px;
}

.create-invitation-form__link {
    width: 450px;
    height: 40px;
    background: #F2F2F2;
    box-shadow: var(--default-shadow);
    border-radius: 10px;
    line-height: 32px;
    padding: 4px 12px;
    margin-right: 32px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.create-invitation-form__copy-button {
    position: relative;
    width: 32px;
    height: 32px;
    margin: 4px;
    cursor: pointer;
}

.copy-button__item {
    background: #D9D9D9;
    width: 24px;
    height: 24px;
    position: absolute;

    &:nth-of-type(2) {
        top: 8px;
        left: 8px;
    }
}

.copy-button__notification {
    position: absolute;
    top: -20px;
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 600;
    color: seagreen;
}

.create-invitation-form__close-button {
    position: absolute;
    right: 40px;
    top: 30px;
}