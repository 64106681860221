.schedulePage_meetingSection_meetingTime{
    margin: 2px 0;
    text-transform: capitalize;
}

.data {
    text-transform: capitalize;
    font-size: 16px;
    color: #FFFFFF;
;
}