.evaluation__wrapperSearch {
    display: flex;
    justify-content: center;
}

.evaluation__searchBlock {
    box-shadow: var(--default-shadow);
    border-radius: 10px;
    border: 1px solid rgba(217, 217, 217, 0.25);
    width: 88%;
    display: flex;
    align-items: center;
}

.evaluation__searchMagnifier {
    width: 20px;
    height: 15px;
    margin: 0 0 0 10px;
}

.evaluation__searchInput {
    width: 100%;
    margin: 5px 0px;

    border: 0px solid;
    outline: 0px solid;
}

.evaluation__searchInput_line {
    position: absolute;
    bottom: 0px;
    left: 0px;

    width: 164px;
    height: 1px;

    background-color: black;
}
