.subSpaceTittleAndSettings {
    margin-top: 10px;
}

.subSpaceTittleAndSettings__tittle {
    width: 75%;
    margin-bottom: 52px;

    font-size: 24px;
    font-weight: 700;
    // margin-bottom: 22px;
}

.subSpaceTittleAndSettings__settingsList {
    margin-bottom: 65px;
}

.subSpaceTittleAndSettings__settingsItem {
    font-size: 18px;
}

.subSpaceTittleAndSettings__diagram {
    width: 100%;
    margin-bottom: 70px;
}

.subSpaceTittleAndSettings__roominess {
    margin-bottom: 42px;
}

.subSpaceTittleAndSettings__roominessTittle {
    margin-bottom: 7px;
}

.subSpaceTittleAndSettings__roominessList {
    display: flex;
}

.subSpaceTittleAndSettings__roominessItem:first-child {
    margin-right: 88px;
}

.subSpaceTittleAndSettings__roominessInput {
    width: 51px;
    height: 30px;
    margin-left: 7px;

    font-size: 12px;
}

.subSpaceTittleAndSettings__textareaContainer {
    display: flex;
    flex-direction: column;
}

.subSpaceTittleAndSettings__textarea {
    width: 630px;
    height: 126px;
    margin-top: 10px;
    margin-bottom: 21px;

    font-size: 16px;
}