.moduleMeetingAndEvalPoint {
    padding: 0 24px;
}

.moduleMeetingAndEvalPoint__item {
    padding: 16px;
    font-size: 14px;
    border: 1px solid #000;
    margin-bottom: 12px;

    &:last-child {
        margin-bottom: 0;
    }
}