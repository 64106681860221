.equipmentFormInput {
    display: flex;
    margin-bottom: 40px;
}

.equipmentFormInput__inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 24px;
    width: 333px;
}

.equipmentFormInput__unitInputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 130px;
}

.equipmentFormInput__nameInput {
    width: 360px;
    height: 30px;

    &::placeholder {
        font-size: 14px;
    }
}

.equipmentFormInput__unitInput {
    width: 66px;
    height: 30px;

    &::placeholder {
        font-size: 14px;
    }
}

.equipmentFormInput__deleteIcon {
   padding-left: 12px;
   align-self: center;
}

.equipmentFormInput__icon {
    width: 25px;
    height: 25px;

    cursor: pointer;
}