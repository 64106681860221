.evaluationStudentsTabs {
  display: flex;
  width: 100%;
  position: relative;
}

.tab {
  width: 50%;
  font-weight: 700;
  color: #000;
  cursor: pointer;
}

.tab_selected {
    background: var(--main-gradient);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: default;
    position: relative;

    &::after {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -8px;
        height: 1px;
        background: var(--main-gradient);
        z-index: 1;
    }
}
