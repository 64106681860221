.assignment {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 12px;
    background: #ffffff;
    border-radius: 15px;
    padding: 12px;
    word-break: break-word;
    box-shadow: var(--default-shadow);
    cursor: pointer;
}

.assignments__list {
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 40px 20px 0 20px;
    margin: -25px 0 0 0;
    background: #f2f2f2;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;

    &::-webkit-scrollbar {
        display: none;
    }

    &::after {
        content: "";
        width: 100%;
        height: 40px;
        position: sticky;
        left: 0;
        bottom: 0px;
        z-index: 2;
        background-image: linear-gradient(transparent 20%, #f2f2f2);
        flex-shrink: 0;
    }
}

.assignments__list_deadline {
    font-family: "Exo-2";
    font-weight: 600;
    font-size: 16px;
}

.assignmentList__header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 30px;
    padding: 20px;
    color: #ffffff;
    font-family: "Exo-2";
    font-weight: bold;
    font-size: 22px;
    background: var(--main-gradient);
    z-index: 2;
}

.assignmentList__header_period {
    font-size: 16px;
    font-weight: 600;
}

.assignment__module {
    font-size: 14px;
}

.assignment__topic {
    font-weight: 600;
    font-size: 14px;
}

.assignmetnt__footer {
    display: flex;
    justify-content: space-between;
}

.assignmetnt__footer_status {
    color: #c4c4c4;
    font-weight: 600;
    font-size: 14px;
}

.assignmetnt__footer_more {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 12px;
    gap: 12px;
    padding: 0;
    background: var(--main-gradient);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    justify-content: flex-end;
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .assignments {
        &__list {
            max-height: 100%;
        }
    }
}
