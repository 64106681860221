.info {
    position: relative;

    display: flex;
    align-items: center;
    gap: 5px;
}

.info:hover > .additionalInfo {
    z-index: 3;

    transition: background-color 200ms ease-in-out, color 400ms ease-in-out, border 200ms ease-in-out;

    background-color: #fff;
    border: 1px solid #F4F4F4;
    color: #161719;
}

.info__text {
    color: #A7A8AA;
    font-size: 13px;
    line-height: 16px;

    cursor: default;
}

.additionalInfo {
    position: absolute;
    top: 110%;
    left: 60%;
    z-index: -1;

    padding: 10px;

    max-width: 280px;
    width: max-content;

    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 6px;
    color: transparent;
}

.additionalInfo__text {
    font-size: 11px;
    line-height: 14px;
}