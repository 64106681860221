.page {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 0 200px 0;
}

.hide {
    display: none;
}

.page__title {
    font-family: "Exo-2";
    font-weight: 700;
    font-size: 26px;
    margin: 0 0 24px 0;
}

.page__tabs {
    width: 100%;
    max-width: 1280px;
    position: relative;
    margin: 0 0 24px 0;
    overflow-x: auto;
    overflow-y: hidden;
}

.page__tabs::before,
.page__tabs::after {
    content: "";
    width: 30px;
    height: 100%;
    position: sticky;
    top: 0;
}

.page__tabs::before {
    left: 0;
    background-image: linear-gradient(#fff, #000);
}

.page__tabs::after {
    right: 0;
    background-image: linear-gradient(#fff, #000, 180deg);
}

.content {
    width: 100%;
    display: flex;
    gap: 24px;
    flex-direction: column;
}
