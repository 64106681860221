.schedulePage_meetingBlock {
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.schedulePage__wrapper {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    margin: auto;
}

.schedulePage_meetingText{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F2F2F2;
    border-radius: 30px;
}

@media screen and (max-width: 1200px) {
    .schedulePage_meetingBlock{
        flex: 1;
        display: block;
        margin-top: 0;
        max-width: 748px;
        // min-height: 700px;
        width: 100%;
        height: fit-content;
    }
    .schedulePage__wrapper {
        overflow-y: hidden;
        position: inherit;
        max-height: inherit;
        max-width: inherit;
    }
}

@media screen and (max-width: 768px) {
    .schedulePage_meetingBlock {
        max-width: inherit;
        // min-height: inherit;
        border: none;
        margin: 0 20px;
        width: 100%;
    }
}

@media print {
    @page {
        size: auto;
        margin: 0mm;
    }

    .schedulePage_meetingBlock {
        display: none;
    }
}
