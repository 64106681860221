.authPage {
  width: 100%;
  max-width: 360px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 15% auto;
}

/* loginPassword */

.loginPassword {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.loginPassword__icon {
  margin-bottom: 30px;
}

.loginPassword__warningText {
  position: absolute;
  top: 92px;
  font-size: 12px;
  font-weight: 300;
  color: #ff0000;
}

.loginPassword__input {
  width: 100%;
  height: 38px;
  font-size: 14px;
  font-family: "Manrope";
  border: none;
  border-radius: 18px;
  outline: none;
  padding: 0 24px;

  &:focus {
    box-shadow: var(--default-shadow);
  }

  &::placeholder {
    color: #c4c4c4;
    font-size: 14px;
    font-family: "Manrope";
  }

  &:-webkit-autofill::first-line {
    font-size: 14px;
    font-family: "Manrope";
  }

  &:-webkit-autofill::first-line {
    font-family: "Manrope";
    font-size: 14px;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    font-size: 14px;
    -webkit-text-fill-color: inherit;
    border: inherit;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.loginPassword__input_border_red {
  border: 1px solid #ff0000;
  // box-shadow: 2px 2px 6px rgba(217, 217, 217, 0.25);
}

.loginPassword__button {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 12px 0;
}

.auth__button {
  width: 100%;
  padding: 12px 17px;
  border-radius: 18px;
}

.auth__text {
  width: 100%;
  color: #999;
  font-size: 14px;
  margin: 12px 0;

  a {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: var(--main-gradient);
  }
}

/* authIcon */

.authIcon {
  width: 80px;
  height: 80px;

  border-radius: 35%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.13);

  display: flex;
  align-items: center;
  justify-content: center;
}

.authIcon__icon {
  width: 44px;
  height: 34px;
}
