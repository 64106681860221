.weekDay {
    padding: 8px 6px;

    border-radius: 10px;

    width: 29px;

    background: #FFF;
    opacity: 0.3;

    box-shadow: 0px 2px 6px 0px rgba(24, 20, 50, 0.13);

    text-align: center;

    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.42px;

    cursor: pointer;
}

.weekDay_active {
    background: #0030B7;

    opacity: 1;

    color: #FFF;

    box-shadow: none;
}
