.item {
    padding-left: 25px;

    &__checkbox {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 9px 18px 11px 0;
        width: calc(100% - 30px);

        cursor: pointer;

        border-bottom: 1px solid #ECEAE9;

        &_borderTop {
            border-top: 1px solid #ECEAE9;
        }
    }

    &__title {
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
    }

    &__rating {
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
    }
}

.item:hover {
    background-color: #DBE1F0;
    //margin-top: -1px;
}

.item:hover .item__checkbox {
    //border: none;
    //padding: 10px 18px 12px 0;
}

.item:first-child:hover {
    //margin-top: 0;
}

.item_fromModule {
    background-color: #F3F3F3;

    &:hover {
        background-color: #F3F3F3;
    }
}

.item_selected {
    background-color: #DBE1F0;
}

.item_wasMoved .item__checkbox {
    //padding: 10px 18px 12px 0;
    //border: none;
}

.item_wasMoved {
    background-color: #F3F3F3;
}

.item:hover > .item__active {
    display: block;
}

.item:hover > .item__static {
    display: none;
}

.item__static {
    display: block;

    margin-left: 23px;
}

.item__static .item__checkbox {
    width: calc(100% - 7px);
}

.item__active {
    display: none;
}

.item__active_selected {
    display: block;
}

.displayNone {
    display: none;
}