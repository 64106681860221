.moduleCardInfo {
  display: flex;
  justify-content: space-between;

  overflow: auto;
  max-height: 300px;

  &::-webkit-scrollbar {
    width: 5px;
    max-height: 15px;
  }

  &::-webkit-scrollbar-track {
    border: 1px solid #f6f6f6;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(177, 177, 177, 0.2);
    border-radius: 6px;
  }
}

.moduleCardInfo__progressInfo {
  width: 60%;
  margin-right: 67px;
}

.moduleCardInfo__passedEvents {
  font-weight: 700;
  color: black;
}

.moduleCardInfo__progressBarTitle {
  display: flex;
  justify-content: space-between;
  font-family: "Exo-2";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #181818;
}

.moduleCardInfo__progressBarContainer {
  margin: 7px 0 24px 0;
}

.moduleCardInfo__progressEvent {
  display: flex;
  justify-content: space-between;
  font-family: "Exo-2";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  &:nth-child(2n) {
    margin: 4px 0;
  }
}

.moduleCardInfo__progressEvent p:last-child {
  color: #c4c4c4;
}

.moduleCardInfo__progressEvent p:last-child b {
  color: black;
}

.moduleCardInfo__description {
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  width: 100%;
  max-width: 620px;

  padding:0;
}

@media screen and (max-width: 768px) {
  .moduleCardInfo {
    flex-direction: column;
    gap: 12px;
  }

  .moduleCardInfo__progressInfo {
    width: 100%;
    margin: 0;
  }

  .moduleCardInfo__description {
    padding: 0 9px;
  }
}
