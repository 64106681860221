.userCommonInfo__block{
    padding-bottom: 50px;
}

.userCommonInfo__info {
    display: grid;
    grid-template-columns: 326px 370px;
    gap: 104px;


    align-items: flex-start;
}