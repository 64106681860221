@import "../../../scheduleStyles/filterColors";

.button {
    width: 24px;
    height: 24px;

    cursor: pointer;

    border-radius: 50%;
}

.button_isOpen {
    position: relative;
    z-index: 2;
}

.button_filter_all {
    background: linear-gradient(
        90deg,
        $color_filter_assignment 50%,
        $color_filter_meeting 50%,
    );

    &.button_isTeacher {
        background: linear-gradient(
            90deg,
            $color_filter_meeting 50%,
            $color_filter_evaluationPoint 50%,
        );
    }
}

.button_filter_meeting {
    background: $color_filter_meeting;
}

.button_filter_evaluationPoint {
    background: $color_filter_evaluationPoint;
}

.button_filter_assignment {
    background: $color_filter_assignment;
}

@media screen and (max-width: 480px) {
    .button {
        width: 18px;
        height: 18px;

        cursor: pointer;

        border-radius: 50%;
    }
}
