.actionButton {
    height: 29px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-family: Exo-2, sans-serif;
    font-weight: 500;
    border-radius: 6px;
    padding: 0 12px;
    cursor: pointer;
    position: relative;
    background: transparent;
    color: #fff;
}

.actionButton__primary {
    color: #fff;
    background: #0B42DC;

    &:hover {
        box-shadow: 0 0 0 1px #0B42DC;
    }

    &:active {
        box-shadow: none;
        color: #7E99E7;
    }
}

.actionButton__transparent {
    background-color: transparent;
    border: none;
    padding: 0;
}

.actionButton__secondary {
    border-radius: 10px;
    text-align: center;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 6px;
        padding: 1px;
        box-sizing: border-box;
        background: linear-gradient(90deg, rgba(23, 233, 132, 1), rgba(11, 66, 220, 1));
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
    }
}

.actionButton__secondary:hover::before {
    padding: 2px;
}

.actionButton__secondary:active {
    opacity: .7;
    background-color: #F8F9FE;

    &::before {
        opacity: .7;
    }
}

.actionButton__text {
    //background-clip: text;
    font-weight: 500;
    color: #0B42DC;
}

.actionButton__transparent {
    font-weight: 500;
    color: #0B42DC;
    padding: 0;
}

.actionButton__text_disabled {
    background-image: var(--disable-gradient);
}

.actionButton_disabled {
    cursor: default;

    color: #A7A8AA;
    background: #F8F9FE;

    &:hover {
        box-shadow: none;
    }

    &:active {
        color: #A7A8AA;
    }
}

.actionButton__grey {
    border: 1px solid #C7C7C7;
    border-radius: 6px;
    color: #161719;

    &:hover {
        border: 1px solid #0B42DC;
        color: #0B42DC;
    }

    &:active {
        border: 1px solid transparent;
        opacity: .7;
        background-color: #F8F9FE;
    }
}

.actionButton__grey:active::before {
    content: "";
    width: 101%;
    height: 108%;

    opacity: .7;

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    border-radius: 6px;
    padding: 1px;
    box-sizing: border-box;

    background: linear-gradient(90deg, rgba(23, 233, 132, 1), rgba(11, 66, 220, 1));
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
}

.actionButton__grey_disabled {
    pointer-events: none;
    background-color: #F8F9FE;
    color: #A7A8AA;
    border: none;
}

@media screen and (max-width: 480px) {
    .actionButton_primary {
        width: 120px;
        font-size: 12px;
    }

    .actionButton_secondary {
        width: 120px;
        font-size: 12px;
    }
}
