.EducationPeriodDates {
    position: relative;

    &__header {
        position: sticky;
        top: 141px;
        z-index: 98;
        background-color: #fff;

        display: grid;
        grid-template-columns: 414px 119px 189px;

        margin-bottom: 24px;

        border-bottom: 1px solid #ECEAE9;
        border-top: 1px solid #ECEAE9;

        &__item {
            border-right: 1px solid #ECEAE9;

            font-size: 13px;
            line-height: 16px;
            font-weight: 500;
            color: #6D6E6F;

            padding: 10px 0 10px 10px;

            &:first-child {
                padding-left: 2px;
            }

            &:last-child {
                border-right: none;
            }
        }

    }

    &__period {
        display: grid;
        grid-template-columns: 414px 119px 189px;

        border-top: 1px solid #ECEAE9;
        border-bottom: 1px solid #ECEAE9;

        &:hover {
            background-color: #F8F9FE;
            border: none;
            height: 39px;
        }

        &_active {
            background-color: #F8F9FE;
            border: none;
            height: 39px;
        }

        &_title {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 9px 0 11px 44px;
            font-size: 14px;
            line-height: 17px;

            border-right: 1px solid #ECEAE9;

            &:hover {
                border: none;
            }

            &:hover ~ .EducationPeriodDates__end {
                border: none;
            }
        }

        &:hover > .EducationPeriodDates__period_title {
            border: none;
        }
    }

    &__start {
        cursor: pointer;
        height: 100%;
        padding: 9px 0 11px 10px;

        display: flex;
        align-items: center;

        font-size: 13px;
        line-height: 16px;

        &_active {
            padding: 0;
        }

        &_noBorder {
            border: none;
        }

        &:hover {
            padding-left: 9px;
            border: 1px solid #DBE1F0;
        }

        &:hover ~ .EducationPeriodDates__end {
            border: none;
        }
    }

    &__end {
        cursor: pointer;
        height: 100%;
        padding: 9px 0 11px 10px;

        display: flex;
        align-items: center;

        font-size: 13px;
        line-height: 16px;

        border-left: 1px solid #ECEAE9;


        &_active {
            padding: 0;
        }

        &:hover {
            padding-left: 9px;
            border: 1px solid #DBE1F0;
            margin-right: 29px;
        }
    }



    &__accordions {
        display: flex;
        flex-direction: column;
        gap: 45px;
    }

    &__dates {
        display: flex;
        gap: 60px;
    }
}

.noBorder {
    border: none;
}