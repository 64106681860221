.student__skills_container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  align-items: flex-start;
  gap: 24px;
  margin: 24px 0;
}

.student__skill_type {
  padding: 18px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: var(--default-shadow);

  &::-webkit-scrollbar {
    display: none;
  }
}

.skill__title {
  color: #0d0d0d;
  font-family: 'Exo-2';
  font-weight: 700;
  font-size: 22px;
}

.student__skill {
  font-size: 16px;
  cursor: default;
}

.student__skill_disable {
  color: #c4c4c4;
}

.student__skill_level {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
}

.student__skill_level_num {
  display: flex;
  justify-self: flex-end;
  color: #0d0d0d;
}

.student__skill_fost {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 30px;
}

.student__skill_middle {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-bottom: 24px;
  margin-left: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.student__skill_terminal {
  display: flex;
  flex-direction: row;
  cursor: default;
  margin-left: 40px;
  word-break: keep-all;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.student__skill_arrow {
  margin-right: 5px;
  width: 10px;
  height: 10px;
}

.student__skill_arrow_reverse {
  margin-top: 7px;
  transform: rotate(90deg);
}

.student__skill_point {
  display: flex;
  justify-content: center;
  padding-top: 9px;
  margin-right: 5px;
  width: 10px;
}

.progress {
  display: inline-block;
  width: 100%;
  background: none;
  border-radius: 15px;
  margin: 0;
}

.progress::-webkit-progress-bar {
  height: 9px;
  background-color: white;
  border-radius: 15px;
  box-shadow: var(--default-shadow);
}

.progress::-webkit-progress-value {
  height: 10px;
  background: var(--main-gradient);
  border-radius: 15px;
}

.progress:after {
  display: inline-block;
}

.student__module_skill_level {
  color: #c4c4c4;
  font-size: 14px;
}
