.occupationDashboard {
    position: relative;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: var(--default-shadow);
    padding: 30px 24px ;
    width: fit-content;
    min-width: 100%;
    display: flex;
    flex-direction: column;
}


.occupationDashboard__table {
    display: flex;
}

.occupationDashboard__modules {
    background-color: #ffffff;
    width: 580px;
    z-index: 1;
    position: sticky;
    left: 0px;
    padding-top: 0px;
}