.Filter {
    width: 200px;
    z-index: 3;
}

@media screen and (max-width: 768px) {
    .Filter {
        width: 100%;
        max-width: 100%;
    }
}
