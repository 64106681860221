:root {
  --meeting: #cdf3a2;
  --assignment: #a5faf4;
  --evaluation-point: #fade5c;

  --past-meeting: #e1f8c7;
  --past-assignment: #b7fbf6;
  --past-evaluation-point: #fceb9d;

  --disable-gradient: linear-gradient(
    90.42deg,
    rgba(196, 196, 196, 0.04) -7.47%,
    rgba(190, 190, 190, 0.85) 45.95%,
    rgba(152, 164, 165, 0.43) 98.7%
  );

  --mock-color: #afd6f0;

  --default-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
