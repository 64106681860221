.evaluatedLocker {
    display: block;
    position: relative;
    width: 24px;
    height: 20px;
    cursor: pointer;
}

.evaluatedLocker .evaluatedLocker__toolTipText {
    position: absolute;
    z-index: 100;
    bottom: 125%;
    left: 50%;
    visibility: hidden;
    margin-left: -60px;
    padding: 5px;
    transition: opacity 0.3s;
    text-align: center;
    opacity: 0;
    color: #000000;
    border: 1px solid #000000;
    border-radius: 6px;
    background-color: #ffffff;
  }

  .evaluatedLocker:hover .evaluatedLocker__toolTipText {
    font-size: 12px;
    display: block;
    visibility: visible;
    opacity: 1;
  }
