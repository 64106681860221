@font-face {
  font-family: 'Exo-2';
  src: url(./fonts/Exo2-ExtraLight.woff2);
  font-weight: 200;
}

@font-face {
  font-family: 'Exo-2';
  src: url(./fonts/Exo2-Medium.woff2);
  font-weight: 500;
}

@font-face {
  font-family: 'Exo-2';
  src: url(./fonts/Exo2-SemiBold.woff2);
  font-weight: 600;
}

@font-face {
  font-family: 'Exo-2';
  src: url(./fonts/Exo2-Bold.woff2);
  font-weight: 700;
}

@font-face {
  font-family: 'Manrope';
  src: url(./fonts/Manrope-Regular.woff2);
  font-weight: 400;
}

@font-face {
  font-family: 'Manrope';
  src: url(./fonts/Manrope-SemiBold.woff2);
  font-weight: 600;
}