.multiautosuggest_defaultWidth {
  // width: 245px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.multiautosuggest__optionList {
  // padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.multiautosuggest__option {
  // opacity: 0.8;
  // box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 4px 12px;
  background: var(--extra-gradient);
}

.multiautosuggest__optionText {
  width: 100%;
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  padding: 0 4px 0 0;
  // word-break: break-word;
  // width: 88%;
  // min-height: 20px;
}

.multiautosuggest__unselect {
  cursor: pointer;
  width: 10px;
  height: 10px;
  position: relative;
  flex-shrink: 0;
}
