.monthScheduleHeader {
    position: relative;
    width: 100%;
    user-select: none;
}

.monthScheduleHeader__eventFilterMenu {
    position: absolute;
    bottom: 0;
    left: 4px;
}

.monthScheduleHeader__dates {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 25px;
    margin-bottom: 12px;
}

.monthScheduleHeader__dates_isScrollVisible {
    width: calc(100% - 15px);
}

.date {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% / 7);
    margin-right: 10px;
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    .monthScheduleHeader__eventFilterMenu {
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        margin: 0 0 18px 0;
    }

    .date {
        justify-content: center;
    }
}