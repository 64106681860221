.datePickerWithMontName {
    position: relative;
    padding: 8px 6px;

    width: fit-content;

    border-radius: 10px;
    background-color: #FFF;

    box-shadow: 0px 2px 6px 0px rgba(24, 20, 50, 0.13);

    cursor: pointer;
}

.datePickerWithMontName_disabled {
    opacity: .3;

    pointer-events: none;
}

.datePickerWithMontName__date {
    position: relative;
    z-index: 1;

    color: #0B42DC;

    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.42px;
}

.datePickerWithMontName_disabled .datePickerWithMontName__date {
    color: #161719;
}

.datePickerWithMontName__calendar {
    position: absolute;
    top: -3px;
    left: 2px;

    width: 0;
    height: 0;

    background-color: transparent;

    border-radius: 10px;

    color: transparent;
}

.datePickerWithMontName__calendar input {
    width: 0;
    height: 36px;

    color: transparent;
    background-color: transparent;
    padding: 0;
    margin: 0;
}
