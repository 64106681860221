.item {
    //margin-left: 60px;
    padding: 0 11px 0 60px;

    font-size: 13px;
    line-height: 16px;

    &_moved {
        background-color: #F8F9FE;
    }

    &__static {
        display: flex;
        justify-content: space-between;

        padding: 9px 0 10px 0;

        border-bottom: 1px solid #ECEAE9;

        &_red {
            color: #C11E1E;
        }
    }

    &__active {
        display: none;

        padding: 9px 0 10px 0;

        border-bottom: 1px solid #ECEAE9;
    }

    &:hover > .item__active {
        display: block;
    }

    &:hover > .item__static {
        display: none;
    }

    &__name {
        font-size: 13px;
        opacity: .6;
    }

    &__rating {
        font-size: 13px;
    }

    &__number {

    }

    &__label {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 24px);
    }
}

.item:hover {
    background-color: #F3F3F3;
}

.item_selected {
    background-color: #F3F3F3;
}

.item_fromWaves {
    background-color: #F3F3F3;
}

.item_fromNotDistributed {
    background-color: #DBE1F0;

    &:hover {
        background-color: #DBE1F0;
    }
}

.item_empty {
    border-bottom: 1px solid #ECEAE9;
}

.noOpacity {
    opacity: 1;
}

.displayBlock {
    display: block;

    padding: 9px 0 10px 0;
}

.item__interval {
    display: flex;
    align-items: center;

    height: 0;
    font-size: 11px;
    color: #0B42DC;
    padding-left: 6px;

    &_line {
        display: none;
        height: 1px;
        width: 100%;
        background-color: #0B42DC;
    }

    &_txt {
        width: 55px;
        display: flex;
        margin-right: 1px;
    }
}

.item_disabled {
    opacity: .5;
    pointer-events: none;
}

.interval {
    position: relative;
    z-index: 2;

    display: flex;
    align-items: center;

    height: 0;
    font-size: 11px;
    color: #0B42DC;
    padding-left: 6px;

    &__line {

        display: none;
        height: 1px;
        width: 100%;
        background-color: #0B42DC;

        &_red {
            background-color: #C11E1E;
        }
    }

    &__txt {
        position: relative;

        display: flex;

        width: 55px;
        margin-right: 1px;

        cursor: default;
    }
}

.interval__txt:hover ~ .interval__additional {
    z-index: 3;
    //left: 50%;
    top: 8px;
    left: 30px;

    background-color: #fff;

    border-radius: 6px;
    border: 1px solid #F3F3F3;

    color: #000;
}

.interval__additional:hover {
    z-index: 3;
    //left: 50%;
    top: 8px;
    left: 30px;

    background-color: #fff;

    border-radius: 6px;
    border: 1px solid #F3F3F3;

    color: #000;
}


.interval_red {
    color: #C11E1E;
}

.borderBottomRed {
    border-bottom: 1px solid #C11E1E;
}

.disabled {
    pointer-events: none;
}