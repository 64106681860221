.emptyTable {
    width: 100%;
    height: 100%;

    border-bottom: 1px solid #D6D6D6;
}

.emptyTable_notVisible {
    height: 60px;
    overflow: hidden;
    border-bottom: 1px solid #ECEAE9;
}

.emptyTable__header {
    position: sticky;
    z-index: 2;

    height: 60px;
    top: 37px;

    border-bottom: 1px solid #ECEAE9;

    background-color: #fff;
}

.emptyTable__row {
    height: 38px;
    background-color: #FCFCFC;

    border-top: 1px solid #ECEAE9;

    &:first-child {
        height: 37px;
        border-top: none;
    }
}