.comment {
    margin-top: 32px;
}

.comment__label {
    font-size: 14px;
}

.comment__container {
    margin-top: 8px;
}

.comment__text {
    margin-top: 16px;

    font-size: 18px;
    word-break: break-all;
}
