.EductionPeriodDates {
    position: relative;

    &__header {
        position: sticky;
        top: 142px;
        z-index: 101;
        background-color: #fff;

        display: grid;
        grid-template-columns: 227px 189px 306px;

        margin-bottom: 24px;

        border-bottom: 1px solid #ECEAE9;

        &__item {
            border-right: 1px solid #ECEAE9;

            font-size: 13px;
            line-height: 16px;
            font-weight: 500;
            color: #6D6E6F;

            padding: 9px 0 11px 10px;

            &:first-child {
                padding-left: 2px;
            }

            &:last-child {
                border-right: none;
            }
        }

    }

    &__accordions {
        display: flex;
        flex-direction: column;
        gap: 45px;
    }

    &__dates {
        display: flex;
        gap: 60px;
    }
}