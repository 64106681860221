
.rolesPicker__roles_title {
    padding-bottom: 15px;
}

.rolesPicker__roles_value {
    padding-bottom: 10px;
}

.rolesPicker {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 18px;
}

.rolesPicker__button {
    width: 200px;
    padding-bottom: 10px;
}