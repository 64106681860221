.timeSlot {
    height: 19px;
    padding: 0px;
    font-size: 9px;
    border: 1px solid #B0B0B0;
    background-color: #ffdca1;
}

.timeSlot__link {
    width: 100%;
    height: 100%;
    display: flex;
}

.timeSlot_isWorking {
    background-color: #fff;
}
.timeSlot_isNonWorking {
    background-color: #ffcdd2;
}
.timeSlot_isReservedByClassicUniversity {
    background-color: #D0D0D0;
}

.timeSlot_isReservedByTUniversity {
    background-image: radial-gradient(circle at center, #0b42dc 15%, #ffffff00 20%);
}

.timeSlot_hasTUniversityMeetingInstance {
    background-image: linear-gradient(135deg, #fff0 37.50%, #0b42dc 37.50%, #0b42dc 50%, #fff0 50%, #fff0 87.50%, #0b42dc 87.50%, #0b42dc 100%);
    background-size: 5.66px 5.66px;
}