.switcher {
  display: inline-flex;
  align-items: center;
}

.switcher input {
  width: 0;
  height: 0;

  opacity: 0;
}

.switcher__slider {
  position: relative;

  width: 32px;
  height: 18px;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  cursor: pointer;
  transition: 0.4s;

  border-radius: 15px;
  background: var(--main-gradient);

  &::after {
    position: absolute;

    width: 150%;
    height: 100%;

    content: "";
  }

  &::before {
    position: absolute;
    content: "";

    bottom: 3px;
    left: 3px;
    width: 12px;
    height: 12px;

    transition: 0.4s;

    border-radius: 50%;
    background: var(--main-gradient);

    z-index: 1;
  }
}

.switcher__slider_withLabel {
  margin-right: 10px;
}

input:checked + .switcher__slider::before {
  left: 18px;

  background: #fff;
}

input:checked + .switcher__slider > .switcher__sliderBg {
  background: var(--main-gradient);
}

.switcher__sliderBg {
  position: absolute;

  width: 28px;
  height: 14px;

  top: 2px;
  right: 0px;
  bottom: 0;
  left: 2px;

  border-radius: 15px;
  background: #fff;
}

.switcher__text {
  font-size: 14px;
}
