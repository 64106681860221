.UniversityBuildingsList {
  display: flex;
  flex-direction: row;
}

.listGroup {
  display: flex;
  flex-direction: row;

  margin-top: 18px;
  margin-bottom: 18px;
}

.listGroup__name {
  width: 35%;
}

.listGroup__buildings {
  min-width: 300px;

  text-align: left;
}

.zone__title {
  font-family: "Exo-2";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 16px;
}
