.subSpaceProfileParams__switcher {
    width: 100%;
}

.subSpaceProfileParams__list {
    margin-top: 20px;
}

.subSpaceProfileParams__item {
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }
}
