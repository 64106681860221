.tabs {
}

.tabs__item {
    position: relative;
    z-index: 110;
}

.tabs__list {
    display: flex;
    gap: 25px;
}

.tabs__link {
    display: block;

    line-height: 17px;
    font-size: 14px;
    color: #6D6E6F;
    font-weight: 500;
    font-family: Exo-2, serif;

    max-width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    padding: 7px 0 9px 0;

    text-align: center;

    &_selected {
        display: block;

        max-width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        padding: 7px 0 9px 0;

        border-bottom: 2px solid #0B42DC;
        color: #000;
    }

}

.tabs__item:hover > .tabs__additional {
    z-index: 120;
    pointer-events: all;

    top: 70%;
    left: 50%;

    background-color: #fff;
    color: #000;
    border: 1px solid #EBEBEB;

    transition: background-color 200ms ease-in-out, color 400ms ease-in-out, border 200ms ease-in-out;

}

.tabs__additional {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    pointer-events: none;

    padding: 9px 12px 11px 12px;
    border-radius: 6px;

    max-width: 232px;
    width: max-content;

    font-size: 11px;
    line-height: 17px;
    font-family: Exo-2, sans-serif;

    border: 1px solid transparent;

    background-color: transparent;
    color: transparent;

    &:hover {
        z-index: 120;

        top: 70%;
        left: 50%;

        background-color: #fff;
        color: #000;
        border: 1px solid #EBEBEB;

        transition: background-color 300ms ease-in-out, color 400ms ease-in-out;
    }
}
