.unitOfTimeButtonMobileContainer {
    position: relative;
}

.unitOfTimeButtonMobile_selected {
    background: linear-gradient(
            248.68deg, #0B42DC 18.97%, #57B2C6 73.4%, #EED660 91.23%);
                background-clip: border-box;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
}

.unitOfTimeButtonMobile_selected::after {
    display: block;

    width: 100%;
    height: 1px;
    margin: 0 auto;
    margin-top: 2px;

    content: '';

    background: linear-gradient(
            248.68deg, #0B42DC 18.97%, #57B2C6 73.4%, #EED660 91.23%);
}
