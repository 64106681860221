.calendar__popperEquipment {
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range {
        color: #000;
        background: #afd6f0;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--in-range {
        color: #000;
        background: var(--mock-color);
    }

    .react-datepicker__navigation--years-previous {
        background-image: url(../../../assets/UpDown.svg);
        background-repeat: no-repeat;
        background-position: center;
    }

    .react-datepicker__navigation--years-upcoming {
        background-image: url(../../../assets/UpDown.svg);
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(180deg);
    }

    .react-datepicker__current-month {
        margin-bottom: 6px;
    }
}

.calendar__wrapperEquipment {
    .react-datepicker__close-icon {
        display: none;
    }

    .react-datepicker__close-icon::after {
        color: #000;
        background-color: var(--mock-color);
        font-size: 14px;
    }
}

.calendar__input {
    width: 200px;
    height: 20px;
    padding-left: 5px;
    border: none;
    cursor: pointer;
    caret-color: transparent;

    &::placeholder {
        color: #c4c4c4;
    }
}
