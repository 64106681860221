@import "../../../../scheduleStyles/filterColors";

.schedulePage_eventsSection_usersBlock {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 12px 0;

    button {
        padding: 0;
    }
}

.schedulePage_eventsSection_usersHeader {
    display: flex;
    align-items: center;
}

.schedulePage_eventsSection_arrowUp {
    width: 18px;
    height: 14px;
    transition: all 0.1s ease-in;
}

.schedulePage_eventsSection_arrowDown {
    transition: all 0.1s ease-in;
    transform: rotate(90deg);
}

.schedulePage_eventsSection_userText {
    font-family: 'Exo-2', sans-serif;
    font-weight: 600;
    font-size: 18px;
    padding: 0 20px;
}

.schedulePage_eventsSection_usersList {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
