.evaluationSection {
    padding-bottom: 20px;
}

.evaluationSection__headerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.evaluationSection__switcher {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;
}

.evaluationSection__switcherText {
    margin-right: 8px;
}

.evaluationSection__unEvaluated {
    text-align: center;
}

.evaluationSection__searchContainer {
    display: flex;
    justify-content: space-between;
    margin: 20px 20px 20px 0;
}

.button__position {
    display: flex;
    justify-content: center;
}
