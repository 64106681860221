.addInterval {
    padding: 17px 14px 16px 20px;

    background-color: #F9F9F9;

    border-radius: 10px;
}

.addInterval__availableIntervals {
    display: flex;
    flex-direction: column;
    gap: 5px;

    padding-bottom: 10px;
}

.addInterval__existingIntervals {
    padding-top: 11px;

    display: flex;
    flex-direction: column;
    gap: 5px;
}

.addInterval__dates {
    //display: flex;
    //gap: 5px;
}

.addInterval__dates_picker {

}

.addInterval__dates_options {
    display: flex;
    align-items: center;
    gap: 6px;
}

.addInterval__options {
    display: flex;
    gap: 110px;

    padding-top: 20px;
}

.addInterval__options_text {
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}