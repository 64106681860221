.equipmentCardDialog {
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;
    z-index: 9999;

    background-color: rgba(0, 0, 0, 0.4);
}

.equipmentCardDialog__container {
    position: relative;

    min-width: 786px;

    background: #fff;
    box-shadow: var(--default-shadow);
    border-radius: 15px;
}

.equipmentCardDialog__content {
    display: flex;
    align-items: center;
    flex-direction: column;

    margin: 21px 60px 26px;

    font-size: 18px;
}

.equipmentCardDialog__title {
    margin-bottom: 34px;

    font-size: 24px;
    font-weight: 700;
}

.equipmentCardDialog__inputs {
    display: flex;
}

.equipmentCardDialog__inputContainer {
    position: relative;
    display: flex;
    flex-direction: column;

    &:first-child {
        margin-right: 24px;
        width: 360px;
    }
    &:last-child {
        width: 125px;
    }
}

.equipmentCardDialog__label {
    margin-bottom: 3px;
}

.equipmentCardDialog__nameInput {
    width: 360px;
    height: 30px;

    &::placeholder {
        font-size: 14px;
    }
}

.equipmentCardDialog__unitInput {
    width: 66px;
    height: 30px;

    &::placeholder {
        font-size: 14px;
    }
}

.equipmentCardDialog__button {
    margin-top: 50px;
}

.equipmentCardDialog__icon {
    position: absolute;

    top: 22px;
    right: 22px;
}
