.cell {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 25px;
    height: 25px;

    border-right: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;

    font-family: 'Exo-2';
}

.cell_planned {
    position: relative;

    background: #DFDFDF;

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: block;

        content: '';

        background: repeating-linear-gradient(
            -45deg,
            rgba(255, 255, 255, 0.3),
            rgba(255, 255, 255, 0.3) 5px,
            rgba(0, 0, 0, 0.03) 2px,
            rgba(0, 0, 0, 0.03) 9px
        );
    }
}

.cell__label {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
