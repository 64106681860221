.toolButton {
    position: relative;
}

.toolButton__radioList {
    position: absolute;
    z-index: 3;
    top: 40px;
    left: 0;

    display: none;
    flex-direction: column;

    width: 217px;
    padding-bottom: 10px;

    list-style-type: none;

    border: 1px solid #000;
    background-color: #FFF;
}

.toolButton__button {
    display: flex;
    justify-content: center;

    width: 100%;

    border-top: 1px solid #aaaaaa;;
}

.toolButton__button_width {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 10px;

    min-width: 60px;
    height: 20px;
}

.toolButton__radioList_opened {
    display: flex;
}
.toolButton__text{
    padding-left: 5px;
    width: max-content;
}

.toolButton__icon{
    min-width: 20px;
    min-height: 20px;
}

.cover__input {
    display: inline-block;
  
    width: 100%;
    height: 100%;
  
    opacity: 0;
    // cursor: pointer;
  }
  
  .cover {
    display: flex;
    align-items: center;
    //border: 1px solid #000;
    flex-direction: column;
    justify-content: center;
  
    width: 150px;
    height: 150px;
    // background-image: url('../assets/Vector.svg');
  
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .cover__image {
    width: 150px;
    height: 154px;
  }
  
  .cover__image:hover {
    z-index: -3;
  
    display: none;
    visibility: hidden;
  }
  
  .cover__input:hover {
    z-index: 3;
  }