.daySchedule {
    width: 100%;
}

.dayScheduleHeader {
    position: relative;
    width: 100%;
    height: 25px;
    margin-bottom: 12px;
    user-select: none;
}

.dayScheduleHeader__eventFilterMenu {
    position: absolute;
    bottom: 0;
    left: 4px;
}

.daySchedule__block {
    display: flex;
    height: 509px;
    position: relative;
    background: #FFF;
    border: 1px solid #F6F6F6;
    border-radius: 15px;
    overflow: hidden;
}

.daySchedule__gridWrapper {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: #C4C4C4;
        border-radius: 10px;
    }
}

.daySchedule__grid {
    flex: 1;
}

.daySchedule__rows {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(45px * 24);
    border-right: 1px solid #F6F6F6;
}

.daySchedule__row {
    width: 100%;
    height: calc(100% / 24);
    border-bottom: 1px solid #F6F6F6;
}

.daySchedule__assignments {
    width: 100%;
    left: 64px;
}

@media screen and (max-width: 1200px) {
    .daySchedule {
        width: 100%;
    }

    .daySchedule__gridWrapper {
        overflow-y: visible;
    }

    .daySchedule__grid {
        width: inherit;
        height: inherit;
        border: none;
    }

    .daySchedule__block {
        width: inherit;
        height: inherit;
        border-right: none;
    }

    .dayScheduleHeader__meetingFilterMenu {
        bottom: -24px;
        left: 18px;
    }
}

@media screen and (max-width: 768px) {
    .dayScheduleHeader__eventFilterMenu {
        bottom: -1px;
        left: 4px;
    }
    .daySchedule__grid {
        overflow-y: inherit;
    }
}

@media  screen and (max-width: 480px) {
    .dayScheduleHeader__eventFilterMenu {
        bottom: 6px;
        left: 7px;
    }
}

@media print {
    .daySchedule {
        margin: 0 auto;
        transform: scale(1.2, 1.2);
        width: 500px;
        height: 700px;
    }

    .daySchedule__block {
        height: 100%;
        border-right: none;
    }

    .daySchedule__rows {
        height: calc(29px * 24);
    }

    .daySchedule__gridWrapper {
        overflow-y: visible;
    }

    .daySchedule__grid {
        overflow-y: visible;
        border-right: none;
    }

    .daySchedule__assignments {
        width: 100%;
    }
}
