.monthSchedule {
  width: 100%;
}

.monthSchedule__block {
  width: 100%;
  position: relative;
  background: #fff;
  border: 1px solid #f6f6f6;
  border-radius: 15px;
}

.monthSchedule__grid {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 1px);
  height: calc(100% + 1px);
}

.monthSchedule__assignments {
  width: 100%;
  padding-left: 20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

@media screen and (max-width: 1200px) {
  .monthSchedule {
    width: 100%;
  }

  .monthSchedule__block {
    border-right: none;
    width: inherit;
  }

  .monthSchedule {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px 0 20px;
  }

  .monthSchedule__grid {
    width: inherit;
    height: 100%;
    border-right: none;
    border-bottom: none;
  }

  .monthSchedule__assignments {
    width: calc(100% - 1px);
  }
}

@media screen and (max-width: 768px) {
  .monthSchedule {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
}

@media screen and (max-width: 480px) {
  .monthSchedule {
    display: flex;
    flex-direction: column;
  }
}

@media print {
  .monthSchedule {
    margin: 0 auto;
    margin-top: 50px;
    transform: scale(1.2, 1.2);
  }

  .monthSchedule__grid {
    overflow-y: visible;
    height: auto;
  }

  .monthSchedule__block_scrollable {
    border-right: none;
  }

  .monthSchedule__assignments {
    width: calc(100% - 1px);
  }
}
