.ruler__timeSlot {
    height:80px;
    padding: 0px;
    position: sticky;
    top: 0;
    z-index: 10;
    border: none;
    padding: none;
}

.ruler__timeSlotContent {
    position: absolute;
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ruler__timeSlotContentText {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 15px;
    width: 90px;
    left: -43px;
    top: -3px;
    transform: rotate(90deg);
    border: none;
    font-size: 10px;
    padding: 0px;
}
