.radioList {
    position: absolute;
    z-index: 3;
    top: 40px;
    left: 0;

    display: none;
    flex-direction: column;

    width: 217px;
    padding-bottom: 18px;

    list-style-type: none;

    border: 1px solid #000;
    background-color: #FFF;
}

.radioList_opened {
    display: flex;
}

.radioList__button {
    display: flex;
    justify-content: center;

    width: 100%;

    border-top: 1px solid rgba(0, 0, 0, 0.32);
}

.radioList__button_noBorder{
    border-top: none;
}