.wysiwyg {
    margin-bottom: 8px;
}

.wysiwyg ul li {
    list-style-type: disc;
    list-style-position: inside;
}

.wysiwyg__label {
    margin: 0 0 12px 0;
    color: #000;
    font-size: 14px;
}

.ck-placeholder {
    font-size: 14px;
}

.ck-placeholder:before, .ck .ck-placeholder:before {
    color: #C4C4C4;
}

.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: none;
    min-height: 150px;
}
.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    font-size: 14px;
    min-height: 150px;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border: none;
    background: #FFF;
    box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border: none;
    background: #FFF;
    box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.ck-dropdown.ck-heading-dropdown .ck-dropdown__button .ck-button__label {
    font-size: 14px;
}

.ck.ck-toolbar {
    padding: 0 5px;
}

.ck.ck-dropdown .ck-dropdown__arrow {
    z-index: 0;
}
