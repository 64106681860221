.levelSelect {
    margin-left: 8px;
}

.skillLevel {
    display: flex;
    align-items: center;
}

.skillLevel__select {
    width: 48px;
    height: 30px;
    margin-left: 10px;
    background: #FFF;
    border: 1px solid #000;
    outline: none;
    cursor: pointer;
}

.skillLevel__checkbox {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    cursor: pointer;
}
