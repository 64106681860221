.preview {
    display: flex;
    flex-direction: column;

    margin: 0;

    word-wrap: break-word;
    margin-bottom: 32px;
}

.preview__container {
    background: #FFFFFF;
    box-shadow: var(--default-shadow);
    border-radius: 15px;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
}

.preview__text {
    font-size: 14px;
    margin-bottom: 8px;
}

.preview__button {
    padding: 0;
    color: transparent;
    background-clip: text;
    background-image: linear-gradient(30deg, #EED660 10%, #57B2C6 25%, #0B42DC);
    font-size: 12px;
    align-self: flex-end;
}

.preview__label {
    margin: 0 0 12px 0;
    color: #000;
    font-size: 14px;
}