.moduleHeader {
    display: flex;
    align-items: center;
    margin: 12px;
}

.moduleHeader__iconContainer {
    margin-right: 12px;
    height: 70px;
    width: 70px;
}

.moduleHeader__title {
    max-width: 256px;
    max-height: 46px;
    overflow: scroll;
    word-break: break-word;
    font-size: 20px;
    font-weight: 500;
}

.moduleHeader__title::-webkit-scrollbar {
    display: none;
}