.accordion {
    padding-right: 38px;

    &__header {
        display: grid;
        grid-template-columns: 348px 103px max-content;
        align-items: baseline;

        padding-bottom: 20px;

    }

    &__title {
        padding-left: 10px;

        font-size: 13px;
        line-height: 16px;
        color: #000;
        opacity: .6;

        &:first-child {
            font-size: 17px;
            line-height: 20px;
            font-weight: 600;

            padding-left: 0;
            opacity: 1;
        }
    }

    &__items {
        margin-left: 3px;
        border-top: 1px solid #ECEAE9;
        padding-left: 45px;
    }

    &__additional {
        padding-left: 27px;

        width: 100%;

        &_btn {
            text-align: left;

            padding: 10px 0;
            width: 100%;

            font-size: 13px;
            font-weight: 400;
            color: #0B42DC;

            border-bottom: 1px solid #ECEAE9;
        }
    }

    &__item {

    }

}

.header {
    display: flex;
    align-items: baseline;

    padding-left: 14px;
}