.trajectoriesMarks {
  margin-bottom: 40px;
}

.trajectoriesMarks__content {
  display: flex;
  align-items: center;

  margin-bottom: 4px;
  max-width: max-content;
}

.trajectoriesMarks__selectCircle {
  height: 24px;
  width: 24px;

  margin-right: 8px;

  border-radius: 50%;

  background: linear-gradient(
    248.68deg,
    #0b42dc 7.24%,
    #0b42dc 10.99%,
    #0b42dc 18.97%,
    #57b2c6 73.4%,
    #eed660 91.23%
  );

  cursor: pointer;
}

.trajectoriesMarks__showAllButton {
  display: flex;
  font-weight: normal;
  flex-direction: row;
  cursor: pointer;
}

.trajectoriesMarks__applyFiltersButtons {
  color: transparent;

  background-clip: text;
  background-image: linear-gradient(30deg, #eed660 10%, #57b2c6 25%, #0b42dc);

  cursor: pointer;
  align-self: center;
}

.trajectoriesMarks__marksSelect {
  position: absolute;
  min-width: 205px;

  padding: 25px 16px 16px;

  background: #fff;

  box-shadow: var(--default-shadow);
  border-radius: 15px;

  z-index: 1;
  display: flex;
  flex-direction: column;
}

.trajectoriesMarks__marksItem {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.trajectoriesMarks__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
}

.trajectoriesMarks__statusText {
  font-size: 14px;
}

.trajectoriesMarks__colorSign {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 3px;
  background-color: #ffa07a;
}

.trajectoriesMarks__colorSign_generation {
  background-color: var(--mock-color);
}

/* MarksSelectItem */

.marksSelectItem {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.marksSelectItem__type {
  display: flex;
  align-items: center;
}

.marksSelectItem__typeColor {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 3px;
}

.marksSelectItem__typeText {
  font-size: 14px;
}

.trajectoriesMarks__icon {
  position: absolute;
  top: 5px;
  right: 8px;
  cursor: pointer;
}
