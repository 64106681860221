.uploadButton {
    position: relative;

    display: inline-block;

    overflow: hidden;

    vertical-align: top;
    cursor: pointer;
}

.uploadButton__input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    opacity: 0;
    cursor: pointer;
}
