.moduleMainCharactersDescription__title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}

.moduleMainCharactersDescription__text {
  font-size: 14px;
  height: 114px;
  margin-bottom: 14px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.moduleMainCharactersDescription__empty {
  font-size: 20px;
  font-weight: 700;
  margin-top: 40px;
  text-align: center;
  height: 114px;
  margin-bottom: 0;
}

.moduleMainCharactersDescription__text::-webkit-scrollbar {
  display: none;
}