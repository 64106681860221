.progressBar {
  width: 100%;
  height: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px rgba(24, 20, 50, 0.13);
  border-radius: 15px;
}

.progressBar__percentageScale {
  height: 100%;
  background: var(--main-gradient) rgba(0, 0, 0, 0.9);
  border-radius: 10px;
}