.transparent-layer {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog {
    position: relative;
    box-sizing: border-box;
    width: 785px;
    height: 240px;
    margin: 200px auto;
    border: 1px solid #000000;
    background-color: #fff;
}

.header__button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 6px 6px 0 0;
}

.dialog__header {
    padding-top: 12px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
}

.dialog__description {
    margin-top: 12px;
    text-align: center;
}

.dialog__button {
    display: flex;
    max-width: 542px;
    margin: 0 auto;
    justify-content: space-around;
    margin-top: 40px;
}


@media screen and (max-width: 1024px) {
    .dialog {
        width: 600px;
    }
    .dialog__header {
        padding-top: 32px;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
    }
}

@media screen and (max-width: 768px) {
    .dialog {
        width: 500px;
    }
    .dialog__header {
        padding: 18px;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
    }
}

@media screen and (max-width: 567px) {
    .dialog {
        width: 400px;
    }
    .dialog__header {
        padding: 14px;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 16px;
    }
}

@media screen and (max-width: 567px) {
    .wrapper {
        padding: 0 10px;
    }
    .dialog {
        width: 400px;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: space-around ;
    }
    .dialog__header {
        padding: 14px;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
    }
    .dialog__description {
        padding: 0 15px;
        font-size: 16px;
    }
    .dialog__button {
        display: flex;
        justify-content: space-around;
        margin: 16px 0 0 0;
    }
}