.attendance_title {
    margin: 24px 0;
}

.attendance__secretItem {
    max-width: 400px;
    margin-bottom: 20px;

    list-style: auto;
}

.attendance__secretList {
    padding-left: 30px;
}

.attendance__secretDescription {
    margin-bottom: 10px;
}