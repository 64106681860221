.moduleSkillsListSpecificSkills {
  overflow: scroll;

  width: 100%;
  height: 365px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.moduleSkillsListSpecificSkills__item {
  display: flex;
  align-items: center;

  margin-bottom: 8px;

  background-color: var(--mock-color);

  &:last-child {
    margin-bottom: 0;
  }
}

.moduleSkillsListSpecificSkills__currentSkillName {
  width: 90%;
  padding: 4px 12px;
}
