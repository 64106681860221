.skill-add-portal-container {
    width: 100%;
    height: 100%;
}

.skill-add-portal {
    position: absolute;
    height: 95%;
    width: 1040px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    overflow-x: hidden;  
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.skill-add-portal-middle {
    height: auto;
    max-height: 95%;
}

.skill-add-portal-little {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.skill-add-portal__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    width: 1040px;
    min-height: 100%;
    background-color: #fff;
}

.skill-add-portal__wrapper-little {
    position: relative;
    margin: 0 auto;
    border: 1px solid #000;
    width: 765px;
    background-color: #fff;
}

.skill-add-portal__container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 40px 0;
    font-size: 18px;
}

.skill-add-portal__title {
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 35px;
}

.skill-add-portal__contant {
    width: 78%;
}

.skill-add-portal__button {
    margin-top: 15px;
}

.blackCrossAddSkillEditPortal {
    position: absolute;

    top: 15px;
    right: 25px;
}
