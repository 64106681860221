.textPreview {
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    &:last-child {
        margin-bottom: 0;
    }
}

.textPreview__button {
    padding: 0;
    color: transparent;
    background-clip: text;
    font-size: 14px;
    font-weight: 600;
    background-image: linear-gradient(30deg, #EED660 10%, #57B2C6 25%, #0B42DC);
    align-self: flex-end;
}