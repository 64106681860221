.notVotedAccordion {
    position: relative;
    width: 100%;

    &__header {
        background-color: #fff;

        width: 100%;

        font-size: 17px;
        font-weight: 600;
        line-height: 20px;

        padding-bottom: 20px;
    }

    &__children {
        padding-left: 30px;
        display: flex;
        flex-direction: column;
    }
}

.accordion {

    &__header {
        display: flex;
        align-items: baseline;

        border-bottom: 1px solid #ECEAE9;
    }
}