.form_header {
    margin-bottom: 18px;
    font-size: 24px;
    font-weight: 700;
    max-width: 600px;
}

.form_header_heading {
    margin: 0 0 24px 0;
}

.form_header_text {
    margin: 0 0 24px 0;
    font-size: 18px;
}

.form_header_select {
    width: 100%;
    height: 36px;
    padding: 0 12px;
    border: none;
    outline: none;
    box-shadow: var(--default-shadow);
    border-radius: 6px;
    background-color: #fff;
}

.form_header_title {
    margin: 0 0 16px 0;

    color: #000;
    font-family: Exo2, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.8px;
}

.placeholder {
    display: none;
}

.empty_option {
    color: #a0a0a0
}

.option {
    color: black;
}
