.items {

    &__head {
        display: grid;
        grid-template-columns: 246px 167px;

        border-bottom: 1px solid #ECEAE9;
    }

    &__count, &__filter {
        font-size: 13px;
        line-height: 16px;
        padding: 9px 0 11px 0;
        border-right: 1px solid #ECEAE9;
    }

    &__count, &__filter {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__count {
        color: #000;
        opacity: .6;
        padding-left: 71px;
    }

    &__filter {
        padding: 0;
        color: #000;
        //opacity: .4;
    }

    &__chart {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 273px;
        width: 100%;

        border-bottom: 1px solid #ECEAE9;
    }

}