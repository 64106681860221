.skillAdd {
    width: 100%;
    padding: 40px 50px;
    border-radius: 15px;
    background: #FFFFFF;
    box-shadow: var(--default-shadow);
}

.skillAdd__container {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.skillAdd__title {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
}

.skillAdd__form {
    margin-bottom: 35px;
}

.skillAdd__label {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.skillAdd__input {
    height: 30px;
    margin-top: 5px;
}

.skillAdd__textarea {
    height: 95px;
    margin-top: 5px;
    resize: none;
}

.skillAdd__addForm {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.skillAdd__addFormText {
    margin-left: 10px;
}

.skillAdd__formItem {
    width: 100%;
}

.skillAdd__saveButton {
    display: flex;
    justify-content: center;
}
