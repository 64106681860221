.wrapper {
  min-height: 30px;
  margin-top: 8px;
  display: flex;
}

.input {
  height: 30px;
  outline: none;
}

.icon {
  width: 18px;
  height: 18px;
  position: relative;
  top: 3px;
  left: 5px;
  cursor: pointer;
}

.button {
  margin: 0 8px;
  border-radius: 4px;
  outline: none;
  &:hover {
    background-color: rgb(228, 228, 228);
  }
  &:focus {
    background-color: rgb(211, 211, 211);
  }
}
