.roomTypes {
	width: 100%;
    margin-bottom: 24px;

    &__select {
        max-width: 347px;
    }
}

.roomTypes__select_container {
    gap: 16px;
}

.roomTypes_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
	list-style-position: inside;
	list-style-type: none;
    gap: 4px;
}

.room-types_items {
    display: flex;
    align-items: center;
    gap: 16px;
}

.room-types_checkbox{
	display: flex;
    gap: 12px;
}

.roomTypes__title {
    color: #000;
    font-family: Exo2, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.8px;
}

.form {
    display: flex;
    flex-direction: column;
}