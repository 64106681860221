.autosuggest {
    position: relative;
}

.autosuggest__input {
    width: 100%;
    height: 40px;
    box-shadow: var(--default-shadow);
    font-size: 14px;
    border: 0px solid;
    padding: 0 12px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &::placeholder {
        color: #c4c4c4;
        font-size: 14px;
    }
}

.autosuggest__input_withOptions {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.autosuggest__input:focus {
    outline: none;
}

.autosuggest__listContainer {
    width: 100%;
    max-height: 400px;
    position: absolute;
    top: 100%;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-shadow: var(--default-shadow);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 2;

    &::-webkit-scrollbar {
        display: none;
    }
}

.autosuggest__listContainer_hide {
    display: none;
}

.autosuggest__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 4px 12px;
    font-size: 14px;
    word-break: break-word;
    border-top: 1px solid #ccc;
    background-color: #FFF;
    cursor: pointer;

    &:first-child {
        border-radius: none;
    }

    &:hover {
        color: #fff;
        background: var(--main-gradient);
    }
}
