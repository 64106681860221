.universityZones {
  margin: 0 0 32px;
}

.universityZones__SetBtn {
  padding: 0;
  text-decoration: underline;
  color: var(--mock-color);
  border: none;
  background: #fff;
}

.universityZones__SetBtn_visited {
  padding: 0;
  text-decoration: underline;
  color: #6ba3ce;
  border: none;
  background: #fff;
  font-size: 16px;
}

.zonesAmount__zonesAmount {
  display: flex;
  align-items: center;
}

.universityZones__editBtn {
  cursor: pointer;
  padding: 0 15px 0 0;
  border: none;
  background: #fff;
}

.zonesAmount {
  display: flex;
  flex-direction: row;
}

.zonesAmount__icon {
  align-items: flex-end;
  width: 10px;
  height: 15px;
  margin-left: 4px;
}
