@import "../../../../scheduleStyles/filterColors";

.dayOfMonthCell {
  position: relative;
  width: 100%;
  max-width: calc(100% / 7);
  min-height: 100px;

  border-right: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
}

.dayOfMonth {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 6px;
  top: 4px;
  border-radius: 50%;
  font-size: 16px;
  user-select: none;
}

.dayOfMonth_isToday {
  background: var(--main-gradient);
  color: #fff;
}

.dayOfMonth_isOutsideMonth {
  color: #c4c4c4;
}

@media screen and (max-width: 768px) {
  .dayOfMonthCell {
    display: none;
  }
}

@media print {
  .dayOfMonthCell {
    height: calc(530px / 5);
  }
}
