.absence {
    position: relative;

    display: grid;
    grid-template-columns: 63px 12px 63px auto;
    //align-items: center;
    gap: 16px;

}
.absence_first {
    height: 38px;
}

.absence_first_edit .absence__delete {
    display: flex;
    height: 38px;

    margin-left: 12.5px;
}

.absence_first_edit .absence__daysAmount {
    display: none;
}

.absence_first:hover .absence__daysAmount {
    display: none;
}

.absence_first:hover .absence__delete {
    display: flex;
    height: 38px;

    margin-left: 12.5px;
}

.absence_first .absence__start:hover {
    height: 38px;
}

.absence_first .absence__start_edit ~ .absence__delete, .absence_first .absence__end_edit ~ .absence__delete {
    display: flex;
    height: 38px;

    margin-left: 12.5px;
}


.absence_first .absence__end:hover {
    height: 38px;
}

.absence_notFirst {
    display: grid;
    grid-template-columns: 125px auto;

    margin-left: 27px;

    border-bottom: 1px solid #E7E7E7;
}

.absence_notFirst:hover .absence__daysAmount {
    display: none;
}

.absence_notFirst:hover .absence__delete {
    display: flex;
}

.absence_notFirst:hover .absence__delete {
    margin-left: 0;
}

.absence_notFirst:hover .absence__start, .absence_notFirst:hover .absence__end {
    height: 38px;
}

.absence_notFirst .absence__hyphen {
    padding-top: 7px;
}

.absence_notFirstEdit {
    height: 38px;
}

.absence_notFirstEdit, .absence_notFirst:hover {
    background-color: #f8f9fe;
    //padding-top: 12px;
    margin-top: -1px;

    height: 38px;

    border-bottom: 1px solid #f8f9fe;
    border-top: 1px solid #f8f9fe;
}

.absence_notFirstEdit {
}

.absence__hyphen {
    padding-top: 10px;
}

.absence__date:hover ~ .absence__hyphen {
    color: transparent;
}
.absence__date_edit ~ .absence__hyphen {
    color: transparent;
}

.absence_notFirstEdit .absence__daysAmount {
    display: none;
}

.absence_notFirstEdit .absence__delete {
    display: flex;
}

.absence__start, .absence__end, .absence__daysAmount {
    padding: 11px 0 8px 0;
    height: 36px;

    margin: 0;

    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    font-family: Exo-2, sans-serif;
    letter-spacing: -0.03em;
}

.absence__start, .absence__end {
    cursor: pointer;
}

.absence__start:hover {
    position: relative;
    top: -1px;

    width: 89px;

    padding: 11px 14px 8px 10px;

    height: 38px;

    margin-left: -11px;

    border: 1px solid #DBE1F0;
}

.absence__start_edit {
    position: relative;
    top: -1px;

    width: 89px;

    padding: 0;

    margin-left: -11px;

    height: 38px;
}


.absence__end:hover {
    position: relative;
    top: -1px;

    width: 89px;

    padding: 11px 14px 8px 10px;

    height: 38px;

    margin-left: -11px;

    border: 1px solid #DBE1F0;
}

.absence__end:hover:before {
    // to hide hyphen
    content: '';

    position: absolute;

    width: 15px;
    height: 10px;

    left: -18px;
    top: 15px;

    background-color: #f8f9fe;


}

.absence__start, .absence__start_edit {

}

.absence__end_edit {
    position: relative;
    top: -1px;

    width: 89px;

    padding: 0;

    margin-left: -11px;

    height: 38px;
}

.absence__daysAmount {
    color: #6D6E6F;
}

.absence__delete {
    display: none;

    justify-content: center;
    align-items: center;

    padding: 0;
}

.absence__delete:hover rect {
    fill: #F3F6FD;
}

.absence__delete:hover path {
    fill: #161719;
}
