.icon {
    width: 20px;
    height: 20px;

    cursor: pointer;
}

.icon_close {
    position: absolute;
    top: 10px;
    right: 10px;
}

.icon_back {
    margin-right: 7px;
}

.icon_newClose {
    width: 9px;
    height: 9px;
}