.timeRuler {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 64px;
    height: calc(45px * 24);
    user-select: none;
    border-right: 1px solid #F6F6F6;
}

.timeRuler__hours {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: calc(45px * 23);
}

.timeRuler__time {
    margin-bottom: 1px;
    font-size: 14px;
}

@media print {
    .timeRuler {
        height: 100%;
    }

    .timeRuler__hours {
        height: calc(29px * 23);
    }
}
