.sectionForm {
  display: flex;
  align-items: center;
  flex-direction: row;

  margin-right: 14px;
  margin-bottom: 10px;

  &_down {
    display: flex;
    flex-direction: column;
  }

  &_border {
    margin: 20px 0 10px;
    padding: 30px 0 20px 65px;

    border: 1px black solid;
  }
}

.sectionForm__button {
  &_down {
    margin: 30px 0 0 304px;
  }
}

.suggestInput__suggest {
  margin-right: 9px;
}

.suggestForm {
  margin-right: 9px;
}

.suggestInput__extraText {
  margin: 0 10px 0 0;
  font-size: 18px;
}

.sectionList {
  margin: 0;
}

.suggestSelect__select {
  margin: 0 0 0 24px;
}

.suggestSection__section {
  margin: 14px 0 0;
}

.suggestSection__sectionLabel {
  margin: 24px 0 0;
}

.sectionForm__icon {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.sectionForm__iconText {
  margin: 0 0 0 5px;
}

.checkBoxForm__form {
  margin: 15px 0 0 0;
}

.inputForm {
  margin: 0 15px 0 0;
}

.hr {
  width: 606px;
  height: 1px;
  margin: 40px 0;

  background-color: black;;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.column {
  display: flex;
  flex-direction: column;

  margin: -7px 0 0 10px;
}
