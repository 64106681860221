.calendar__popperFiltration {
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range {
        color: #fff;
        background: var(--extra-gradient);
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--in-range {
        color: #fff;
        background: var(--main-gradient);
    }

    .react-datepicker__navigation--years-previous {
        background-image: url(../../../../assets/UpDown.svg);
        background-repeat: no-repeat;
        background-position: center;
    }

    .react-datepicker__navigation--years-upcoming {
        background-image: url(../../../../assets/UpDown.svg);
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(180deg);
    }

    .react-datepicker__current-month {
        margin-bottom: 6px;
    }

    .react-datepicker {
        .react-datepicker__month-container {
            width: 280px;
        }
    }
}

.calendar__wrapperFiltration {
    width: 100%;

    .react-datepicker__close-icon {
        // right: -35px;
    }

    .react-datepicker__close-icon::after {
        color: #fff;
        background: var(--main-gradient);
        font-size: 14px;
    }

    .react-datepicker__input-container {
        margin-bottom: 0;
        border: none;
    }
}

.calendar__input {
    width: 100%;
    height: 40px;
    border: none;
    cursor: pointer;
    caret-color: transparent;

    &::placeholder {
        color: #c4c4c4;
    }
}
