.room__penIcon {
    width: 18px;
    height: 18px;
    position: relative;
    top: 3px;
    left: 5px;
    cursor: pointer;
}

.room__suggestion {
    display: flex;
    margin-top: 10px;
}

.room__suggestion__list {
    border-radius: 10px;
    background: var(--extra-gradient);
    color: #000;
    padding: 10px;
    margin: 10px 30px 0 0;
}
