.assignment__container {
  background: #ffff;
  border-radius: 30px;
}
.assignment {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  font-size: 14px;
}

.assignment__header {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  border-radius: 30px;
  background: var(--extra-gradient);
  padding: 12px 20px;

  cursor: pointer;
}
.assignment__header_arrow {
  margin: 2px 5px 0 0;
}

.assignment__status {
  color: #717171;
  font-size: 14px;
}

.assignment__topic {
  font-family: "Exo-2";
  font-weight: 600;
  font-size: 16px;
  word-wrap: break-word;
}

.assignment__module {
  font-size: 14px;
}

.assignment__nextMeeting {
  font-size: 14px;
}

.assignment__deadline {
  font-size: 14px;
}

.assignment__description {
  font-size: 14px;
}

.assignment__description_title {
  font-family: "Exo-2";
  font-weight: 600;
  font-size: 16px;
}
