.section_button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.selection_button {
    height: fit-content;
    padding: 7px 12px;
}

//.paramaters__button_inactive {
//    font-size: 14px;
//    align-self: flex-end;
//    border-radius: 10px;
//    padding: 13px 14px;
//    cursor: pointer;
//    position: relative;
//    color: transparent;
//    -webkit-background-clip: text;
//    background-clip: text;
//    background-image: var(--main-gradient);
//    filter: grayscale(1);
//
//    &::before {
//        content: "";
//        width: 100%;
//        height: 100%;
//        position: absolute;
//        top: 50%;
//        left: 50%;
//        transform: translate(-50%, -50%);
//        border-radius: 10px;
//        padding: 3px;
//        box-sizing: border-box;
//        background: linear-gradient(var(--main-gradient));
//
//        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
//        mask-composite: exclude;
//    }
//}

.paramaters__selectionPointDate {
    margin-bottom: 5px;
}
.paramaters__selectionStatus{
    align-self: flex-start;
    font-size: 12px;
    text-align: center;
}