.card {
  padding: 40px 50px;
  box-shadow: var(--default-shadow);
  border-radius: 15px;
  margin: 0 0 24px 0;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.card__title {
  margin: 0 0 24px;

  font-size: 24px;
  font-weight: 700;
}

.card__section {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}
