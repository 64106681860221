.slotParameters {
  margin-top: 32px;
}

.slotParameters__moduleSize {
  color: #C4C4C4;

  font-size: 18px;
}

.slotParameters__button {
  display: flex;
  justify-content: center;
}

