.dateSelect {
    display: flex;
    align-items: center;
}

.dateSelect__view {
    width: 420px;
    padding: 0 10px;
    font-family: 'Exo-2', sans-serif;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
}

.dateSelect__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    padding: 0;
    background: #FFF;
    border-radius: 15px;
    box-shadow: var(--default-shadow);
}

.arrow {
    width: 12px;
    height: 20px;
}

@media print {
    .dateSelect__button {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .dateSelect__view {
        max-width: 300px;
        font-size: 24px;
        font-weight: 700;
    }
}

@media screen and (max-width: 480px) {
    .dateSelect__view {
        width: 185px;
        font-size: 16px;
    }
}
