.pagination ul {
    display:flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.pagination li:not(:first-child):not(:last-child) {
    height:20px;
}

.pagination_default {
    // min-width:450px;
}

.pagination__currentPage {
    background: var(--main-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.pagination__page {
    cursor: pointer;
}

.pagination__breaker {
    cursor: pointer;
}