.EducationPeriodDates {
    &__tabs {
        position: sticky;
        top: 72px;
        background-color: #fff;
        z-index: 90;

        display: flex;
        gap: 28px;
        align-items: center;

        width: 100%;

        border-bottom: 1px solid #ECEAE9;
    }
}