.legend {
    margin-bottom: 20px;
    padding: 32px 24px;

    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: var(--default-shadow);
}

.legend__title {
    margin-bottom: 20px;

    font: 600 22px/26px 'Exo-2';
    letter-spacing: -0.02em;
}

.legend__item {
    padding: 0 8px;
    border: 1px solid #C4C4C4;
    border-bottom: none;
    font-family: 'Exo-2';

    &:last-child {
        border-bottom: 1px solid #C4C4C4;
    }
}

.legend__item_planned {
    position: relative;
    background: #DFDFDF;

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: block;

        content: '';

        background: repeating-linear-gradient(
            -45deg,
            rgba(255, 255, 255, 0.3),
            rgba(255, 255, 255, 0.3) 5px,
            rgba(0, 0, 0, 0.03) 2px,
            rgba(0, 0, 0, 0.03) 9px
        );
    }

    .legend__text {
        position: relative;
    }
}
