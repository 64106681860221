@import "../../scheduleStyles/filterColors";

.eventWrapper {
    position: absolute;
    cursor: pointer;
}

.dayEvent {
    width: 100%;
    padding-left: 5px;
    cursor: pointer;
    user-select: none;
    &::after {
        content: "";
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50%;
        height: 100%;
        pointer-events: none;
        background: linear-gradient(
            90deg,
            transparent,
            #FFF 100%,
        );
    }

    &::before {
        content: '';
        width: 4px;
        height: 100%;
        position: absolute;
        left: 0;
        border-radius: 5px;
    }
}

.dayEvent:hover::after {
    background: inherit;
    z-index: -1;
}

.dayEvent__text {
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    &:hover {
        overflow: inherit;
        width: max-content;
        border-bottom: 1px solid #0B42DC;
        z-index: 2;
        padding-right: 4px;
        background-color: #FFF;
        position: absolute;
    }
}

.dayEvent_selected::after {
    content: '';
    width: 100%;
    height: 100%;
    padding: 3px 0;
    position: absolute;
    left: -1px;
    top: -4px;
    border: 1px solid #0B42DC;
    border-radius: 5px;
}

.dayEvent_filter_meeting {
    &::before {
        background: $color_filter_meeting;
    }
}

.dayEvent_filter_evaluationPoint {
    &::before {
        background: $color_filter_evaluationPoint;
    }
}

.dayEvent_filter_assignment {
    &::before {
        background: $color_filter_assignment;
    }
}

.dayEvent_inPast_meeting {
    color: #C4C4C4;
    &::before {
        background: $color_filter_inPast_meeting;
    }
}

.dayEvent_inPast_evaluationPoint {
    color: #C4C4C4;
    &::before {
        background: $color_filter_inPast_evaluationPoint;
    }
}
.dayEvent_inPast_assignment {
    color: #C4C4C4;
    &::before {
        background: $color_filter_inPast_assignment;
    }
}
@media print {
    .dayEvent {
        border: 1px solid black;
    }

    .dayEvent__decorationOpacity {
        -webkit-text-fill-color: #000;
    }
}

@media screen and (max-width: 1200px) {
    // Эти свойства, которые добавил Андрей, очень ломают расписание.
    .dayEvent {
        //overflow: inherit;
    }
    .dayEvent__decorationOpacity {
        //background: none;
    }
}