.weekDayInterval {
    display: flex;
    align-items: center;
    gap: 40px;

    width: 100%;
    height: 36px;
    padding: 0 52px 0 0;
    margin-bottom: -1px;

    border-bottom: 1px solid #ECEAE9;
    border-top: 1px solid #ECEAE9;

    &_active {
        position: relative;

        border: none;

        height: 36px;

        z-index: 3;

        background-color: #F8F9FE;
    }

    &:hover {
        position: relative;

        border: none;

        height: 36px;

        z-index: 2;

        background-color: #F8F9FE;
    }


    &__label {
        width: 82px;
        font-size: 13px;
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 16px;

        cursor: pointer;

        &:focus-within {
            padding-left: 0;
            color: transparent;
            border: none;
        }

        &:first-child {
            padding-left: 30px;
        }

        &:hover:not(.weekDayInterval__label:focus-within) {
            height: 36px;
            border: 1px solid #DBE1F0;
            padding-left: 15px;

            &:first-child {
                padding-left: 29px;
            }
        }


        //&:hover:not(.weekDayInterval__label:focus-within) {
        //    border: none;
        //}
    }

    &__input {
        padding: 0 0 0 29px;
        height: 0;

        border: none;
        outline: none;
        width: 0;

        &_end {
            padding-left: 15px;
        }

        &:focus {
            position: absolute;
            left: 0;
            width: 82px;
            height: 36px;
            border: 1px solid #0B42DC;
        }

    }

    &__week {
        padding: 8px 0;
        display: flex;
        gap: 6px;
        width: 100%;
    }

    &__timeInterval {
        width: 100%;
        display: flex;
    }
}