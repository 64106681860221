.formatFromInput {
    display: flex;
    align-items: center;
    gap: 12px;
}

.formatFromInput__input {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 460px;
    height: 36px;
    padding: 0 12px;
    font-size: 14px;
    border-radius: 15px;
    box-shadow: var(--default-shadow);
    border: none;
    outline: none;
}

.formatFromInput__deleteIcon {
    height: 25px;
}
