@import "../../../../../scheduleStyles/filterColors";

.dayOfMonthCellMobile {
  position: relative;

  display: none;
  align-items: center;
  flex-direction: column;
  width: calc(100% / 7);
  height: calc(100% / 5);
  padding: 8px 0;
  border-right: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
}

.dayOfMonth {
  display: flex;
  justify-content: center;
  height: 25px;
  user-select: none;
}

.dayOfMonth_isToday {
  position: relative;
  top: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;

  background: var(--main-gradient);
  color: #fff;
  border-radius: 50%;
}

.dayOfMonth_isOutsideMonth {
  color: #c4c4c4;
}

.meetings {
  margin-top: 13px;
}

.meetingCircle {
  width: 7px;
  height: 7px;
  margin-top: 3px;

  border-radius: 50%;
}

.meetingCircle_filter_meeting {
  background: $color_filter_meeting;
}

.meetingCircle_filter_evaluationPoint {
  background: $color_filter_evaluationPoint;
}

.meetingCircle_filter_assignment {
  background: $color_filter_assignment;
}

@media screen and (max-width: 1200px) {
  .dayOfMonthCellMobile {
    height: 100px;
  }
}

@media screen and (max-width: 768px) {
  .dayOfMonthCellMobile {
    display: flex;
  }
}
