.gradingMenu {
    padding: 28px 20px 24px 20px;
}

.grades {
    margin-top: 24px;
}

.comments {
    margin-top: 50px;
}
