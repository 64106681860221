.arrow {
    width: 18px;
    height: 14px;
    margin: 0 20px 0 16px;
    opacity: 1;
    cursor: pointer;
    transition: all 0.1s ease-in;
}

.arrow_isOpen {
    transform: rotate(180deg);
    transition: all 0.1s ease-in;
}

.arrow_isDisabled {
    width: 18px;
    height: 14px;
    opacity: 0.5;
    transition: all 0.1s ease-in;
    cursor: default;
}