.waveSelection {
    background-color: #fff;
}

.waveSelection__header {
    border-bottom: 1px solid #ECEAE9;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-right: 20px;
}

.waveSelection__content {
    display: flex;
}

.waveSelection__notDistrib {
    //width: 350px;
}

.waveSelection__waves {
    display: flex;

    border-left: 1px solid #eceae9;
    border-bottom: 1px solid #eceae9;

    height: calc(100vh - 144px);

    max-width: calc(100vw - 845px);

    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
        width: 15px;
    }

    &::-webkit-scrollbar-track {

        background-color: #FAFAFA;
        padding-bottom: 1px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E3E3E3;

        border-radius: 10px;
        border: 3.5px solid #FAFAFA;
    }

    &_required {
        max-width: calc(100vw - 455px);
        border: none;
    }
}

.loader {
    width: 100%;
    height: 400px;

    display: flex;
    align-items: center;
    justify-content: center;
}