.gradeRange {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  width: 100%;
}

.gradeRange_isEvaluated {
  flex-direction: column;
  align-items: initial;
}

.gradeRange__header {
  font-size: 14px;
}

.skillName_disabled {
  color: #c4c4c4;
}

.gradeRange__evaluationPointLevel {
  color: #c4c4c4;
}

.gradeRange__notEvaluatedText {
  color: #c4c4c4;
}

.range__quantityLevel {
  color: #c4c4c4;
}

.numberInput {
  width: 52px;
  height: 36px;
  text-align: center;
  border: 1px solid rgba(217, 217, 217, 0.25);
  outline: none;
  font-size: 16px;
  border-radius: 10px;
  box-shadow: var(--default-shadow);
}

.range {
  display: flex;
  margin-top: 10px;
}

.range__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.rangeBlock {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}

.rangeBlock__isStatus {
  margin-left: 16px;
  margin-right: 16px;
}

.rangeBlock__isEvaluated {
  margin-left: 16px;
}

.range__container_isEditing {
  margin-left: 12px;
}

.range__header {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.range__label {
  margin-bottom: 7px;
}

.rangeView {
  width: 100%;
  height: 10px;
  margin-top: 4px;
  border-radius: 6px;
  box-shadow: var(--default-shadow);
}

.rangeView__progress {
  height: 100%;
  background: var(--main-gradient);
  border-radius: 10px;
}
