.diagramOperationButton {
  box-sizing: border-box;
  height: 40px;
  padding: 0 9px;

  border: 1px solid var(--mock-color);

  font-size: 14px;
}

.diagramOperationButton_active {
  background: rgba(255, 196, 82, 0.5);
}

.diagramOperationButton_disabled {
  color: #c4c4c4;
  border: 1px solid #c4c4c4;
  background: #ffffff;
}
