.meetingDetails {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 640px;
  max-height: 90%;
  padding: 24px;
  transform: translate(-50%, -50%);
  border: 1px solid #000;
  background-color: #fff;
  font-size: 14px;
  overflow-y: scroll;
}

.meetingDetails__loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

// .meetingDetails__generation {
//   background-color: #fff,;
// }

.meetingDetails::-webkit-scrollbar {
  display: none;
}

.meetingDetails a {
  color: #3362a0;
}

.meetingDetails__paragraph {
  margin-bottom: 12px;
}

.meetingDetails__title {
  font-size: 16px;
  font-weight: 500;
}

.student__link {
  display: inline-block;
  position: relative;
  margin-left: 4px;
}

.student__link .student__toolTipText {
  position: absolute;
  z-index: 100;
  bottom: 125%;
  left: 50%;

  visibility: hidden;

  margin-left: -60px;
  padding: 5px;

  transition: opacity 0.3s;
  text-align: center;

  opacity: 0;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 6px;
  background-color: #ffffff;
}

.student__link:hover .student__toolTipText {
  font-size: 12px;

  display: block;
  visibility: visible;

  opacity: 1;
}

.student__link .student__toolTipText::after {
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 50%;

  margin-left: -5px;

  content: "";

  border-width: 5px;
  border-style: solid;
  border-color: #000000 transparent transparent transparent;
}

.meetingDetails__icon {
  position: absolute;
  right: 16px;
  top: 16px;

  width: 32px;
  height: 32px;

  cursor: pointer;

  &:hover {
    fill: rgb(110, 107, 107);
  }
}

.roomTitle__tabs {
  display: flex;
  align-items: baseline;
}

.roomTitle__tab {
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
}

.roomTitle__tab_active {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

.meetingDetails__infoItem {
  font-size: 14px;
}

.meetingDetails__status {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.meetingDetails__pinned {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 12px;
}

.meetingDetails__colorSign {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 3px;
  background-color: #2e88bb;
}

.meetingDetails__colorSign_generation {
  background-color: #fceb9d;
}

.meetingDetails__colorSign_deleted {
  background-color: #f2f2f2;
}

.meetingDetails__buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 32px;
}
