@import "../../../../../scheduleStyles/filterColors";

.schedulePage_eventsSection_usersList {
    display: flex;
    flex-direction: column;
    transition: all 1s ease-in;
}

.schedulePage_eventsSection_users {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 16px;
}

.schedulePage_eventsSection_userContainer {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;

    max-width: 332px;
    margin: 0 20px;
}

.schedulePage_eventsSection_userInfo {
    width: 100%;
    display: flex;
    align-items: center;
}

.schedulePage_eventsSection_userPicture {
    margin-right: 14px;
}

.user__avatar {
    width: 40px;
    height: 40px;
}

.userName__data {
    font-weight: 400;
}

.userName__data_currentUser {
    font-weight: 700;
}

.attendanceSaveEditBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 155px;
    height: 36px;
    margin: 32px 0 30px 0;
}

.attendanceSaveEditBtn_isEdit {
    background: #fff;
    border: 3px solid $color_selection;

    &:hover {
        background: inherit;
    }
}

.schedulePage_meetingSection_attendance {
    padding: 42px 20px 20px 20px;
}

.schedulePage_meetingsSection_attendanceText {
    font-weight: 700;
}
