.gradeCheckbox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 22px;
}

.gradeWithLabel {
    display: flex;
    align-items: center;
}

.gradeCheckbox__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    min-width: 16px;
    height: 16px;
    border: 1px solid #5F5F5F;
    cursor: pointer;
    filter: drop-shadow(0px 2px 6px rgba(24, 20, 50, 0.13));
    border-radius: 5px;
}

.gradeCheckbox__checkbox_disabled {
    cursor: default;
    border-color: #C4C4C4;
}

.gradeCheckbox__icon {
    width: 14px;
    height: 18px;
    fill: none;
    stroke: #000;
}

.gradeCheckbox__icon_disabled {
    stroke: #C4C4C4;
}

.gradeCheckbox__textBlock {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 8px;
    user-select: none;
    max-width: 220px;
    word-wrap: break-word;
}

.gradeCheckbox__textBlock_isViewMode {
    margin-left: 22px;
}

.gradeCheckbox__label_disabled {
    color: #C4C4C4;
}

.gradeCheckbox__notEvaluatedText {
    color: #C4C4C4;
}
