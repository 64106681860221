.graphOptions {
    position: relative;

    display: flex;
    align-items: flex-start;

    margin: 0 0 25px 0;
}

.graphOptions__selectContainer {
    position: relative;

    flex: 1;

    margin-right: 15px;
}

.graphOptions__label {
    padding-left: 5px;
    margin-bottom: 7px;
    font-size: 14px;
}

.graphOptions__select {
    font-size: 14px;
    width: 100%;
}

.graphOptions__eventCircle {
    position: absolute;
    right: -21px;
    top: 31px;

    width: 24px;
    height: 24px;

    border-radius: 50%;

    background-image: linear-gradient(to right, #FADE5C 0%, #CDF3A2 50%, #A5FAF4 100%);

    z-index: 1;
}

// chart
.graphOptions__chartContainer {
    position: absolute;
    right: -21px;
    top: 31px;

    width: 24px;

    margin: auto;

    z-index: 1;
  }
  
  .graphOptions__chart {
    position: relative;

    overflow: hidden;

    cursor: pointer;
  }
  
  .graphOptions__chart:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  
  .graphOptions__chartTriangle {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 50%;
    transform-origin: center bottom;
    overflow: hidden;
  }
  
  .graphOptions__chartCircle {
    height: 100%;
    width: 100%;
    background: silver;
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
    transform-origin: center bottom;
    transform: rotate(60deg);
  }

// tooltips
.nodeTooltips {
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-width: 150px;
    max-width: 700px;
    min-height: 20px;
    padding: 5px;
    border: 1px solid #CDF3A2;
    background: #E1F8C7;
    overflow: hidden;
}

.nodeTooltips_display_none {
    opacity: 0;
}
  
.nodeTooltips_color_assignment {
    border-color: #A5FAF4;
    background: #B7FBF6;
}
  
.nodeTooltips_color_evaluationPoint {
    border-color: #5c86fa;
    background: #9dc9fc;
}