.tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.tab {
    font-size: 14px;
    font-weight: 600;
    position: relative;
    cursor: pointer;

    &_active {
        &::after {
            content: '';
            width: 100%;
            height: 2px;
            position: absolute;
            top: 100%;
            left: 0;
            background: #000;
        }
    }
}
