.checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checkbox__input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.checkbox__input:checked + .checkbox__customInput {
    &::after {
        content: url('../AdminTrajectories/assets/check.svg');
        position: absolute;
        left: 2px;
        top: -3px;
    }
}

.checkbox__customInput {
    position: relative;

    width: 20px;
    height: 20px;

    margin-right: 15px;

    border-radius: 5px;
    border: 1px solid #5F5F5F;
    cursor: pointer;
}

.checkbox__customInput_minus {
    &::after {
        content: url('../AdminTrajectories/assets/minus.svg');
        position: absolute;
        left: 2px;
        top: -45%;
    }
}

.checkbox__label {
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
}