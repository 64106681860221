.schedulePage_meetingHeader {
  width: 100%;
  color: #ffffff;
  display: flex;
  background: var(--main-gradient);
  border-radius: 30px;
}

.schedulePage__eventHeader_wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

@media screen and (max-width: 1200px) {
  .schedulePage_meetingHeader {
    position: static;
  }
}
