.equipmentSuggestion__suggestion {
  display: flex;
  align-items: center;
}

.equipmentSuggestion__input {
  width: 336px;
}

.equipmentSuggestion__listContainer {
  width: 336px;
}

.equipmentSuggestion__icon {
  width: 26px;
  height: 26px;

  margin-left: 8px;

  cursor: pointer;
}

.equipmentSuggestion__equipmentItem {
  width: 336px;
}

.equipmentSuggestion__itemContainer {
  position: relative;

  display: flex;
  align-items: center;

  margin-top: 10px;
  padding: 3px 30px 3px 4px;

  background-color: var(--mock-color);

  font-size: 14px;
}

.equipmentSuggestion__crossIconContainer {
  position: absolute;
  display: flex;

  right: -9px;
}

.equipmentSuggestion__crossIcon {
  width: 18px;
  height: 18px;

  cursor: pointer;

  fill: #c4c4c4;
}
