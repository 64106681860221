.wave {

}

.wave__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 5px 0 4px 0;

    margin-left: 40px;

    border-bottom: 1px solid #ECEAE9;

    &_borderTop {
        border-top: 1px solid #ECEAE9;
    }
}

.wave__title {
    display: flex;
    gap: 2px;

    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    font-family: Exo-2, sans-serif;


    &_gray {
        display: flex;
        align-items: center;

        color: #6D6E6F;

    }

    &_default {
        display: flex;
        align-items: center;
    }

    &_red {
        color: #C11E1E;
    }
}

.wave__sort {
    display: flex;
    align-items: center;
    gap: 3px;
}

.wave__sort_txt {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: #6D6E6F;
}

.wave__sort_btn {
    padding: 0;

    display: flex;
    align-items: center;
}

.wave__sort_btn:hover > svg > rect {
    fill: #F3F6FD;
}

.wave__sort_btn:hover > svg > path {
    fill: #161719;
}

.wave__sort_btn:active > svg > path {
    fill: #0B42DC;
}