.educationPeriodSchedule {
    display: flex;
    flex-direction: column;
    gap: 28px;

    &__edit {
        min-height: 29px;
        height: auto;
    }

    &__header {
        font-size: 17px;
        font-weight: 600;
    }

    &__schedule {

        &__title {
            font-size: 14px;
            padding-bottom: 16px;
            font-weight: 600;
            font-family: Exo-2, sans-serif;
        }
    }

    &__items {
        padding: 14px 0 0 30px;
        margin-top: 15px;

        display: flex;
        flex-direction: column;
        gap: 36px;
    }
}