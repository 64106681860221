.infoCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 15px 21px;
  background-color: #F8F9FE;
  font-size: 13px;

  color: #252525;

  overflow: auto;
  max-height: 900px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  cursor: default;
}

.infoCard::-webkit-scrollbar {
  display: none;
}

.infoCard__whithArrow {
  cursor: pointer;
}

.infoCard__alertIcon {
  margin-top: -40px;
  margin-left: -76px;
}

.infocard__title {
  font-family: "Exo-2";
  color: #0B42DC;
  font-size: 13px;
}

.choicePoint__subtitle {
  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.03em;

  color: #393939;

  display: flex;
  justify-content: space-between;

  padding: 10px 15px 10px;

  border-bottom: 1px solid #ECEAE9;
}

.choicePoint__subtitleItem {
  display: flex;

  align-items: center;
  gap: 5px;

  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  margin-left: 3px;

  letter-spacing: -0.03em;

  color: #393939;
}

.infoCard__arrow {
  position: absolute;
  right: 40px;
}

.choicePoint {
  display: flex;
  align-items: center;

  margin: 10px 0 30px;

  cursor: pointer;
}

.choicePoint__infoCard {
  background: #F9F9F9;
  border-radius: 6px;
}

.choicePoint__open {
  display: flex;
  align-items: center;

  margin: 10px 0 0;

  cursor: pointer;
}


.choicePoint__title {
  font-family: "Exo-2";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.03em;

  width: 100%;
  display: contents;

  cursor: pointer;

  color: #000000;
}

.choicePoint__selectCount {
  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  letter-spacing: -0.03em;

  padding-left: 3px;

  display: flex;
  justify-content: flex-start;

  color: #6D6E6F;

  &_selectedAll {
    color: #0B42DC;
  }
}

.choicePoint__RemainingPriorities_text {
  font-size: 14px;
  color: #000000;
  padding: 8px 0;
}

@media screen and (max-width: 600px) {
  .choicePoint {
    justify-self: start;

    padding-top: 7px;
  }

  .choicePoint__selectCount {
    padding: 0;
  }

  .choicePoint__title {
    display: block;
  }

  .choicePoint__subtitle {
    padding-right: 0;
  }

  .choicePoint__open {
    justify-self: start;

    padding-top: 7px;

    background: #F9F9F9;
    border-top: 6px;
  }

  .choicePoint__infoCard {
    border-top: 0;
  }
}