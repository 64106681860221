.input {
  display: flex;
  flex-direction: column;
  width: 100%;

  margin: 0;
}

.input_inline {
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.input__label {
  font-weight: 700;
	font-size: 18px;
  margin: 0 0 12px 0;
}

.input__input {
  width: 100%;
  height: 36px;
  padding: 0 12px;
  border: none;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
  border-radius: 6px;
  background-color: #fff;
}

.input_number {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.input__label_number {
  display: flex;
  flex-direction: row;
  margin: 0 6px 0 0;
}

.input__input_number {
  min-width: 60px;
  max-width: 60px;

  text-align: center;

  &::placeholder {
    color: #C4C4C4;
  }
}

.input__input_input {
  display: flex;
  align-content: center;
  flex-direction: row;
}

.bold {
  font-weight: bold;
}

.formGroup__label {
  margin-right: auto;
}
