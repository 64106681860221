.suggest {
    width: 100%;
    height: 36px;

    cursor: pointer;

    border: none;
}

.suggest_size-s {
    width: 204px;
    height: 35px;
}

.suggest_size-l {
    width: 360px;
}

.suggest-input {
    display: flex;
    align-items: center;
    flex-direction: row;

    width: 100%;
    height: 100%;
}

.suggest-input_text {
  margin-bottom: 19px;
}

.suggest-input_minmax {
  align-items: center;
  flex-direction: row;
}

.suggest-input__label {
    padding-right: 10px;
    color: #000;
    font-size: 18px;
}