.evaluationMultipleStudents {
    position: relative;
    margin-top: 14px;
    padding: 0 20px;
}

.evaluationSection__searchContainer {
    display: flex;
    justify-content: space-between;
    margin: 20px 20px 20px 0;
}

.evaluationSection__chooseBlock {
    display: flex;
    flex-direction: column;
}


.evaluationSection__peopleImg {
    width: 30px;
    height: 30px;
}

.evaluationSection__studentsChoose {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 32px;
    padding: 18.5px 0px;
    border: 1px solid rgba(24, 20, 50, 0.13);
    box-shadow: var(--default-shadow);
    border-radius: 15px;
    background: rgba(217, 217, 217, 0.25);
}

.evaluation__arrowBlock_open {
    background: transparent;
    padding: 12px 0;
}

.evaluationSection__studentsChoose_text {
    font-weight: 400;
    padding: 0 20px;
}

.evaluationSection__studentsChoose_textBold {
    font-size: 20px;
    font-weight: 700;
}

.evaluation_arrowBlock {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.multipleEvaluationQuantity {
    margin: 6px 5px 0 0;
}
