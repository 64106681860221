.pencil {
  width: 10px;
  height: 15px;

  border: none;
  background: #fff;

  fill: #000;
}

.circlePlus {
  width: 25px;
  height: 25px;
  margin-right: 5px;

  fill: #afd6f0;
}

.circlePlus_disabled {
  width: 25px;
  height: 25px;
  margin-right: 5px;

  fill: #c4c4c4;
}

.circleCross {
  width: 25px;
  height: 25px;
  margin-right: 5px;

  color: #000;
}

.circleCross_grey_20 {
  width: 20px;
  height: 20px;
}
