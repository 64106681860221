.skill-nested-card__content {
    position: relative;
}

.skill-nested-card__skill-name-container{
    display: flex;
    align-items: baseline;
}

.skill-nested-card__skill-name {
    display: inline-block;
    width: 100%;
    font-size: 22px;
    font-weight: 700;
    overflow-wrap: break-word;
}

.skill-nested-card__skill-name--edit {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.skill_nested_card__skill_name_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.skill-nested-card__skill-children:last-child {
    margin-bottom: 8px;
}

.skill-nested-card__button-edit {
    position: absolute;
    top: -14px;
    right: -8px;
}


.skill-nested-card__child-name-container {
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
}

.skill-nested-card__child-name {
    width: 100%;
    padding: 0 0 0 18px;
}

.skill-nested-card__child-of-child {
    overflow: hidden;

    margin-top: 5px;
    margin-left: 22px;
}

.skill-nested-card__child-of-child--null-height {
    height: 0;
}

.skill-nested-card__child-of-child-item {
    // margin-bottom: 5px;

    &:hover {
        text-decoration: underline;
    }
}

.skill-nested-card__child-of-child-container {
    display: flex;
    align-items: center;
}

.skill-nested-card__child-of-child-name {
    font-size: 14px;
    margin-right: 5px;
    width: 73%;
    cursor: pointer;
}

.skill-nested-card__child-name-triangle {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%) rotate(-90deg);
    border: 8px solid transparent;
    border-top: 8px solid #555;
    transition: transform .2s;
    transform-origin: center;
    cursor: pointer;
}

.skill-nested-card__child-name-triangle--none-desc {
    &::after {
        opacity: 0;
        border-left-color: #555;
    }
}

.skill-nested-card__child-name-triangle--active {
    transform: rotate(0deg);
}

.skill-add-portal {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
  }

  .skill-add-portal__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 24px;
    border: 1px solid #000;
    min-width: 700px;
    max-height: 100px;
    background-color: #fff;
  }

  .skill-add-portal__container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 20px 60px;
    font-size: 18px;
  }

  .skill-add-portal__buttons {
    width: 50%;
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
  }

.blackCrossAddSkillPopUp {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 8px;
    right: 8px;
    cursor: pointer;

    &:hover {
        fill: rgb(110, 107, 107);
    }
}

.yellow-cross-15px {
    height: 15px;
    width: 15px;
    cursor: pointer;
}

.edit-pen-skill-with-nest {
    cursor: pointer;
    width: 17px;
    height: 17px;
    margin-left: 100px;
}
