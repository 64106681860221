.calendar__popperUsers {
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__year-text--in-selecting-range {
    color: #fff;
    background: var(--extra-gradient);
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--in-range {
    color: #fff;
    background: var(--main-gradient);
  }
  .react-datepicker__navigation--years-previous {
    background-image: url(../../../assets/UpDown.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .react-datepicker__navigation--years-upcoming {
    background-image: url(../../../assets/UpDown.svg);
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg);
  }
  .react-datepicker__current-month {
    margin-bottom: 6px;
  }
}

.calendar__wrapperUsers {
  .react-datepicker__close-icon::after {
    color: #fff;
    background: var(--main-gradient);

    font-size: 14px;
  }
  .react-datepicker__input-container {
    margin-bottom: 0;
    border: none;
  }
  .calendar__input {
    width: 200px;
    height: 30px;
    padding-left: 5px;
    margin: 0;
    border: none;
    background-color: #fafafa;
    font-size: 14px;

    &::placeholder {
      color: #c4c4c4;
      font-size: 14px;
    }
  }
}

.calendar__input_timer {
  width: 313px;
  border: 1px solid #000;
  border-right: none;
}

.react-datepicker__close-icon {
  padding-right: 10px;
}
