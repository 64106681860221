.diagramOperationPanel {
  display: flex;
  flex-direction: row;

  margin-top: 20px;
}

.diagramOperationPanel__slotsButtons {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.buttonGroup {
  display: flex;
  justify-content: flex-start;
}

.buttonGroup__relationButton {
  margin-left: 20px;
}

.removeButtonGroup {
  display: flex;
  justify-content: flex-end;
}

.diagramOperationPanel__editModeButton {
  width: 53px;
  height: 53px;
}





