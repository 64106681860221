.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

// https://labs.danielcardoso.net/load-awesome/animations/ball-clip-rotate-multiple.html
/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */

.loader,
.loader > div {
    position: relative;
    box-sizing: border-box;
}

.loader {
    display: block;
    font-size: 0;
    color: #fff;
}

.loader.la_dark {
    color: #333;
}

.loader > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
}

.loader {
    width: 32px;
    height: 32px;
}

.loader > div {
    position: absolute;
    top: 50%;
    left: 50%;
    background: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 100%;
    animation: ball-clip-rotate-multiple-rotate 1s ease-in-out infinite;
}

.loader > div:first-child {
    position: absolute;
    width: 32px;
    height: 32px;
    border-right-color: transparent;
    border-left-color: transparent;
}

.loader > div:last-child {
    width: 16px;
    height: 16px;
    border-top-color: transparent;
    border-bottom-color: transparent;
    animation-duration: 0.5s;
    animation-direction: reverse;
}

.loader.la_sm {
    width: 16px;
    height: 16px;
}
.loader.la_sm > div {
    border-width: 1px;
}
.loader.la_sm > div:first-child {
    width: 16px;
    height: 16px;
}
.loader.la_sm > div:last-child {
    width: 8px;
    height: 8px;
}
.loader.la_2x {
    width: 64px;
    height: 64px;
}
.loader.la_2x > div {
    border-width: 4px;
}
.loader.la_2x > div:first-child {
    width: 64px;
    height: 64px;
}
.loader.la_2x > div:last-child {
    width: 32px;
    height: 32px;
}
.loader.la_3x {
    width: 96px;
    height: 96px;
}
.loader.la_3x > div {
    border-width: 6px;
}
.loader.la_3x > div:first-child {
    width: 96px;
    height: 96px;
}
.loader.la_3x > div:last-child {
    width: 48px;
    height: 48px;
}
/*
* Animation
*/
@keyframes ball-clip-rotate-multiple-rotate {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    50% {
        transform: translate(-50%, -50%) rotate(180deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
