.article {

}

.article svg {
    flex-shrink: 0;
}

.article__header {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding: 0 48px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: var(--default-shadow);
    cursor: pointer;
    user-select: none;
}

.article_active .article__header {
    background-image: var(--extra-gradient);
    box-shadow: none;
}

.article_active .article__header svg path {
    fill: white;
}

.article_active .article__content {
    // visibility: visible;
    // pointer-events: auto;
}

.article__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: -24px 0 0 0;
    padding: 24px 48px 48px;
    border-radius: 15px;
    background: #fff;
    // visibility: hidden;
    // pointer-events: none;
}

.article__title {
    font-weight: 600;
    font-size: 16px;
    margin: 18px 0;
}

.controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.sort {
    display: flex;
    align-items: center;
    gap: 8px;
}

.sort__button {
    font-weight: 600;
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    background: var(--main-gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .article__header {
        padding: 0 18px;
    }

    .article__content {
        padding: 24px 12px;
    }

    .article__tab {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .controls {
        align-items: flex-start;
        flex-direction: column;
        gap: 24px;
    }
}
