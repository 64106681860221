.legend {
    display: grid;
    height: 80px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    position: sticky;
    top: 0px;
    left: 0px;
    background-color: white;
    font-size: 9px;
}

.legend__firstColumnCell {
    display: flex;
    flex-direction: column;
    grid-column:1 / 2;
    grid-row: 1 / 2;
}

.legend__secondColumnCell {
    display: flex;
    flex-direction: column;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.legend__rowCell {
    display: flex;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}

.legend__columnName {
    font-size: 12px;
    border: 1px solid #B0B0B0;
    border-right: none;
}

.legend__columnName:last-of-type {
    border: 1px solid #B0B0B0;
    border-right: 1px solid #B0B0B0;
}

.legend__item {
    display: flex;
}

.legend_color {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: 1px solid black;
    margin-right: 5px;
}

.legend_color_red {
    background-color: rgb(255, 205, 210);
}

.legend_color_orange {
    background-color: rgb(255, 220, 161);
}

.legend_color_gray {
    background-color: #D0D0D0;
}

.legend_color_white {
    background-color: white;
}