.formatForm {
    width: 100%;
    max-width: 786px;
    border-radius: 15px;
    background-color: #fff;
    padding: 40px 50px;
    box-shadow: var(--default-shadow);
}

.formatForm__container {
    display: flex;
    flex-direction: column;
}

.formatForm__title {
    margin-bottom: 34px;
    font-size: 24px;
    font-weight: 700;
}

.formatForm__subtitle {
    margin: 0 0 12px 0;
    font-size: 18px;
}

.formatForm__list {
    margin-bottom: 28px;
}

.formatForm__item {
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }
}

.formatForm__iconContainer {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    cursor: pointer;
}

.formatForm__button {
    align-self: center;
}
