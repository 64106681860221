.label {
    width: 82px;
    font-size: 13px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 16px;

    cursor: pointer;

    &:focus-within {
        padding-left: 0;
        color: transparent;
    }

    &:first-child {
        padding-left: 30px;
    }
}

.input {
    padding: 0 0 0 29px;
    height: 0;

    border: none;
    outline: none;
    width: 0;

    &_end {
        padding-left: 15px;
    }

    &:focus {
        position: absolute;
        left: 0;
        width: 82px;
        height: 36px;
        border: 1px solid #DBE1F0;
    }

}