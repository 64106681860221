.selectedItem {
  display: flex;
  align-items: center;
  flex-direction: row;

  margin-bottom: 5px;

  list-style-type: none;
  &:last-child {
    margin-bottom: 10px;
  }
}

.selectedItem__item {
  box-sizing: border-box;
  width: 326px;
  margin-right: -10px;
  padding: 4px 12px 4px 4px;

  background-color: var(--mock-color);
}

.selectedItem__item_title {
  width: 394px;
  height: 60px;
}

.selectedItem__text {
  margin: 0;
  word-wrap: break-word;
  color: #000;
  font-size: 14px;
}

.selectedItem__text_title {
  font-size: 18px;
  font-weight: 700;
}

.selectedItem__block {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  word-wrap: break-word;
  color: #000;
  font-size: 14px;
}

.selectedItem__span {
  padding-left: 4px;

  font-weight: bold;
}
