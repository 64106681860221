.eventHeader__info {
    font-family: 'Exo-2';
    font-size: 18px;
    font-weight: 700;
}

.eventHeader__infoTop {
    display: flex;
    flex-direction: column;
    margin: 12px 0;
}

.eventHeader__infoTop_type {
    padding-right: 5px;
}

.eventHeader__infoTop_name {
    display: flex;
    font-size: 18px;
    font-weight: 700;
    word-break: break-word;
}

.eventHeader__moduleName {
    font-size: 16px;
}

.eventHeader__infoBottom_format {
    font-size: 14px;
    font-weight: 600;
}


@media screen and (max-width: 1200px) {
    .schedulePage_meetingHeader_meetingInside {
        font-size: 14px;
    }

    .schedulePage_meetingHeader_meetingName{
        margin: 10px 10px;
        font-size: 24px;
    }
}

@media screen and (max-width: 480px) {
    .schedulePage_meetingHeader_square {
        width: 68px;
        height: 63px;
    }

    .schedulePage_meetingHeader_meetingInside {
        font-size: 12px;
    }

    .schedulePage_meetingHeader_meetingName{
        margin: 0px;
        font-size: 18px;
    }
}
