.votedChart {
    height: 100%;
    width: 100%;
    padding: 20px 38px 20px 71px;

    &__overflow {
        height: 100%;
        display: grid;
        //gap: 22px;

        width: 710px;
        overflow-x: auto;
    }
}

.chartItem {
    min-height: 26px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    &:hover > .chartItem__info {
        gap: 4px;

        z-index: 3;

        border: 1px solid #EBEBEB;

        color: #000;

        background-color: #fff;

        transition: all 400ms ease-in-out;
    }

    &__item {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }

    &__info {
        position: absolute;

        display: flex;
        flex-direction: column;

        background-color: transparent;

        border-radius: 6px;

        width: 138px;

        max-height: 150px;
        overflow-y: auto;

        top: 30%;
        left: 110%;
        z-index: -1;

        font-size: 11px;
        color: transparent;

        padding: 10px 14px;

        &_bold {
            font-weight: 600;
        }

        border: 1px solid transparent;

        &:last-child {
            //left: 0;
            //right: 110%;
        }
    }

    &__full {
        width: 100%;
        background-color: rgba(199, 199, 199, .3);
        display: flex;
        align-items: flex-end;
        min-height: 40px;
    }

    &__plenum {
        min-height: 20px;
        width: 100%;


        display: flex;
        justify-content: center;
        align-items: flex-start;

        background-color: #0B42DC;
    }

    &__voted {
        font-size: 13px;
        color: #fff;
    }

    &__title {
        font-size: 13px;
        color: #000;
        opacity: .6;
    }

    &__caption {
        text-align: center;
        font-size: 11px;
        font-weight: 400;
    }
}