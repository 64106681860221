.usersImportPopUpContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 32px 68px 39px;

    background-color: #fff;

    border-radius: 4px;

    box-shadow: 0 2px 6px 0 rgba(24, 20, 50, 0.13);
}

.usersImportPopUpContent__text {
    width: 300px;

    margin-top: 15px;

    margin-bottom: 24px;

    text-align: center;
    font-size: 17px;
    font-family: Exo-2, sans-serif;
    line-height: 20.4px;
    font-weight: 400;
    letter-spacing: -0.51px;
}

.usersImportPopUpContent__text_bold {
    margin: 0;

    font-weight: 600;
}