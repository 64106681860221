.diagram {
  overflow: auto;

  margin-left: 12px;

  font-size: 14px;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
}

.legend {
  border: 1px solid black;

  &__item {
    padding-left: 5px;
  }
}

.diagram__axesContainer {
  overflow: hidden;

  width: 732px;
  margin-top:-325px;
  margin-left: -14px;
}

.diagram__axes {
  z-index: 2;

  width: 1142px;
  margin-left: 12px;
}

.diagram__sectionPoint {
  width: 35px;
  height: 35px;
  margin-top:2px;
  margin-left: -20px;

  cursor: pointer;
}

.diagram__sectionPoint_inactive {
    opacity: 0.3;
}

.diagram > div:first-of-type div {
  margin-top:2px;
  margin-left: -5px;
}