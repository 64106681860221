.unitOfTimeSelectMobile {
    display: none;
}

@media screen and (max-width: 1200px) {
    .unitOfTimeSelectMobile {
        display: flex;

        margin: 17.5px 0;
    }
}
