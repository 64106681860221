.textarea {
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    margin-bottom: 8px;
}

.textarea__container {
    overflow-y: hidden;
    background: #FFF;
    box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
    border-radius: 15px;
}
.textarea__item {
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    margin: 0;
    padding: 8px 12px;
    resize: none;
    border: none;
    outline: none;
    overflow-y: auto;
    font-size: 14px;

    &::placeholder {
        font-size: 14px;
        color: #C4C4C4;
    }

    &::-webkit-scrollbar-track {
        border: 1px solid #F6F6F6;
        border-radius: 10px 30px 10px 10px;
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(196, 196, 196, 0.2);
        border-radius: 6px;
    }
}

.textarea__item_description {
    height: 173px;
}

.textarea__item_autosize {
    height: 50px;
}
.textarea__label {
    margin: 0 0 12px 0;
    color: #000;
    font-size: 14px;
}

.textarea__label_accent {
    font-weight: bold;
}