.educationPeriodStatistic {
    background-color: #fff;
    font-family: Exo-2, sans-serif;

    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 1250px;

    &__header {
        padding: 8px 44px 0 24px;
        border-bottom: 1px solid #ECEAE9;
        display: flex;
        justify-content: space-between;

    }

    &__header_buttons {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &__content {
        width: 100%;
        height: 100%;

        display: grid;
        grid-template-columns: 390px auto;
    }

    &__notVoted {
        padding: 19.5px 0 0 0;
    }

    &__notVoted {
        padding-left: 30px;
        border-right: 1px solid #ECEAE9;
    }

    &__voted {

    }
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 795px;
}