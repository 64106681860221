.buildingEdit {
  position: relative;

  width: 80%;
  margin-top: 18px;
}

.buildingEdit__title {
  font-family: 'Exo-2';
  font-weight: 600;
  font-size: 16px;
}

