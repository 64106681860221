.datePickerWithTime {
    display: flex;

    gap: 8px;
}

.datePickerWithTime__dateWithTime {
    display: flex;
    gap: 7px;
}

.datePickerWithTime__separator {
    display: flex;
    align-items: center;
    justify-content: center;
}
