.subspaceChooser {
    width: 100%;
    margin-bottom: 64px;
    &:last-child {
        margin-bottom: 0;
    }
}

.subspaceChooser__name {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.subspaceChooser__name_noSelect {
    margin-bottom: 0;
}

.subspaceChooser__colorSign {
    height: 16px;
    width: 16px;
    border-radius: 4px;
    margin-right: 4px;
}

.subspaceChooser__text {
    font-family: 'Exo-2';
    font-weight: 600;
    font-size: 16px;
}

.subspaceChooser__autosuggest {
    width:100%;
}

.subspaceChooser__description {
    width: 100%;
    background: #FFF;
    box-shadow: var(--default-shadow);
    border-radius: 10px;
    padding: 4px 12px;
    font-size: 14px;
    margin-bottom: 16px;
    position: relative;
    &:last-child {
        margin-bottom: 0;
    }
}

.subspaceChooser__description_noData {
    min-height: 48px;
    display: flex;
    align-items: center;
}