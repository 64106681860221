.row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // align-items: center;
  margin: 0 0 30px;
  column-gap: 20px;
}

.row > div > input {
  margin-right: 10px;
}

.row > div > button {
  margin-top: 10px;
}

.error {
  color: red;
}

.filters__conflictTypes {
  width: 700px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-right: 40px;
  margin-bottom: 20px;
}

.filters__itemName {
  // width: 100%;
}
.filters__select {
  box-shadow: var(--default-shadow);
  border-radius: 15px;
}
.filters__checkbox {
  display: flex;
  align-items: center;
}

.filters__checkbox > div {
  margin-left: 10px;
}