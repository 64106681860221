.tabs {
    padding: 20px 0;

    border-bottom: 1px solid #ECEAE9;

    position: sticky;
    top: 61px;
    background: white;
    z-index: 2;
}

.tabs__list {
    display: flex;
    background: #F8F8F8;
    border: 1px solid #C2C2C2;
    border-radius: 7px;
    width: max-content;
}

.tabs__tab {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    padding: 5px;

    white-space: nowrap;

    font-family: 'Exo-2';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */

    letter-spacing: -0.03em;

    &:last-child {
        margin-left: -1px;
        margin-right: 0;
    }

    &:first-child {
        margin-left: 0;
    }
}

.tabs__tab_selected {
    cursor: default;

    padding: 5px;
    margin-left: 0;

    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;

    letter-spacing: -0.03em;

    color: #FFFFFF;
}

.tabs__item_active {
    background: #0B42DC;
    border-radius: 6px;
}

.tabs__tab_point {
    position: absolute;
    right: -12px;
    top: 43%;

    >svg {
        display: block;
    }
}

@media screen and (max-width: 786px) {
    .tabs {
        position: sticky;
        top: 67px;
        background: white;
        z-index: 2;
    }

    .tabs__list {
        width: 100%;
        justify-content: space-between;
    }

    .tabs__tab {
        padding: 5px 11px;

    }

    .tabs__item {
        width: 100%;
    }
}

@media screen and (max-width: 380px) {

    .tabs__tab {
        padding: 5px;

    }
}