@import "../../../../../../scheduleStyles/filterColors";

.eventWrapper {
    position: relative;
    cursor: pointer;
}

.event {
    display: flex;
    align-items: center;
    width: 100%;
    height: 22px;
    margin-top: 5px;
    padding-left: 6px;
    position: relative;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
    transition: all 0.2s;
    &::before {
        content: '';
        width: 4px;
        height: 100%;
        position: absolute;
        left: 0;
        border-radius: 5px;
    }

    &::after {
        content: "";
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50%;
        height: 100%;
        pointer-events: none;
        background: linear-gradient(
            90deg,
            transparent,
            #FFF 100%,
        );
    }
    &:hover {
        overflow: inherit;
        width: max-content;
        border-bottom: 1px solid #0B42DC;
        z-index: 2;
        padding-right: 4px;
        background-color: #FFF;
    }
}

.event:hover::after {
    background: inherit;
    z-index: -1;
}

.event_moreEvents {
    min-height: 22px;
    height: max-content;
    max-height: 60px;
    overflow: inherit;
    white-space: normal;
    align-items: flex-start;
    text-align: left;
    &::after {
        bottom: 0;
        left: 0;
        width: 100%;
        height: 15%;
        background:linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 75%);
    }
    &:hover {
        border-bottom: none;
        padding-right: 0;
        width: 100%;
    }
}

.event_moreEvents:hover::after {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 75%);
    z-index: 3;
}

.event_selected::after {
    content: '';
    width: 100%;
    height: 100%;
    padding: 3px 0;
    left: -4px;
    top: -4px;
    position: absolute;
    border: 1px solid #0B42DC;
    border-radius: 5px;
}

.event_filter_meeting {
    &::before {
        background: $color_filter_meeting;
    }
}

.event_filter_evaluationPoint {
    &::before {
        background: $color_filter_evaluationPoint;
    }
}

.event_filter_assignment {
    &::before {
        background: $color_filter_assignment;
    }
}

@media print {
    .event__decorationOpacity {
        display: none;
    }
}
