.equipmentTable__header {
    display: flex;
    align-items: center;

    border: 1px solid #000;
}

.equipmentTable__header > div {
    padding-top: 16px;
    padding-bottom: 14px;
}

.equipmentTable__headerName {
    width: 448px;

    padding-left: 66px;

    border-right: 1px solid #000;
}

.equipmentTable__headerUnit {
    width: 155px;

    text-align: center;

    border-right: 1px solid #000;
}

.equipmentTable__headerCount {
    width: 181px;

    text-align: center;
}

.equipmentTable__list {
    border: 1px solid #000;
    border-top: none;
}

.equipmentTable__item {
    border-bottom: 1px solid #C4C4C4;

    &:last-child {
        border-bottom: none;
    }
}