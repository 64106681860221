.tableModule {
    background: #FFFFFF;
    box-shadow: var(--default-shadow);
    border-radius: 10px;
    padding: 32px 24px;
    margin-bottom: 20px;
}

.tableModule__container {
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 200px 1fr;
}

.tableModule__name {
    font-family: 'Exo-2';
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 20px;
}

.tableModule__weekChooser {
    margin-bottom: 20px;
}

.tableModule__title {
    margin-bottom: 5px;
    align-self: center;
    padding-left: 200px;
}

.tableModule__waves {
    display: flex;
    flex-direction: column;
    max-width: max-content;
}

.tableModule__row {
    display: flex;
    flex-direction: row;

    max-width: max-content;
}

.tableModule__item {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 25px;
    height: 25px;

    border-top: 1px solid #C4C4C4;
    border-right: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;

    font-family: 'Exo-2';
}

.tableModule__colomn {
    display: flex;
    flex-direction: column;
    max-width: 200px;
}

.tableModule__colomnItem {
    position: relative;

    height: 25px;

    border: 1px solid #C4C4C4;
    border-top: none;

    &:first-child {
        border-top: 1px solid #C4C4C4;
    }

    &:hover {
        .tableModule__colomnText{
            text-overflow: inherit;
            max-width: max-content;
            font-size: 15px;
            text-shadow: #494743 1px 0 1px 0;
            padding: 0 3px;
            z-index: 1;
        }
    }
}

.tableModule__colomnText {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 190px;
    transition: all 0.1s;
    position: absolute;
    top: 3px;
    left: 5px;
    background-color: #fff;
    font-family: 'Exo-2';
    font-size: 14px;
    cursor: default;
}