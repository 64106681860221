.item {
    padding-left: 30px;

    &:hover {
        background-color: #DBE1F0
    }

    &__checkbox {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 9px 18px 11px 0;
        width: calc(100% - 30px);

        cursor: pointer;

        border-bottom: 1px solid #ECEAE9;
    }

    &__title {
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
    }

    &__rating {
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
    }
}

.item_wrapper {
}

.item_wrapper:hover .item_active {
    display: block;
}

.item_wrapper:hover .item_static {
    display: none;
}

.item_active {
    display: none;

    &:hover {
        display: block;
    }
}

.item_static .item__checkbox {
    width: calc(100% - 7px);
}

.item_static {
    display: block;
    padding-left: 53px;
}

.item_static:hover ~ .item_active {
    display: block;
}

.item_selected {
    background-color: #DBE1F0
}

.item_fromModule {
    background-color: #F3F3F3;
}

.item_wasMoved {
    background-color: #F3F3F3;
}