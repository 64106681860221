.buildingForm {
  width: 100%;

}

.addRemoveButton {
  margin-top: 20px;
}

.buildingEdit__buttonsBlock {
  display: flex;
  align-items: center;
  gap: 12px;
  // justify-content: space-between;

  // width: 350px;
  // min-width: 320px;
  // margin-top: 32px;
  // margin-left: 30%;
  margin: 12px 0;
}
