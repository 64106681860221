.timePastLine {
    position: absolute;

    width: 100%;
    height: 1px;

    background: #E05157;

    &::before {
        position: absolute;
        top: -3px;
        left: -4px;

        width: 7px;
        height: 7px;

        content: '';

        border-radius: 50%;
        background: #E05157;
    }
}

@media print {
    .timePastLine {
        display: none;
    }
}
