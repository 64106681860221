.moduleSkillsBlock__text {
  font-weight: 700;
  margin-bottom: 4px;
}

.moduleSkillsBlock__item {
  padding: 4px 12px;
  margin-bottom: 8px;
  background-color: var(--mock-color);

  &:last-child {
    margin-bottom: 0;
  }
}

.moduleSkillsBlock__item_list {
  margin-bottom: 16px;
}
