.moduleMainCharactersParams {
    display: flex;
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 1;

    &__list {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    &__listItems {
        display: flex;
        gap: 24px;
    }
}

.moduleMainCharactersParams__numberItem {
    display: flex;
    justify-content: flex-end;
    font-weight: 700;
    min-width: 70px;
}