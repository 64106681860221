.teacherAssignment {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.teacherAssignment__title {
    font-size: 26px;
    font-weight: 700;
    font-family: "Exo-2";
    margin: 0;
}

.teacherAssignment__subtitle {
    font-size: 22px;
    font-weight: 600;
    font-family: "Exo-2";
    margin: 0;
}

.teacherAssignment__textGradient {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 600;
    background-clip: text;
    background-image: var(--main-gradient);
    -webkit-text-fill-color: transparent;
}

.teacherAssignment__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
}

.teacherAssignment__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.teacherAssignment__sidebar {
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex-shrink: 0;
}

.teacherAssignment__comments {
    display: flex;
    flex-direction: column;
    gap: 18px;
    background-color: #fff;
    padding: 12px 24px;
    border-radius: 15px;
    box-shadow: var(--default-shadow);
}

.teacherAssignment__comments svg {
    padding: 0;
}

.teacherAssignment__button {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    font-weight: 600;
    background-clip: text;
    background-image: var(--main-gradient);
    -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 768px) {
    .teacherAssignment__wrapper {
        // display: flex;
        flex-direction: column;
    }

    .teacherAssignment__subtitle {
        font-size: 18px;
    }
}
