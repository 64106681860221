.Search {
    max-width: 350px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    z-index: 3;
}

.Search__title {
    font-size: 16px;
    font-family: "Exo-2";
    font-weight: 600;
    margin: 0;
}

.Search__input {
    width: 100%;
    max-width: 336px;
    height: 36px;
    padding: 0 18px;
    border-radius: 10px;
    background: #fff;
    box-shadow: var(--default-shadow);
    outline: none;
    border: none;

    &:placehoder {
        color: #bbb;
        font-size: 14px;
    }

    &:checked ~ .Search__title {
        color: red;
    }
}

@media screen and (max-width: 768px) {
    .Search__input {
        max-width: 100%;
    }
}
