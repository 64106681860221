.moreEvents {
    overflow: hidden;
    width: 200px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    // padding: 8px 16px 10px 16px;
    background: #FFF;
    border-radius: 15px;
    box-shadow: var(--default-shadow);
    text-align: center;
    user-select: none;
    max-height: 200px;
}

.button {
    position: absolute;
    left: 16px;
    bottom: 0;
    font-size: 14px;
    color: #585858;
    cursor: pointer;
    user-select: none;
}

.menu {
    overflow-y: auto;
    max-height: 200px;
    padding: 8px 16px 10px 10px;
    box-sizing: border-box;
    &::-webkit-scrollbar-track {
        border: 1px solid #F6F6F6;
        border-radius: 10px 30px 10px 10px;
    }
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(196, 196, 196, 0.2);
        border-radius: 6px;
    }
}

.menu_isBottom {
    top: initial;
    bottom: 0;
}

.menu_isRight {
    left: initial;
    right: 0;
}

.menu__closeButton {
    position: absolute;
    right: 7px;
    top: 6px;
    width: 13px;
    height: 13px;
    cursor: pointer;
}

.menu__date {
    text-transform: capitalize;
    font-size: 16px;
    color: #000;
}

.menu__events {
    margin-top: 11px;
}

.eventLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 68px;
    border-bottom: 1px solid #F6F6F6;
    overflow: hidden;

    &:last-of-type {
        border-bottom: none;
    }
}

.eventLine__startTime {
    align-items: center;
    width: 36px;
    min-width: 36px;
    max-width: 36px;
    font-size: 14px;
    color: #000;
}

.event {
    width: 125px;
    margin-top: -5px;
}
