.formEquip_container {
	display: flex;
	flex-direction: column;

    margin-bottom: 24px;
    max-width: 600px;
}

.formEquip_header {
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 0 16px 0;
    padding-right: 40px;
}

.formEquip_list {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
}

.formEquip_listBlock {
    width: 100%;
	display: flex;
	align-items: center;
    justify-content: space-between;
}

.formEquip_nameGroup {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    box-shadow: var(--default-shadow);
    background: #FFF;
    border-radius: 15px;
}

.formEquip_btn {
    padding: 0 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formEquip_img {
    display: block;
    align-items: center;

    width: 22px;
    height: 22px;
}

.formEquip_subtitle {
    font-size: 16px;
}

.formEquip_text {
    font-size: 14px;
}

.roomPage_form {
    display: flex;
}