.education-period {
    background-color: #fff;
    position: relative;
    min-width: 1250px;

    &__header {
        position: sticky;
        top: 0;
        z-index: 101;

        background-color: #fff;

        border-bottom: 1px solid #ECEAE9;
        padding: 8px 0 0 24px;
    }

    &__content {
        padding: 0 24px 20px 24px;
        height: 100%;
        position: relative;
    }

    &__settings {
        position: sticky;
        top: 72px;
        z-index: 98;

        background-color: #fff;

        display: flex;
        gap: 20px;

        padding: 20px 0;

        &__btn {
            color: #0B42DC;
        }
    }
}