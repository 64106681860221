.evaluationStudent__container{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 0;
    cursor: pointer;
}

.evaluationStudent{
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    margin: 0 20px;
}

.evaluationStudent__data{
    display: flex;
    align-items: center;
    flex: 0 1 85%;
}

.evaluationStudent__avatar{
    margin-right: 14px;
    position: relative;
}

.avatarImage {
    display: block;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 10px;
}

.evaluationStudent__dot {
    position: absolute;
    top: -2px;
    right: -6px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgb(250, 0, 0);
}

.arrow {
    width: 18px;
    height: 14px;
    cursor: pointer;
    transition: all 0.1s ease-in;
    fill: url('#arrow-block-up-linear');
}

.arrow_isOpen {
    transform: rotate(180deg);
    fill: url('#arrow-block-up-linear');
    transition: all 0.1s ease-in;
}

// @media screen and (max-width: 768px) {
//     .arrow {
//         fill: url('#arrow-block-up-linear');
//     }
// }

@media screen and (max-width: 768px) {
    .evaluationStudent__container{
        font-size: 14px;
    }
}