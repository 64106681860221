.newModule__buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 876px;
}

.newModule__buttons_margin_right {
  margin-right: 24px;
}

.newModule__buttonContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.newModule__buttonTitle {
  font-size: 18px;
  padding-left: 5px;
}

.newModule__errors {
  width: 90%;

  display: flex;
  flex-direction: column;
  gap: .5rem;
  //align-items: center;

  margin-bottom: 20px;
}

.newModule__error {
  color: red;

  &_title {
    font-size: 1.125rem;
    //padding-left: .625rem;
    font-weight: bold;
  }

}

.newModule {
  margin-bottom: 80px;
}

.newModule__upButton {
  position: sticky;
  bottom: 100px;
  margin-left: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.newModule__upButtonIcon {
  width: 30px;
  height: 30px;
}

.newModule__mode {
  max-width: 200px;
}
