.alert {
    position: absolute;
    z-index: 2;
    top:26px;
    left:1px;

    display: flex;
    align-items:center;
    flex-direction: row;
    justify-content: center;

    width:384px;
    min-height:80px;

    background-color: white;
    box-shadow: var(--default-shadow);

}

.alert__message {
    width: 180px;
    margin-top:10px;
    margin-left:15px;
    word-wrap: break-word;
    font-weight: 500;
}
