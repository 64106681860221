.spaceInfo {
    display: flex;
    flex-direction: column;
    padding: 40px 50px;
    border-radius: 15px;
    background: #fff;
    box-shadow: var(--default-shadow);
}

.spaceInfo__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.spaceInfo__editButton {
    align-self: flex-end;
}

.spaceInfo__spaceTitle {
    display: flex;
    align-self: flex-start;
    flex-direction: row;
    font-size: 24px;
}

.spaceInfo__spaceName {
    margin-right: 12px;
    font-weight: bold;
}

.spaceInfo__subspaceConnections {
    font-weight: normal;
    margin-left: 12px;
}

.subspaceConnections_disable {
    color: #ccc;
    pointer-events: none;
}

.spaceInfo__spacecharacteristics {
    align-self: flex-start;
    margin-top: 25px;
    font-size: 18px;
}

.spaceInfo__switcher{
    display: flex;
    align-items: center;
    margin-top:23px;
}

.spaceInfo__switcherDiagramIcon_marginRight {
    margin-right: 4px;
}

.spaceInfo__switcherDiagramIcon_marginLeft {
    margin-left: 4px;
}

.spaceInfo__editIcon {
    width: 33px;
    height: 33px;
}

.spaceInfo__info {
    margin-top: 24px;
}

.educationSpaceDialog {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .4);
}

.educationSpaceDialog__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 520px;
    padding: 30px 61px 50px;
    border: 1px solid;
    background-color: #fff;
}

.educationSpaceDialog__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 18px;
}

.educationSpaceDialog__icon {
    position: absolute;
    cursor: pointer;
    height: 25px;
    width: 25px;
    top: 10px;
    right: 10px;
}

.educationSpaceDialog__button {
    margin-top: 50px;
}

.subspaceTypeSelector__label {
    font-size: 18px;
    margin-bottom: 5px;
}

.subspaceTypeSelector__select {
    width: 360px;
    height: 30px;
    margin-right: 7px;
    outline: none;
}

option[data-default] {
    display: none;
}

.subspaceTypeSelector__icon {
    height: 25px;
    width: 25px;
    cursor: pointer;
    transform: translate(0, 25%);
}

.subspaceOptions {
    margin-top: 10px;
}

.subspaceOptions__typeContainer {
    position: relative;
    width: 394px;
    margin-bottom: 30px;
    background: var(--mock-color);
}

.subspaceOptions__typeText {
    font-size: 18px;
    font-weight: 700;
    padding: 20px 20px 20px 5px;
}

.subspaceOptions__typeIcon {
    width: 25px;
    height: 25px;
    position: absolute;
    cursor: pointer;
    fill: #ccc;
    top: 50%;
    right: -12.5px;
    transform: translate(0, -50%);
}

.studentPicking {
    margin-top: 20px;
    margin-bottom: 34px;
}

.studentPicking__label {
    font-size: 18px;
    margin-bottom: 5px;
}

.studentPicking__select {
    width: 360px;
    height: 30px;
    outline: none;
}

.subspaceSelect {
    margin-top: 5px;
}

.subspaceSelect__label {
    font-size: 18px;
    margin-bottom: 5px;
}

.subspaceSelect__select {
    width: 360px;
    height: 30px;
    outline: none;
}
