.item {
    padding-left: 60px;

    font-size: 13px;
    line-height: 16px;
}

.item:hover {
    background-color: #F3F3F3;
    //margin-top: -1px;

    &:first-child {
        //margin-top: 0;
    }
}

.item:hover .item__active {
    //padding: 10px 11px 11px 0;
    //
    //border: none;
}

.item_empty:hover {
    //margin-top: -1px;
}

.item_empty:hover .item__static_block {
    //border: none;
    //padding: 10px 11px 11px 0;
}

.item_selected {
    background-color: #F3F3F3;

    //margin-top: -1px;

    & .item__active {
        //border: none;
        //
        //padding: 10px 11px 11px 0;
        display: block;
    }
}

.item_wasMoved {
    background-color: #F3F3F3;
}

.item_background {
    background-color: #F3F3F3;
}

.item_borderTop {
    border-top: 1px solid #ECEAE9;
}

.item__static {
    display: flex;
    justify-content: space-between;

    border-bottom: 1px solid #ECEAE9;

    padding: 9px 11px 10px 0;

    &_block {
        display: block;
        padding: 9px 11px 10px 0;

        border-bottom: 1px solid #ECEAE9
    }

    &_red {
        color: #C11E1E;
    }
}

.item__active {
    display: none;
    border-bottom: 1px solid #ECEAE9;

    padding: 9px 11px 10px 0;

    &_selected {
        display: block;
    }
}

.item_fromModule {
    background-color: #F3F3F3;
}

.item_notFromModule {
    background-color: #DBE1F0;

    &:hover {
        background-color: #DBE1F0;
    }
}

.item_disabled {
    pointer-events: none;
    background-color: transparent;
}

.item:hover > .item__active {
    display: block;
}

.item:hover > .item__static {
    display: none;
}

.item__name {
    font-size: 13px;
    opacity: .6;

    &_red {
        color: #C11E1E;
        opacity: 1;
    }
}

.item__rating {
    font-size: 13px;
}

.item__number {

}

.item__label {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 24px);
}

.displayBlock {
    display: block;
}

.noOpacity {
    opacity: 1;
}

.interval {
    position: relative;
    z-index: 2;

    display: flex;
    align-items: center;

    height: 0;
    font-size: 11px;
    color: #0B42DC;
    padding-left: 6px;

    &__line {

        display: none;
        height: 1px;
        width: 100%;
        background-color: #0B42DC;

        &_red {
            background-color: #C11E1E;
        }
    }

    &__txt {
        position: relative;

        display: flex;

        width: 55px;
        margin-right: 1px;

        cursor: default;
    }
}

.interval__txt:hover ~ .interval__additional {
    z-index: 3;
    //left: 50%;
    top: 8px;
    left: 30px;

    background-color: #fff;

    border-radius: 6px;
    border: 1px solid #F3F3F3;

    color: #000;
}

.interval__additional:hover {
    z-index: 3;
    //left: 50%;
    top: 8px;
    left: 30px;

    background-color: #fff;

    border-radius: 6px;
    border: 1px solid #F3F3F3;

    color: #000;
}


.interval_red {
    color: #C11E1E;
}

.item__static_disabled {
    display: flex;
    justify-content: space-between;

    padding: 9px 11px 10px 0;

    border-bottom: 1px solid #ECEAE9}

.item_borderRed {
    border-bottom: 1px solid #C11E1E;
}

.item_borderBlue {
    border-bottom: 1px solid #0B42DC;
}