.cell {
    position: relative;
    
    display: flex;
    align-items: center;
    justify-content: center;

    width: 29px;
    height: 38px;

    //border-right: 1px solid #ECEAE9;
    border-bottom: 1px solid #ECEAE9;

    cursor: default;

    &:hover {
        margin-top: -1px;

        height: 39px;

        border: 1px solid #0B42DC;
    }
}

.cell_firstRow:hover {
    margin-top: 0;
    height: 38px;
    padding-bottom: 1px;
}

.cell_noData:hover {
    border: 1px solid #161719;
}

.cell_empty {
    background-image: url("../EmptyTableRowCell/dashCover.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.cell_empty:hover {
    margin-top: 0;

    height: 38px;
    border: none;
    border-bottom: 1px solid #ECEAE9;
}

.cell__additional {
    position: absolute;
    top: 50%;
    left: 80%;

    display: block;

    z-index: 2;

    pointer-events: none;

    & > div {
        color: #000;
        background-color: #fff;
        border: 1px solid #F3F3F3;
    }

    & .meetingInfo {
        width: max-content;
    }
}

.cell__additional_positionRight {
    right: 20px;
    left: unset;
}

.cell__additional:hover {
    z-index: 2;
    width: max-content;

    display: block;

    & > div {
        color: #000;
        background-color: #fff;
        border: 1px solid #F3F3F3;
    }

    & .meetingInfo {
        width: max-content;
    }
}

.cell__additional_top {
    top: -100px;
}

.cell__additional_missingTop {
    top: -100%;
}

.cell__additional_info {
    //max-width: 187px;
    padding: 10px;

    border: 1px solid #F4F4F4;
    border-radius: 6px;

    background-color: #fff;

    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    font-family: Exo-2, sans-serif;
}

.cell__attendance {
    font-size: 14px;
    line-height: 17px;
}

.cellRow:hover .cell:not(.cell_notValid, .cell_valid) {
    background-color: #F8F9FE;
}

.cell_valid {
    background-color: #F3FDFD;
}

.cell_notValid {
    background-color: #DBE1F0;
}

.meetingInfo {
    width: 0;
}

.borderBottom_none {
    border-bottom: none;
}

