.zonesAvailableIntervals {
    display: flex;
    align-items: center;
    gap: 15px;
}

.zonesAvailableIntervals__button {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0;
}