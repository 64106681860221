.accordion {
    width: 390px;
    flex-shrink: 0;

    &_closed {
        width: 130px;
    }

    &__sort {
        font-weight: 500;

        display: flex;
        align-items: center;
        gap: 10px;

        &_btn {
            padding: 0;
        }
    }

    &__item {
        font-size: 13px;
        font-weight: 500;

        padding: 10px 18px 10px 30px;
        margin-left: 25px;

        border-top: 1px solid #ECEAE9;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__items {
        height: calc(100vh - 218px);
        overflow-y: auto;

        display: flex;
        flex-direction: column;
        gap: 50px;

        &::-webkit-scrollbar {
            width: 15px;
        }

        &::-webkit-scrollbar-track {

            background-color: #FAFAFA;
            padding-bottom: 1px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #E3E3E3;

            border-radius: 10px;
            border: 3.5px solid #FAFAFA;
        }

        &_disabled {
            opacity: .5;
            pointer-events: none;
        }
    }
}

.accordion__header {
    position: relative;

    padding: 20px 0 4px 0;

    font-size: 17px;
    line-height: 20px;
    font-weight: 600;

    display: flex;
    flex-direction: column;
    gap: 1px;

    &_main {
        display: flex;
        align-items: center;
        padding-left: 25px;

        cursor: pointer;
    }

    &:hover > .accordion__header_additional {
        z-index: 20;
        top: 80%;
        left: 30%;
        color: #000;

        background-color: #fff;
        border: 1px solid #ECEAE9;

        transition: all 500ms ease-in-out;
    }
}

.accordion__header_red {
    color: #C11E1E;
}

.accordion__header_wavesInfo {
    color: #6D6E6F;
    font-size: 11px;
    line-height: 15px;
}

.accordion__header_ellipsis {
    width: 330px;
    display: block;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &_closed {
        width: 80px;
    }
}

.accordion__header_additional {
    position: absolute;
    z-index: -10;


    padding: 9px 12px 11px 12px;
    max-width: 232px;
    width: max-content;

    color: transparent;
    font-size: 11px;
    line-height: 17px;
    font-family: Exo-2, sans-serif;

    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 6px;
}
