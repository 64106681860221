.form {
  box-sizing: border-box;
  max-width: 876px;
  padding: 12px 89px 45px 181px;
  border: 1px solid #000;
  box-sizing: border-box;
  margin: 20px 0;
}

.form__saved {
  margin: 20px 0 20px 180px;
  width: 516px;
}

.form_meeting {
  box-sizing: border-box;
  min-width: 876px;
  max-width: 876px;
  margin-bottom: 50px;
  padding: 20px 90px 51px 180px;

  border: 1px solid #000;
}

.form__inputs {
  display: flex;
  align-items: center;

  min-width: 606px;
  margin-bottom: 55px;
}

.form__equipments {
  margin-bottom: 55px;
}

.form__skills {
  margin-bottom: 55px;
}

.form__professors {
  margin-bottom: 55px;
}

.form__line {
  width: 606px;
  height: 1px;
  margin: 30px 0;

  background-color: #000;
}

.form__buttons {
  display: flex;
  justify-content: flex-end;

  margin-top: 39px;
}

.form__buttons_margin_right {
  margin-right: 24px;
}

.form__container {
  margin-bottom: 50px;
}

.form__theme {
  width: 606px;
  margin-bottom: 20px;
}

.form__warning {
  width: max-content;
  padding: 2px 5px;

  color: #FF0000;
  border: 1px solid #FF0000;
  border-radius: 3px;
  font-size: 12px;
}

.form__span {
  margin-right: 39px;

  &:last-child {
    margin-right: 0;
  }
}

.form__error {
  margin-top: 24px;
  color: red;
}