.notDistributed {
    width: 390px;

    &__accordionHeader {
        display: flex;
        align-items: center;
        padding: 20px 0;
        margin-left: 25px;

        cursor: pointer;
    }

    &__header {
        font-size: 17px;
        line-height: 20px;
        font-weight: 600;
        color: #C11E1E;

        &_valid {
            color: #000;
        }
    }

    &__items {

    }

    &__item {
        padding: 10px 18px 10px 30px;
        margin-left: 25px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        border-top: 1px solid #ECEAE9;

        &_title {
            font-size: 13px;
            line-height: 16px;
            font-weight: 500;
            color: #C11E1E;
        }

        &_valid {
            color: #0B42DC;
        }

        &_cancel {
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;

            padding: 0 6px;

            color: #0B42DC;
            cursor: pointer;
        }

    }

    &__scroll {
        max-height: calc(100vh - 244px);
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 15px;
        }

        &::-webkit-scrollbar-track {

            background-color: #FAFAFA;
            padding-bottom: 1px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #E3E3E3;

            border-radius: 10px;
            border: 3.5px solid #FAFAFA;
        }

    }
}

.notDistributed__item_additional {
    display: flex;
    gap: 5px;
    align-items: center;
}

.notDistributed__sort {
    display: flex;
    gap: 3px;
    align-items: center;
}

.notDistributed__sort_txt {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: #6D6E6F;
}

.notDistributed__sort_btn:hover > svg > rect {
    fill: #F3F6FD;
}

.notDistributed__sort_btn:hover > svg > path {
    fill: #161719;
}

.notDistributed__sort_btn:active > svg > path {
    fill: #0B42DC;
}

.notDistributed__sort_btn {
    padding: 0;
    display: flex;
    align-items: center;
}