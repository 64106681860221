.checkbox {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6.75px;

    &__checkmark {
        position: relative;
        width: 16px;
        height: 16px;
        background-color: #818181;
        border-radius: 4px;

        &_inside {
            position: absolute;
            top: 1.5px;
            left: 1.5px;

            background-color: #fff;
            width: 13px;
            height: 13px;
            border-radius: 3px;
        }
    }

    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkbox__checkmark > .checkbox__checkmark_inside {
            display: none;
        }

        &:checked ~ .checkbox__checkmark {
            background-color: #0B5FFF;
        }

        &:checked ~ .checkbox__checkmark:after {
            content: "";
            display: block;
            position: absolute;
            left: 8.5px;
            top: 4px;

            height: 8px;
            width: 2px;
            background-color: #fff;

            border-radius: 1px;

            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        &:checked ~ .checkbox__checkmark:before {
            content: "";
            display: block;
            position: absolute;
            left: 4.5px;
            top: 6px;

            height: 6px;
            width: 2px;
            background-color: #fff;

            border-radius: 1px;

            -webkit-transform: rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: rotate(135deg);
        }
    }

    &__label {

    }
}