.conventions {
    width: 100%;

    display: flex;
    gap: 25px;
    justify-content: flex-end;
    align-items: center;
}

.conventions__default_text, .conventions__missing_text, .conventions__notValid_text, .conventions__valid_text {
    font-size: 11px;
    font-weight: 400;
    font-family: Exo-2, sans-serif;
    line-height: 14px;
}

.conventions__default, .conventions__notValid, .conventions__missing, .conventions__valid {
    display: flex;
    gap: 5px;
    align-items: center;
}

.conventions__valid_symbol, .conventions__missing_symbol, .conventions__notValid_symbol, .conventions__default_symbol {
    //height: 15px;
    font-size: 11px;
    font-weight: 600;
    font-family: Exo-2, sans-serif;
    line-height: 14px;
}

.conventions__default_symbol {
    width: 13px;
    height: 15px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid #EEEEEE;
}

.conventions__default {
}

.conventions__valid {

}

.conventions__valid_symbol {
    width: 15px;
    height: 15px;

    font-size: 10px;
    text-align: center;
    line-height: 15px;
    font-weight: 400;

    background-color: rgba(60, 173, 180, .2);
}

.conventions__notValid {

}

.conventions__notValid_symbol {
    width: 13px;
    height: 15px;

    font-size: 10px;
    text-align: center;
    line-height: 13px;
    font-weight: 600;

    background-color: #DBE1F0;
}

.conventions__missing {

}

.conventions__missing_symbol {
    height: 15px;
}
