.meetingEdit {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.suggestion {
  display: flex;
  flex-direction: row;
  min-width: 460px;
}

.suggestion__input {
  width: 360px;
}

.suggestion__list {
  border-radius: 10px;
  background: var(--extra-gradient);
  color: #000;
  padding: 10px;
  margin: 10px 30px 0 0;
}

.meetingEdit__check {
  width: 18px;
  height: 18px;
  position: relative;
  top: 5px;
  left: 5px;
  cursor: pointer;
}