.module-assessment-tab__title {
    margin-bottom: 20px;

    font-family: 'Exo-2';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #C4C4C4;
    text-transform: uppercase;

    user-select: none;
}

.module-assessment-tab__title_finished {
    font-family: 'Exo-2';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #C4C4C4;
    text-transform: uppercase;

    user-select: none;
}

.module-assessment-tab__errors-container {
    margin-bottom: 40px;
}

.module-assessment-tab__error {
    font-family: "Manrope";
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;

    &:last-of-type {
        margin-bottom: 0;
    }

    span {
        font-weight: 700;
    }

    .module-assessment-tab__error-message {
        font-weight: 400;
        color:#ff0000;
    }
}

.module-assessment-tab__teacher {
    font-family: 'Exo-2';
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    text-transform: uppercase;
    margin-bottom: 5px;
}

.module-assessment-tab__teacher-meetings {
    width: fit-content;
    margin-bottom: 45px;

    font-family: 'Exo-2';
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;

    text-decoration-line: underline;
    text-transform: uppercase;
    color: #717171;

    cursor: pointer;
    user-select: none;
}

.assessment-table {
    width: fit-content;
    box-shadow: none;

    td {
        height: 44px;
        border: 1px dashed #C4C4C4;
        padding: 0 10px !important;
        font-family: 'Manrope';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }

    thead tr:first-of-type {
        td {
            width: 44px;
            border-top: none;
            font-family: 'Exo-2';
            font-weight: 300;
            font-size: 10px;
            line-height: 120%;
            text-align: center;

            &:first-of-type {
                width: 540px;
                font-family: 'Manrope';
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                text-align: start;
            }

            &:last-of-type {
                border-right: 1px dashed #C4C4C4;
            }
        }
    }

    tbody tr {
        td:last-of-type {
            border-right: 1px dashed #C4C4C4;
        }

        &:last-of-type td {
            border-bottom: none;
        }
    }
}

.assessment-table__row_error td {
    background-color: #ff00000d;
}

.assessment-table__cell {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    cursor: pointer;
    user-select: none;

    width: 33px;
    height: 33px;
    background: #C4C4C4;
    border-radius: 15px;

    font-family: 'Exo-2';
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;

    &:hover {
        background: var(--extra-gradient);
    }

    &_active {
        background: var(--extra-gradient);
    }
}

.module-assessment-tab__open-ended-question {
    margin-bottom: 40px;
}

.assessment-open-ended-question {
    display: flex;
    width: fit-content;

    cursor: pointer;
    user-select: none;
}

.assessment-answer {
    width: 100%;
    max-width: 902px;
    height: 140px;
    margin: 10px 0;
    padding: 12px;
    background: #FFFFFF;

    font-family: 'Manrope';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    resize: none;
    border: none;
    outline: none;

    box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
    border-radius: 15px;

    &::placeholder {
        color: #C4C4C4;
    }

    &_invalid {
        box-shadow: 0px 2px 6px 2px #ff000021;
    }
}

.assessment-save-button {
    width: fit-content;
    font-family: 'Manrope';

    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin-left: auto;

    background: var(--main-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.assessment-open-ended-question__title {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    background: var(--main-gradient);

    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.assessment-question__icon {
    margin-right: 10px;
    width: 16px;
    height: 16px;

    &_active {
        transform: rotate(-45deg);
    }
}

.module-assessment-tab__popup-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1001;
}

.module-assessment-popup {
    position: relative;
    width: fit-content;
    height: fit-content;
    padding: 40px 54px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
    border-radius: 30px;
}

.module-assessment-popup__title {
    font-family: 'Exo-2';
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.02em;
    margin: 0  30px 15px 0;

    > span {
        color: #C4C4C4;
    }
}

.module-assessment-popup__meeting-list {
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
}

.module-assessment-popup__meeting {
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.module-assessment-popup__close-icon {
    position: absolute;
    top: 25px;
    right: 30px;
}

.assessment-poll {
    display: none;
}

.module-assessment-finish-button__error-message {
    font-family: "Manrope";
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ff0000;
    user-select: none;
    margin-bottom: 8px;
}

.module-assessment_mobile {
    display: none;
}

.teacher-assessment_mobile {
    display: none;
}

@media screen and (max-width: 567px) {
    .module-assessment {
        display: none;
    }

    .assessment-table {
        display: none;
    }

    .assessment-poll {
        display: initial;
    }

    .module-assessment_mobile {
        display: initial;
    }

    .teacher-assessment {
        display: none;
    }

    .teacher-assessment_mobile {
        display: initial;
    }

    .module-assessment__accordion {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;
    }

    .module-assessment-tab__title {
        margin-bottom: 0;
    }

    .module-assessment-tab__title_finished {
        margin-bottom: 10px;
    }

    .assessment-closed-type-question__title {
        font-family: 'Manrope';
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .assessment-closed-type-question__placeholder {
        font-family: 'Manrope';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #C4C4C4;
        margin-bottom: 8px;
    }

    .assessment-closed-type-question__option-list {
        margin-bottom: 25px;
        display: flex;
    }

    .assessment-closed-type-question__option {
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        user-select: none;

        width: 33px;
        height: 33px;
        background: #C4C4C4;
        box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
        border-radius: 15px;

        font-family: 'Exo-2';
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;

        &:not(:last-of-type) {
            margin-right: 16px;
        }

        &_active {
            background: var(--extra-gradient);
        }
    }

    .module-assessment-popup {
        padding: 20px;
    }

    .module-assessment-tab__errors-container {
        margin-bottom: 60px;
    }

    .module-assessment-tab__finish-button-container {
        margin: 40px 0 20px;
    }

    .module-assessment-finish-button {
        margin: auto;
    }
}