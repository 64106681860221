.external-module-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 18px;

    &__item {
        display: flex;
        flex-direction: column;
        // align-items: center;
        border: 1px solid #ccc;
        border-radius: 15px;
        background-color: #fff;
    }
}

.external-module__header {
    display: flex;
    align-items: center;
    margin: 12px;
    position: relative;
}

.external-module__status-point {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    margin-right: 4px;

    &_revision {
        background-color: #FF0000;
    }

    &_approved {
        background-color: #18A659;
    }

    &_verification {
        background-color: #fca800;
    }

    &_draft {
        background-color: #0B42DC;
    }
}

.external-module__status {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    top: -5px;
    right: -5px;
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 10px;
    line-height: 14px;

    &_revision {
        color: #FF0000;
    }

    &_approved {
        color: #18A659;
    }

    &_verification {
        color: #fca800;
    }

    &_draft {
        color: #0B42DC;
    }
}

.external-module-header__icon-container {
    margin-right: 12px;
    height: 70px;
    width: 70px;
}

.external-module-header__title {
    height: 54px;
    font-size: 18px;
    font-weight: 500;
    overflow-y: scroll;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.external-module-header__title::-webkit-scrollbar {
    display: none;
}

.external-module__content {
    // max-height: 459px;
}

.external-module-content__tabs {
    margin-bottom: 20px;
    display: flex;
    border-top: 1px solid #ccc;
}

.external-module-content__tab-item {
    cursor: pointer;
    max-width: 33.3%;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 500;

    &_active {
        border-bottom: none;
    }
}

.external-module-content {
    margin: 0 24px;
}

.external-module-content__title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}

.external-module-content__description {
    height: 114px;
    margin-bottom: 14px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    ul {
        margin-block-start: 1em;
        margin-block-end: 1em;
        padding-inline-start: 40px;
    }

    ul li {
        list-style: disc;
    }
}

.external-module-content__tab-stub {
    width: 100%;
    border-bottom: 1px solid #ccc;
}

.external-module-content__features {
    display: flex;
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 1;
}

.external-module-features {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
}

.external-module-feature__value {
    display: flex;
    justify-content: flex-end;
    font-weight: 700;
    margin-bottom: 6px;
    width: 70px;

    &:last-child {
        margin-bottom: 0;
    }
}

.external-module-feature__title {
    margin-bottom: 6px;

    &:last-child {
        margin-bottom: 0;
    }
}

.external-module-owner__title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}

.external-module-owner__card {
    display: flex;
    align-items: center;
    min-height: 55px;
    padding: 8px 12px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
}

.external-module-owner__avatar {
    min-width: 40px;
    min-height: 40px;
    margin-right: 14px;
}

.external-module-owner__full-name {
    font-weight: 700;
    word-break: break-word;
}

.external-module__buttons {
    display: flex;
    justify-content: space-around;
    padding: 18px 18px 0 18px;
}

.external-module__button {
    margin-bottom: 18px;
    width: 150px;
}