.studentInfo__spoiler_title {
  padding-bottom: 15px;
  font-family: 'Exo-2';
  font-weight: 600;
  font-size: 16px;
}

.studentInfo__block {
  padding-bottom: 50px;
}

.studentInfo__title {
  font-family: 'Exo-2';
  margin-bottom: 22px;
  font-weight: bold;
  font-size: 18px;
}

.studentInfo__module_item {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.studentInfo__select_wave {
  display: flex;
  justify-content: right;
}

.studentInfo__select {
  display: flex;
  align-items: center;
  // height: 20px;
  min-width: 90px;
  background: #FFF;
  border-radius: 5px;
  box-shadow: 2px 2px 6px rgba(217, 217, 217, 0.25);
}
