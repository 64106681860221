.modulesList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 18px;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}