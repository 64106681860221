.forkCard {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.4);
}

.forkCard__container {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding: 40px 50px;

  box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
  border-radius: 15px;

  background-color: #fff;
}

.forCard__title {
  text-align: start;
  margin-bottom: 32px;
  font-family: Exo-2;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  color: #000000;
  cursor: default;
}

.forkCard__moduleCount {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  align-self: flex-start;

  cursor: default;
}

.forkCard__subtitle {
  font-family: "Exo-2";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  display: flex;
  align-self: flex-start;
  margin-right: 16px;
  margin-bottom: 8px;

  cursor: default;
}

.forkCard__moduleInput {
  background: #ffffff;
  box-shadow: 2px 2px 6px rgba(217, 217, 217, 0.25);
  border-radius: 10px;
  border: #ffffff;
  min-width: 200px;
  margin-bottom: 15px;
  font-size: 14px;
  color: #000000;
}

.forkCard__moduleInput:focus {
  outline: none;
}

.forkCard__select {
  background: #ffffff;
  box-shadow: 2px 2px 6px rgba(217, 217, 217, 0.25);
  border-radius: 10px;
  border: #ffffff;
  margin-bottom: 15px;
  max-width: 200px;
  color: #000000;
  font-size: 14px;
}

.forkCard__distribution {
  margin-bottom: 31px;
  align-self: flex-start;
}

.dialogFork__distribution_list {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  font-size: 14px;
}

.forkCard__button {
  margin-top: 20px;
  align-self: center;
}

.forkCard__icon {
  position: absolute;

  width: 25px;
  height: 25px;

  top: 20px;
  right: 20px;

  cursor: pointer;
}

.automaticallyChoice {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  align-self: flex-start;

  cursor: default;
}

.forkCard__label {
  font-size: 18px;

  margin-bottom: 2px;
}

/* studentChoice */

.studentChoice {
  margin-top: 32px;
}

.forkCard__marginContainer_24px {
  margin-top: 24px;
}

.forkCard__marginContainer_42px {
  margin-top: 42px;
}

.radio_container {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.radio_title {
  margin-left: 5px;
  cursor: default;
}

label.custom_radio input {
  display: none;
}

label.custom_radio input + div {
  content: "\a";
  width: 20px;
  height: 20px;
}
