@media screen and (max-width: 980px) {
  .menu__profile {
    display: none;
  }
}

.menuProfile {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  margin: 17px 0 24px;
}

.menuProfile_userTypesMode {
  align-items: flex-start;
}

.menuProfile__userType {
  font: 500 12px "Exo-2";
  color: #c4c4c4;
  margin: 0 auto;
  width: 80%;
  text-align: center;
}

.menuProfile__photo {
  display: flex;
  justify-content: center;
  margin: 4px 0 12px;
}

.menuProfile__photo_userTypesMode {
  position: relative;
  flex-direction: row;
  border-radius: 15px;
  padding-left: 65px;
  opacity: 1;
  box-shadow: 0 2px 6px rgba(24, 20, 50, 0.13);
}

.menuProfile__photo_userTypesMode::after {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  opacity: 0;
  padding-left: 0;
  transition: opacity 0.2s ease-in-out;
}

.menuProfile__gradientCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  background: var(--main-gradient);
  border-radius: 50%;
}

.menuProfile_avatarCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffffff;
}

.menuProfile_avatarCircle_cursorPointer {
  cursor: pointer;
}

.menuProfile_icon {
  width: 40px;
  height: 40px;
  fill: url("#hat-linear-gradient");
}

.menuProfile__name {
  cursor: auto;
  margin: 0 auto;
  width: 80%;
  text-align: center;
  font: 700 22px "Exo-2";
  line-height: 26px;
}
