.popup {
    position: fixed;
    top: 0;
    left: 0;

    display: none;
    align-items: flex-start;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.3);
}

.popup_opened {
    z-index: 9999;
    display: flex;
}

.popup__container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 18px 24px;
    border-radius: 18px;
    background: #fff;
    box-shadow: var(--default-shadow);
    // position: relative;
    // display: flex;
    // flex-direction: column;
    // margin-top: 110px;
    // margin-left: -380px;
    // box-sizing: border-box;
    // padding: 30px 45px 10px;
    // background: #FFFFFF;
}

.popup__title {
    margin: 0 0 40px 0;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}

.popup__fieldset {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    min-height: 30px;
    margin: 0 0 48px;
    padding: 32px 24px 24px;

    border: none;
}

.popup__icon {
    height: 24px;
    width: 27px;
    margin-right: 15px;
}
