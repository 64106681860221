.educationSubspaceForm {
    // margin: 24px 0;
    // border-radius: 15px;
    // background: #fff;
    // box-shadow: var(--default-shadow);
}

.educationSpaceEdit_container {
    // margin: 21px 10px 26px 60px;
}

.educationSubspaceFormEdit__crossContainer {
    display: flex;
    justify-content: space-between;
}

.suggest_container {
    padding-top: 6px;
}

.educationSubspaceForm__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 700;
    gap: 12px;
}

.educationSubspaceEdit__formContainer {
    display: flex;
    flex-direction: column;
}

.educationSubspaceForm__label {
    width: 360px;
    display: flex;
    flex-direction: column;
    margin-bottom: 17px;
}

.educationSubspaceForm__input {
    height: 30px;
    margin-top: 5px;
    border: 1px solid black;
}

.educationSubspaceForm__ButtonContainer {
    display: flex;
    align-items: center;
    gap: 12px;
}

.educationSubspaceForm__usersList {
    overflow-y: scroll;
    height: 250px;
}

.hidden {
    display: none;
}

.educationButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.educationButton__bgButton {
    // margin-right: 20px;
}

.educationButton_width_fix {
    // width: 155px;
}
