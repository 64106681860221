.time__penIcon {
  width: 18px;
  height: 18px;
  position: relative;
  top: 3px;
  left: 5px;
  cursor: pointer;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.editingDate {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 20px;
}

.editingDateEnd {
    margin-left: 6px;
}

.error {
    color: #FF0000;
}
