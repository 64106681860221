.accordionItems {

    &__header {
        display: grid;
        grid-template-columns: 314px 103px max-content;

        font-size: 13px;
        font-weight: 400;

        padding: 9px 0 11px 0;

        &_bright {
            font-weight: 600;
        }
    }

    &__title {
        padding-left: 10px;
        line-height: 16px;

        &:first-child {
            padding-left: 0;
        }

    }

    &__items {
        padding-left: 19px;

    }

    &__item {
        position: relative;
        padding-left: 26px;

        display: grid;
        grid-template-columns: 409px max-content;

        font-size: 13px;
        font-weight: 400;

        border-bottom: 1px solid #ECEAE9;

        &:first-child {
            border-top: 1px solid #ECEAE9;
        }

        &:hover {
            background-color: #F8F9FE;
        }

        &:hover > .accordionItems__priorities {
            z-index: 2;

            background-color: #fff;

            border: 1px solid #ECEAE9;

            color: #000;

            transition: z-index 200ms ease-in-out,
            background-color 400ms ease-in-out,
            border 400ms ease-in-out,
            color 500ms ease-in-out;
        }
    }

    &__priorities {
        position: absolute;
        left: 100px;
        top: 30px;
        z-index: -1;

        padding: 8px 15px;

        background-color: #fff;

        border: 1px solid #ECEAE9;
        border-radius: 5px;

        color: transparent;

        &_bold {
            font-weight: 600;
        }
    }

    &__info {
        line-height: 16px;
        padding: 9px 0 11px 0;
    }
}

.header {
    display: flex;
    align-items: center;
}
