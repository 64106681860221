.tabs {
  &__list {
    display: flex;
    gap: 16px;
    margin: 24px 0;
  }

  &__link {
    color: #0d0d0d;
    font-weight: 600;
    text-align: center;
    position: relative;
    border-image-slice: 1;
    background: #0d0d0d;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0 0 4px 0;
    cursor: pointer;

    &::before {
      content: '';
      width: 0;
      height: 2px;
      background-image: var(--main-gradient);
      position: absolute;
      top: 100%;
      left: auto;
      right: 0;
      transition: width .1s ease;
      transition-delay: .1s;
    }

    &_selected {
      position: relative;
      background: var(--main-gradient);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: default;

      &::before {
        content: '';
        width: 100%;
        left: 0;
        right: auto;
        transition-delay: 0s;
      }
    }
  }
}



