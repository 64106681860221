.equipmentPlan {
    position: relative;

    width: 786px;
}

.equipmentPlan__calendar {
    margin-bottom: 15px;
}

.equipmentPlan__list {
    display: flex;
}

.equipmentPlan__item {
    color: #C4C4C4;
    padding: 0 15px;
    border-right: 1px solid #000;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
        border-right: 0;
    }
}

.equipmentPlan__item_color_black {
    color: #000;
    font-weight: bold;
}

.equipmentPlan__tabs {
    margin-bottom: 20px;
}

.equipmentPlan__tabs_disable {
    opacity: .4;
    pointer-events: none;
}
