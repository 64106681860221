.BTIDiagram {
  display: flex;
  flex-direction: column;
  justify-content: center;

  box-sizing: border-box;
}

.BTIDiagram__slots {
  display: flex;
  flex-direction: column;
}

.BTIDiagram__row {
  display: flex;
  flex-direction: row;
}

.BTIDiagram__description {
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  height: 30px;

  color: #C4C4C4;
  border: 1px solid #C4C4C4;
}

.BTIDiagram__order {
  display: inline-block;
}

.BTIDiagram__name {
  display: inline-block;

  margin-left: 4px;
}

.BTIDiagram__creditCount {
  display: inline-block;

  margin-left: 4px;
}

.row {
  display: flex;
  flex-direction: row;
}
