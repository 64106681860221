.subSpaceOptions {
    position: relative;

    width: 786px;
    margin-bottom: 40px;
    padding: 0 66px;

    border: 1px solid black;

    font-size: 18px;
}

.subSpaceOptions__commonParamsList {
    margin-top: 21px;
}

.subSpaceOptions__captivity {
    padding-bottom: 41px;
}

.subSpaceOptions__captivity_bold {
    font-size: 18px;
    font-weight: 700;
}

.subSpaceOptions__captivity_info {
    font-size: 18px;
    margin-left: 16px;
}

.subSpaceOptions__commonParamsItem {
    border-top: 1px solid black;
    border-bottom: 1px solid black;

    &:last-child {
        border-top: none;
        border-bottom: none;
    }
}

.subSpaceOptions__profileParamsTitle {
    padding: 21px;

    border-top: 1px solid black;

    font-weight: 700;
}

.subSpaceOptions__profileParamsList {
    margin-bottom: 45px;
}

.subSpaceOptions__profileParamsItem {
    margin-top: 17px;
}

.subSpaceOptions__button {
    display: flex;
    justify-content: center;

    margin-bottom: 45px;
}

.subSpaceOptions__penIcon {
    position: absolute;
    top: 10px;
    right: 10px;
}

.edit-pen-subspace-options {
    width: 53px;
    height: 53px;

    cursor: pointer;
}
