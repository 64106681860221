.equipmentList {
  position: relative;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: var(--default-shadow);
}

.equipmentList__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.equipmentList__icon {
  cursor: pointer;
}
