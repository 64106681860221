.weekScheduleGrid {
    width: 100%;
    display: flex;
    flex: 1;
    position: relative;
    height: calc(45px * 24);
}

.column {
    position: relative;
    width: calc(100% / 7);
    height: 100%;
    border-right: 1px solid #F6F6F6;
}

.row {
    width: 100%;
    height: calc(100% / 24);
    border-bottom: 1px solid #F6F6F6;
}

@media screen and (max-width: 1200px) {
    .weekScheduleGrid {
        border-top: 1px solid #F6F6F6;
    }
}

@media print {
    .weekScheduleGrid {
        height: calc(29px * 24);
    }

    .row {
        width: 100%;
        height: calc(100% / 24);
        border-bottom: 1px solid #F6F6F6;
    }
}
