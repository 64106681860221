.skillCardContent {
  display: flex;
}

.skillCardContent__letter {
  margin-right: 20px;

  font-size: 24px;
  font-weight: 700;
}

.skillCardContent__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.skillCardContent__container {
  // margin-bottom: 15px;
}

.skillCardContent__titleWrapper {
  display: flex;
  align-items: center;

  word-wrap: anywhere;
}

.skillCardContent__titleWrapper_withDesc:hover {
  cursor: pointer;
}

.skillCardContent__titleWrapper_editHover:hover {
  text-decoration: underline;
}

.skillCardContent__title {
  display: flex;
  align-items: center;

  width: 80%;
  margin-right: 8px;

  font-size: 14px;
  font-weight: 400;
}

.skillCardContent__title_checked {
    font-weight: 700;
}

.skillCardContent__description {
  overflow: hidden;
}

.skillCardContent__description_height_null {
  height: 0;
}

.skillCardContent__descriptionText {
  color: #555;
  padding: 0 0 0 12px;
  margin: 12px 0;
  font-size: 14px;
}
