.schedulePageMobile {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
  width: 100%;
  margin: 0 auto;
}

.schedulePageMobile__schedule {
  width: 100%;
}

.buttonBackWrapper {
  width: 100%;
  padding: 0 0 20px 0;
}

.schedulePageMobile_buttonBack {
  text-align: start;
  background: var(--main-gradient);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 1200px) {
  .schedulePageMobile_arrowLeft {
    width: 10px;
    height: 8px;
    fill: var(--mock-color);
  }
}
