div.confirmContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h3.confirmTitle {
    font-family: 'Exo-2', sans-serif;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 48px;
    text-align: center;
    max-width: 450px;
}

p.simpleMessage, p.confirmMessage {
    line-height: 20px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 33px;
}

p.simpleMessage {
    margin-bottom: 33px;
}

p.confirmMessage {
    margin-bottom: 10px;
}

input.confirmInput {
    background: #FFFFFF;
    box-shadow: 2px 2px 6px rgba(217, 217, 217, 0.25);
    border-radius: 10px;
    padding: 4px 12px;
    outline: none;
    border: none;
    width: 270px;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 33px;
}

input.confirmInput::placeholder {
    color: #c4c4c4;
}

.rejectButton {
    margin-right: 115px;
}
