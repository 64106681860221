.diagramFork {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: auto;
  font-size: 10px;
  z-index: 100;
}

.diagramFork__entity {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 60%;

  cursor: pointer;

  border: 1px solid #000000;
}

.diagramFork__numberOfRelations {
}

.diagramFork__moduleSelectionCount {
}

.diagramFork__question {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  width: 10px;
  border: 1px solid #000000;
  border-radius: 50%;
}

.diagramFork__question_readyToRemove {
  color: #ff0000;
  border-color: #ff0000;
}

.diagramFork__entity_dashed {
  border: 1px dashed #000000;
}

.diagramFork__entity_active {
  border-width: 4px;
}

.diagramFork__entity_withoutPointer {
  cursor: auto;
}

.diagramFork__entity_readyToRemove {
  cursor: pointer;
  color: #ff0000;
  border-width: 2px;
  border-style: solid;
  border-color: #ff0000;
}

.diagramFork__entity_readyToConnect {
  border: 1px solid #c4c4c4;
}

.diagramFork__entity_setPrevious {
  cursor: auto;

  border: 1px solid var(--mock-color);
  background: var(--mock-color);
}

.diagramFork__entity_warning {
  border: 2px dashed #ff0000;
}

.diagramFork .diagramFork__toolTipText {
  position: absolute;
  z-index: 100;
  bottom: 125%;
  left: 50%;

  visibility: hidden;

  width: 120px;
  margin-left: -60px;
  padding: 5px 0;

  transition: opacity 0.3s;
  text-align: center;

  opacity: 0;
  color: #000000;
  border: 2px solid #ff0000;
  border-radius: 6px;
  background-color: #ffffff;
}

.diagramFork .diagramFork__toolTipText::after {
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 50%;

  margin-left: -5px;

  content: "";

  border-width: 5px;
  border-style: solid;
  border-color: #ff0000 transparent transparent transparent;
}

.diagramFork:hover .diagramFork__toolTipText {
  font-size: 12px;
  display: block;
  visibility: visible;

  opacity: 1;
}
