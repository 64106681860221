.transparentLayer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.meetingConfirm {
  box-sizing: border-box;
  width: 90%;
  margin: 200px auto;
  border: 1px solid #000000;
  background-color: #fff;
  padding-bottom: 25px;
}

.meetingConfirm__close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 6px 6px 0 0;
}

.meetingConfirm__header {
  padding-top: 12px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.meetingConfirm__description {
  margin-top: 12px;
  text-align: center;
}

.meetingConfirm__button {
  display: flex;
  max-width: 542px;
  justify-content: space-around;
  margin: 40px auto 0;
}

@media screen and (max-width: 1024px) {
  .meetingConfirm {
    width: 600px;
  }
  .meetingConfirm__header {
    padding-top: 32px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
}

@media screen and (max-width: 768px) {
  .meetingConfirm {
    width: 500px;
  }
  .meetingConfirm__header {
    padding: 18px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
}

@media screen and (max-width: 567px) {
  .meetingConfirm {
    width: 400px;
  }
  .meetingConfirm__header {
    padding: 14px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
}

@media screen and (max-width: 567px) {
  .wrapper {
    padding: 0 10px;
  }
  .meetingConfirm {
    width: 400px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .meetingConfirm__header {
    padding: 14px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
  .meetingConfirm__description {
    padding: 0 15px;
    font-size: 16px;
  }
  .meetingConfirm__button {
    display: flex;
    justify-content: space-around;
    margin: 16px 0 0 0;
  }
}

.meetingConfirm__meeting {
  margin-left: 15px;
  margin-top: 5px;
  text-align: left;
  word-wrap: normal;
  word-break: keep-all;
}

.meetingConfirm__question {
  margin-top: 15px;
}
