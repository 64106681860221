.modulesPage {
    width: 546px;
    margin: 0 auto;
}

.modulesPage__link {
    width: 516px;
    margin-right: 10px;
}

.modulesPage__item {
    display: flex;
    align-items: center;
    flex-direction: row;

    width: 100%;
    margin-bottom: 20px;
}

.modulesPage__tabs {
    margin: 0 0 24px 0;
}
