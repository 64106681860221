.form {
  max-width: 876px;
  border: 1px solid #000;
  box-sizing: border-box;
  margin: 20px 0 50px;
  padding: 20px 90px 51px 180px;
  min-width: 876px;
}

.form__options {
  margin: -180px 0 0;
}

.form__options__warning {
  position: relative;
  margin: 0 0 120px;
}

.form__options__warningText {
  width: 550px;
  font-size: 10px;
}

.form__menu {
  position: sticky;
  top: 10px;
  margin: -20px 0 0 -180px;
  max-width: 165px;
}

.form__menuItem {
  min-height: 40px;
  max-height: 61px;
  padding: 10px 0 10px 10px;
  border-top: 1px solid black;
  border-right: 1px solid black;
  width: 156px;

  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid black;
  }
}

.form__menuItem_selected {
  min-height: 40px;
  max-height: 61px;
  padding: 10px 0 10px 10px;
  width: 165px;

  background: #afd6f0;
}

.form__minStudentCount {
  margin-right: 100px;
}

.form__minMaxStudentCount_input {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 0 0 35px;
}

.form__minMaxStudentCount {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 20px 0 40px;
}

.form__saved {
  margin: 20px 0 20px 180px;
  width: 516px;
}

.form__minMaxInputs {
  align-content: center;
}

.form__inputs {
  display: flex;
  align-items: center;
  min-width: 606px;
  margin-bottom: 55px;
}

.form__title {
  margin: 0 0 30px;
}

.form__equipments {
  margin: 0 0 55px;
}

.form__skills {
  margin-bottom: 55px;
}

.form__professors {
  margin-bottom: 55px;
}

.form__line {
  width: 100%;
  height: 1px;
  background-color: #000;
  margin: 30px 0;
}

.form__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 39px;
}

.form__buttons_margin_right {
  margin-right: 24px;
}

.form__container {
  margin-bottom: 20px;
}

.form__theme {
  width: 606px;
  margin-bottom: 20px;
}

.form__warning {
  font-size: 12px;
  color: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 3px;
  padding: 2px 5px;
  width: max-content;
}

.form__span {
  margin-right: 39px;

  &:last-child {
    margin-right: 0;
  }
}

.form_remove {
  width: max-content;
  border: 3px solid var(--mock-color);
  height: min-content;
}

.form_remove:hover {
  background-color: var(--mock-color);
}

.form_send {
  width: max-content;
  background-color: var(--mock-color);
}

.form_send:hover {
  background-color: #f3ae00;
}

.form__lineAndButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form__lineAndButton__button {
  margin: 0 0 0 80px;
}

.form__checkBoxTitle {
  font-size: 14px;
  margin-bottom: 20px;
}

.form__error {
  margin-top: 24px;
  color: red;
}
