.suggest {
    width: 300px;

    cursor: pointer;

    border: 1px solid #000;
}

.suggest_size-s {
    width: 204px;
    height: 35px;
}

.suggest_size-l {
    width: 360px;
}

.suggest-input {
    display: flex;
    align-items: center;
    flex-direction: row;

    height: 30px;
    min-width: 200px;
}

.suggest-input_text {
  margin-bottom: 19px;
}

.suggest-input_minmax {
  align-items: center;
  flex-direction: row;
}

.suggest-input__label {
    padding-right: 10px;
    color: #000;
    font-size: 18px;
}