.equipmentCardItem {
  display: flex;
}

.equipmentCardItem__title {
  display: flex;
  align-items: center;

  width: 80%;
  margin-right: 8px;

  cursor: pointer;
  word-wrap: anywhere;

  font-size: 14px;
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }
}

.equipmentCardItem__icon {
  width: 15px;
  height: 15px;

  cursor: pointer;
}
