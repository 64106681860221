.notVotedAccordionItems {
    height: calc(100vh - 213px);

    &__items {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__scroll {
        height: 100%;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;

        &::-webkit-scrollbar {
            width: 15px;
        }

        &::-webkit-scrollbar-track {

            background-color: #FAFAFA;
            padding-bottom: 1px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #E3E3E3;

            border-radius: 10px;
            border: 3.5px solid #FAFAFA;
        }
    }

    &__item {
        width: 100%;
        padding: 9px 0 11px 0;

        display: grid;
        grid-template-columns: 248px auto;

        border-bottom: 1px solid #ECEAE9;
        border-right: 1px solid #ECEAE9;

        &_head {
            width: 100%;
            grid-template-columns: 248px auto;
            border-right: none;
        }
    }

    &__title {
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;

        &_head {
            font-size: 13px;
            line-height: 16px;
            color: #000;
            opacity: .6;
            font-weight: 500;
        }
    }

    &__rating {
        font-size: 13px;
        line-height: 16px;
        font-weight: 300;

        &_head {
            font-size: 13px;
            line-height: 16px;
            color: #000;
            opacity: .6;
        }
    }
}