.weekSchedule {
    width: 100%;
}

.weekSchedule__block {
    display: flex;
    height: 509px;
    position: relative;
    background: #FFF;
    border: 1px solid #F6F6F6;
    border-radius: 30px;
    // overflow: hidden;
}

.weekSchedule__gridWrapper {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background: #C4C4C4;
        border-radius: 10px;
    }
}

.weekSchedule__assignments {
    width: calc(100% - 64px);
    left: 64px;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .weekSchedule {
        width: 100%;
    }
    .weekSchedule__grid {
        width: inherit;
        height: inherit;
        border: none;
    }
    .weekSchedule__block {
        width: inherit;
        height: inherit;
        border-right: none;
    }

    .weekSchedule__gridWrapper {
        overflow-y: visible;
    }
}

@media screen and (max-width: 768px) {
    .weekSchedule {
        display: flex;
        flex-direction: column;
    }

    .weekSchedule__grid {
        overflow-y: inherit;
    }


}

@media screen and (max-width: 480px) {
    .weekSchedule {
        display: flex;
        flex-direction: column;
    }
}

@media print {
    .weekSchedule {
        margin: 0 auto;
        transform: scale(1.2, 1.2);
        width: 500px;
        height: 700px;
    }

    .weekSchedule__block {
        height: 100%;
        border-right: none;
    }

    .weekSchedule__gridWrapper {
        overflow-y: visible;
    }

    .weekSchedule__grid {
        border-right: none;
        overflow-y: visible;
    }

    .weekSchedule__assignments {
        width: calc(100% - 32px - 1px);
    }
}
