.trajectories {
    width: 915px;
    margin-bottom: 86px;
}

.trajectories__tabs {
    margin-bottom: 48px;
}

.trajectories__disabled {
    color: #000;
    text-align: center;
    margin-top: 178px;
}