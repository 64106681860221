.moduleCards {
  &__item {
    display: inline-flex;
    flex-direction: column;

    padding: 10px 0;

    width: 100%;

    border-bottom: 1px solid #ECEAE9;

    &:hover {
      background: #F8F9FE;
    }
  }

  &__item_open {
    padding-top: 18px;

    background: #F8F9FE;
  }
}


.moduleContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px 24px 43px;

  background: #F8F9FE;
}

.moduleContent__header {
  color: #181818;
  font-family: "Exo-2";
  font-weight: 600;
  font-size: 20px;

}

.moduleContent__listOfEvents {
  display: flex;
  gap: 24px;

  max-height: 400px;
  overflow-y: auto;
}

.eventView_hideInMobile {
  display: flex;
  width: 100%;
}


.moduleContent__skills {
  display: flex;
  flex-direction: row;

  max-height: 400px;
  overflow-y: auto;
}

.tabs {
  padding: 20px 0 0;

  &__list {
    display: flex;
    gap: 25px;
  }

  &__tab {
    font-size: 14px;
    color: #6D6E6F;
    font-weight: 500;
    font-family: Exo-2, serif;

    padding: 4px 0;

    text-align: center;
    cursor: pointer;

    &_selected {
      border-bottom: 2px solid #0B42DC;
      color: #000;
    }

  }
}


.moduleContent__teacher {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0;
  break-inside: avoid;
  font-size: 14px;
}

.moduleContent__teacher_list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    max-height: 15px;
  }

  &::-webkit-scrollbar-track {
    border: 1px solid #f6f6f6;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(177, 177, 177, 0.2);
    border-radius: 6px;
  }
}


.moduleContent__teacher_roles {
  color: #c4c4c4;
  font-size: 14px;
}

@media screen and (max-width: 980px) {
  .moduleCards__item {
    width: 100%;
    padding: 5px 0;

    background-color: white;

    &:hover {
      background-color: white;
    }
  }
}

@media screen and (max-width: 768px) {
  .moduleContent {
    height: auto;
    padding: 0 0 10px 8px;

    &__skills {
      flex-direction: column;
    }

    &__teacher_list {
      gap: 8px;
      padding: 0 0 0 9px;
    }

    &__teacher {
      margin: 0;
    }
  }

  .tabs {
    padding: 0;

    &__list {
      width: 100%;
      white-space: nowrap;
      flex-wrap: nowrap;
      overflow-x: auto;
      padding: 8px 0;

      li {
        display: inline-block;
      }
    }
  }
}