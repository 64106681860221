.timeInput {
    height: 36px;

    padding: 8px 6px;

    border-radius: 10px;
    border: none;
    outline: none;

    box-shadow: 0 2px 6px 0 rgba(24, 20, 50, 0.13);

    color: #0B42DC;

    font-size: 14px;
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.42px;
}

.timeInput::-webkit-calendar-picker-indicator {
    display: none;
}

.timeInput::-webkit-datetime-edit-fields-wrapper {
    padding: 0;
}

// ругается на селектор, но он есть в Safari тоже работает
.timeInput::-webkit-datetime-edit {
    display: flex;
    align-items: center;
    justify-content: center;
}

.timeInput::-webkit-datetime-edit-hour-field, .timeInput::-webkit-datetime-edit-minute-field {
    padding: 0;
}

.timeInput::-webkit-datetime-edit-hour-field:focus, .timeInput::-webkit-datetime-edit-minute-field:focus {
    padding: 0;

    color: #0B42DC;
    background-color: #F3F6FD;
}
