.menu {
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    position: sticky;
    top: 40px;
    margin: 40px 0;
    border-radius: 30px;
    box-shadow: var(--default-shadow);
    flex-shrink: 0;
    z-index: 100;
}

.menu__navigation_burger {
    display: none;
}

.menu__profile {
    &__logout {
        font-family: "Exo-2";
        font-size: 18px;
        margin: 0;
        text-align: left;
    }
}

@media screen and (max-width: 768px) {
    .menu__profile {
        display: none;
    }

    .menu {
        width: 100%;
        max-width: 100%;
        top: 12px;
        background: transparent;
        box-shadow: none;
        flex-direction: column-reverse;
        margin: 12px 0 0 0;
    }

    .menu__navigation {
        width: 100%;
        position: absolute;
        top: calc(100% + 12px);
        left: 0;
        padding: 0;
        background: #fff;
        transform: translateY(10px);
        transition: opacity 0.1s ease-in-out, transform 0.2s cubic-bezier(0.4, 0, 0, 1);
        box-shadow: var(--default-shadow);
        opacity: 0;
        pointer-events: none;
    }

    .menu__navigation_active {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0);
    }

    .menu__profile {
        display: none;
    }

    .menu__line {
        border-radius: 15px;
        height: 3px;
        background: #000;
        width: 30px;
        transform: translateX(0);
        transition: width 0.1s cubic-bezier(0.4, 0, 0.2, 1),
            transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);

        &:nth-child(1) {
            transition-delay: 0.2s;
        }

        &:nth-child(2) {
            transition-delay: 0.25s;
        }

        &:nth-child(3) {
            transition-delay: 0.3s;
        }
    }

    .menu__burger {
        width: 30px;
        height: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: sticky;
        top: 0;
        left: 0;
        padding: 0;
        z-index: 2000;

        &::before,
        &::after {
            content: "";
            width: 0;
            height: 3px;
            border-radius: 100px;
            position: absolute;
            top: 50%;
            left: 50%;
            background: #000;
            transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        }

        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
            transition-delay: 0.05s;
        }

        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
            transition-delay: 0.1s;
        }
    }

    .menu__burger_active {
        &::before,
        &::after {
            content: "";
            width: 30px;
            height: 3px;
            border-radius: 100px;
            position: absolute;
            top: 50%;
            left: 50%;
            background: #000;
        }

        &::before {
            transition-delay: 0.2s;
        }

        &::after {
            transition-delay: 0.25s;
        }
    }

    .menu__burger_active {
        .menu__line {
            width: 0;
            transition: width 0.1s cubic-bezier(0.4, 0, 0.2, 1),
                transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);

            &:nth-child(1) {
                transition-delay: 0.05s;
            }

            &:nth-child(2) {
                transition-delay: 0.1s;
            }

            &:nth-child(3) {
                transition-delay: 0.15s;
            }
        }
    }
}
