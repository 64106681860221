.listItem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 24px;
  }
}

.listItem__container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.listItem__intervals {
  padding-left: 35px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.listItem__intervals_title {
}

.address {
  box-sizing: border-box;
}

.address__buildingName {
  display: inline;

  word-wrap: break-word;
}

.address__buildingLocation {
  display: inline-block;

  word-wrap: break-word;
}

.listItem__button {
  cursor: pointer;
  padding: 0 15px 0 0;
  border: none;
  background: #fff;
}
