.roomSpace {
	margin-bottom: 24px;
    align-items: flex-start;
}

.roomSpace div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.formRoom__input {
    input:required {
        box-shadow: #6BA3CE; // possible to add image
    }
    input:invalid {
        box-shadow: 0 0 5px 1px red;
    }

    input:focus:invalid {
        box-shadow: none;
    }
}

.roomSpace__label {
    color: #000;
    font-family: Exo2, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.8px;
}