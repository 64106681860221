.trajectoriesList__disabled {
    color: #000;
    text-align: center;
    margin-top: 178px;
}

/* header */

.trajectoriesListHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 52px;
}

.trajectoriesListHeader__title {
    font-family: 'Exo-2';
    font-size: 26px;
    font-weight: 700;
}

.trajectoriesListHeader__buttons {
    display: flex;
}

.trajectoriesListHeader__createButton {
    margin-left: 15px;
}

/* settings */

.trajectoriesListSettings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

// .trajectoriesListSettings__text {}
