.equipmentForm {
    width: 786px;
    background: #FFF;
    box-shadow: var(--default-shadow);
    border-radius: 15px;
    padding: 40px 90px;
}

.equipmentForm__container {
    display: flex;
    flex-direction: column;
}

.equipmentForm__list {
    margin-bottom: 28px;
}

.equipmentForm__item {
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }
}

.equipmentForm__iconContainer {
    display: flex;
    align-items: center;

    margin-bottom: 40px;

    cursor: pointer;
}

.equipmentForm__iconText {
    margin-left: 12px;
}

.equipmentForm__icon {
    width: 26px;
    height: 26px;

    cursor: pointer;
}

.equipmentForm__button {
    align-self: center;
}