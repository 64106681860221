.regularEquipment {
    font-family: "Exo-2";
    font-size: 14px;
    position: relative;
}

.regularEquipment__defaultValue {
    color: rgba(109, 110, 111, 1);
}

.regularEquipment__form {
    display: flex;
    background-color: white;
    height: 36px;
    width: 100%;
}

.regularEquipment__form_editing {
    background-color: rgba(11, 66, 220, 0.03);
}

.regularEquipment__form:hover {

    background-color: rgba(11, 66, 220, 0.03);
}

.regularEquipment:hover>.regularEquipment__crossDeleteContainer {
    display: block;
}

.regularEquipment__equipmentNameContainer {
    flex: 1 150px;
    padding-left: 28px;
}

.regularEquipment__equipmentNameContainer_subtypeMode {
    padding-left: 55px;
}

.regularEquipment__equipmentName {
    cursor: pointer;
    height: 36px;
    padding: 8px 0px 8px 10px;
    display: flex;
}

.regularEquipment__equipmentName:hover {
    box-sizing: border-box;
    padding: 7px 0px 7px 9px;
    border: 1px solid #DBE1F0;
}

.regularEquipment__equipmentNameCount {
    color: rgba(122, 122, 122, 1);
    position: relative;
    left: 5px;
}

.regularEquipment__equipmentNameEdit {
    background-color: white;
    height: 36px;
    padding: 7px 0px 7px 9px;
    border-bottom: 1px solid rgba(236, 234, 233, 1);
}

.regularEquipment__equipmentNameEdit:focus-within {
    border: 1px solid rgba(11, 66, 220, 1);
    ;
}

.regularEquipment__equipmentCountContainer {
    flex: 0 69px;
    display: flex;
}

.regularEquipment__equipmentCountContainer:focus-within {
    flex: 0 69px;
    display: flex;
}

.regularEquipment__equipmentCount {
    display: flex;
    flex-direction: row;

    font-family: 'Exo-2';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    letter-spacing: -0.03em;
    cursor: pointer;
    padding: 8px 0px 8px 11px;
    height: 36px;
}

.regularEquipment__equipmentCount:hover {
    box-sizing: border-box;
    padding: 7px 0px 7px 10px;
    border: 1px solid #DBE1F0;
}

.regularEquipment__roomCount {
    font-family: 'Exo-2';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.03em;
    color: #ABABAB;
}


.regularEquipment__equipmentCountEdit {
    position: absolute;
    top: 30px;

    z-index: 4;

    display: flex;
    flex-direction: column;
    flex-direction: column;

    font-family: 'Exo-2';
    font-size: 14px;
    border: 1px solid rgba(11, 66, 220, 0.3);
    border-radius: 6px;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.07);

    background-color: white;
}

.regularEquipment__equipmentMobilityContainer {
    flex: 0 150px;
}

.regularEquipment__equipmentMobility {
    flex: 0 150px;
    padding: 8px 0px 8px 10px;
    cursor: pointer;
}

.regularEquipment__equipmentSubtypesContainer {
    flex: 0 203px;
    padding: 8px 0px 8px 11px;
}

.regularEquipment__addSubtypeButton {
    display: none;
    color: rgba(11, 66, 220, 1);
    cursor: pointer;
}

.regularEquipment:hover .regularEquipment__addSubtypeButton {
    display: block;
}

.regularEquipment__addSubtypeButton_subtypeMode {
    display: none;
}

.regularEquipment:hover .regularEquipment__addSubtypeButton_subtypeMode {
    display: none;
}

.regularEquipment__equipmentRoomsContainer {
    flex: 1 1 150px;
    padding: 7px 0px 11px 10px;
}

.regularEquipment__countContainer {
    flex: 0 1 69px;
}


.regularEquipment__polygonContainer {
    width: 0px;
    height: 0px;
    position: absolute;
    top: 10px;
    left: 8px;
}

.regularEquipment__polygon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    position: relative;
    left: 1px;
    margin-right: 9px;
    cursor: pointer;
}

.regularEquipment__polygon svg {
    fill: #6D6E6F;
}

.regularEquipment__polygon:hover {
    background: rgba(11, 66, 220, 0.06);
}

.regularEquipment__polygon_highlight svg {
    fill: #0B42DC;
}

.regularEquipment__polygon:active svg {
    fill: #0B42DC;
}

.regularEquipment__polygon_open {
    transform: rotate(90deg);
}

.regularEquipment__addEquipment {
    width: 100%;
    padding: 11px 0px 12px 65px;
}

.regularEquipment__addEquipmentLabel {
    cursor: pointer;
    font-family: 'Exo-2';
    font-size: 13px;
    color: rgba(130, 130, 130, 1);
}

.regularEquipment__crossDeleteContainer {
    display: none;
    position: relative;
    width: 0px;
    height: 0px;
}

.regularEquipment__crossDelete {
    position: absolute;
    top: 11px;
    left: 8px;
}

.movability__option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 16px;

    cursor: pointer;
}

.movability__option_selected {
    background: rgba(11, 66, 220, 0.03);
}

.movability__option:hover {
    background: rgba(11, 66, 220, 0.03);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Works for Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.movability__input {
    all: inherit;

    width: 43px;
    height: 37px;

    padding-left: 5px;
}

.movability__moveableCount {
    all: inherit;

    width: 43px;
    height: 37px;

    padding: 7px 0 0 5px;
}

.movability__input:focus {
    background: white;
    border: 1px solid #0B42DC;
    border-width: 1px;
}

.movability__subtitle {
    font-family: 'Exo-2';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 36px;
    /* or 257% */

    letter-spacing: -0.03em;

    /* Lighter Black */

    color: #6D6E6F;

    margin-right: 30px;

    cursor: pointer;
}

.movability__subtitle_bold {
    height: 36px;

    text-align: center;
    font-family: 'Exo-2';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 36px;
    /* identical to box height, or 257% */

    letter-spacing: -0.03em;

    /* basic black */

    color: #000000;

    margin-right: 30px;

    cursor: pointer;
}

.movability__territorialZone {
    text-align: center;
    font-family: 'Exo-2';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 36px;

    letter-spacing: -0.03em;

    color: #6D6E6F;

    max-width: 140px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    margin-right: 30px;
}

.movability__territorialZone_selected {
    font-family: 'Exo-2';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 36px;

    letter-spacing: -0.03em;

    color: #161719;
}

.equipmentRoom__link {
    font-family: 'Exo-2';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.03em;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
    padding: 5px;

    letter-spacing: -0.03em;

    color: #0B42DC;
}

.equipmentRoom__link:hover {
    color: #0B42DC;

    text-decoration-line: underline;
}

.equipmentRoom__link:visited {
    color: #7E99E7;
}

.equipmentRoom__count {
    color: #7A7A7A;
}

.equipmentRoom__hiddenRooms {
    position: absolute;
    top: 30px;

    z-index: 3;

    max-height: 230px;
    max-width: 522px;
    width: max-content;

    overflow: scroll;

    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    padding: 10px 10px;

    background: #FFFFFF;

    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
    border: 1px solid #BDCDFC;
    border-radius: 6px;
}

.equipmentRoom__moreButton {
    cursor: pointer;
    color: #0B42DC;
}

.error__window {
    position: fixed;
    top: 0;
    left: 0;

    display: none;
    align-items: flex-start;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.3);
}