.skillAddWithoutNest__form {
  position: relative;
}

.skillAddWithoutNest__selectLabel {
  display: inline-block;

  width: 100%;
  margin-top: 17px;
  margin-bottom: 45px;
}

.skillAddWithoutNest__select {
  margin-left: 15px;
}

.skillAddWithoutNest__label {
  display: flex;
  flex-direction: column;

  width: 360px;
  margin-bottom: 17px;
}

.skillAddWithoutNest__input {
  height: 30px;
  margin-top: 5px;

  border:1px solid black;
}

.skillAddWithoutNest__input_border_red {
  height: 30px;
  margin-top: 5px;

  border:1px solid red;
  
  &:focus {
    outline: none !important;
    box-shadow: 0 0 2px red;
  }
}

.skillAddWithoutNest__textarea {
  height: 95px;
  margin-top: 5px;

  resize: none;
}

.skillAddPortal {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, .4);
}

.skillAddPortal__wrapper {
  position: relative;

  min-width: 786px;
  margin-left: 24px;

  border: 1px solid #000;
  background-color: #fff;
}

.skillAddPortal__container {
  display: flex;
  flex-direction: column;

  margin: 21px 60px 26px;

  font-size: 18px;
}

.skillAddPortal__title {
  margin-bottom: 24px;

  font-size: 24px;
  font-weight: 700;
}

.blackCrossSkillModal {
  position: absolute;

  top: 22px;
  right: 22px;
}

.blackCrossSkillAddForm {
  position: absolute;

  top: -15px;
  right: -15px;
}

.skillAddWithoutNest__updateButton {
  display: flex;
  justify-content: center;
}