.interval {
    position: relative;

    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;

    width: 360px;
    padding: 1px 0 3px 0;

    background: var(--mock-color);
}

.interval__nameGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 360px;
    padding: 0 12px;
}

.interval__btn {
    position: absolute;
    right: -11px;
    top: 2px;

    display: block;
    align-items: center;

    margin: 0;
    padding: 0;

    color: #c4c4c4;
}

.interval__info {
    color: #000;
    font-family: Manrope, sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.51px;
}

.interval__info_bold {
    font-weight: 600;
}