.universitySpecialDaysItem {
    max-width: 605px;
    display: flex;
    flex-wrap: wrap;
	flex-direction: row;
}

.universitySpecialDaysItem__monthName {
    font-weight: 700;
    font-size: 18px;
    margin-right: 5px;
}

.universitySpecialDaysItem__dates {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.universitySpecialDaysItem__date {
    display: flex;
    margin-right:10px;
}

.universitySpecialDaysItem__deleteButton {
    margin-left:3px;
    margin-top:1px;
    cursor: pointer;
}