.moduleMainCharactersParams {
    display: flex;
    margin-bottom: 14px;
    font-size: 14px;
}

.moduleMainCharactersParams__numberList {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
}

.moduleMainCharactersParams__numberItem {
    display: flex;
    justify-content: flex-end;
    font-weight: 700;
    margin-bottom: 6px;
    width: 70px;

    &:last-child {
        margin-bottom: 0;
    }
}

.moduleMainCharactersParams__textItem {
    margin-bottom: 6px;

    &:last-child {
        margin-bottom: 0;
    }
}