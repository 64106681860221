@import 'src/admin/NewModule/assets/styles/colors';

.customCheckbox {
  position: absolute;
  z-index: -1;

  opacity: 0;
}

.customCheckbox+label {
  display: inline-flex;
  align-items: center;

  user-select: none;
}

.customCheckbox+label::before {
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;

  width: 24px;
  height: 24px;
  margin-right: 12px;

  content: '';

  border: 1px solid #adb5bd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.customCheckbox:checked+label::before {
  border-color: black;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 14'%3e%3cpath fill='none' d='M1 7L7 13L17 1' stroke='black' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
}

/* стили при наведении курсора на checkbox */
.customCheckbox:not(:disabled):not(:checked)+label:hover::before {
  border-color: black;
}
/* стили для активного состояния чекбокса (при нажатии на него) */
.customCheckbox:not(:disabled):active+label::before {
  border-color: black;
}

/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.customCheckbox:focus:not(:checked)+label::before {
  border-color: black;
}
/* стили для чекбокса, находящегося в состоянии disabled */
.customCheckbox:disabled+label::before {
  background-color: #e9ecef;
}
