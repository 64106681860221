.eventItem {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px;
  padding-right: 16px;
  justify-content: space-between;

  border-bottom: 1px solid #ECEAE9;

  cursor: pointer;
}

.eventItem_selected {
  border: none;
}

.eventItem__container {
  display: flex;
  flex-direction: row;
}

.eventItem__container_selected {
  background: #F8F9FE;
}

.eventItem__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.eventItem__info {
  display: flex;
  flex-direction: row;
}

.eventItem__informationContainer {
  display: flex;
  background-color: #DFEDFD;
  border-radius: 6px;
  padding: 12px 12px 20px;

  gap: 72px;
}

.eventItem__information {
  display: flex;
  flex-direction: column;

  gap: 10px;
}


.eventItem__text {
  display: flex;
  flex-direction: column;
}

.eventItem__title {
  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.eventItem__title_meeting {
  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.03em;

  color: #161719;
}

.eventItem__title_assignment {
  font-family: 'Exo-2';
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  letter-spacing: -0.03em;

  color: #000000;
  ;
}

.eventItem__description {
  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

}

.eventItem__description_meeting {
  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  letter-spacing: -0.03em;
  color: #161719;
}

.eventItem__description_assignment {
  font-family: 'Exo-2';
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.03em;

  color: #000000;

}

.eventView__infoItem {
  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  letter-spacing: -0.03em;

  color: #000000;

  &_isTitle {
    font-family: 'Exo-2';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;

    letter-spacing: -0.01em;

    color: #214572;
  }

}

.eventView__arrow {
  transform: rotate(90deg);
}

.eventView__arrow_open {
  transform: rotate(-90deg);
}

.svgCircle {
  position: relative;
  top: 0;
  left: 1px;
}

.svgRect {
  position: relative;
  top: 10px;
  width: 2px;
  height: 9px;

  background: #0B42DC;
}

.skillType {
  margin: 25px 0 0 8px;
}

.eventView__skillType {
  margin: 3px 0 0 0;
}

.skillType__name {
  display: flex;
  flex-direction: row;

  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  letter-spacing: -0.03em;

  padding-bottom: 8px;

  cursor: pointer;
}

.skillType__skillContainer {
  padding-bottom: 0;
}

.skillType__skill {
  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */

  letter-spacing: -0.03em;

  margin-left: 27px;

  color: black;

  list-style-type: disc;

  &_withParents {
    margin-top: 15px;
  }
}

.eventView__skill {
  margin: 0 0 0 27px;
}

.skillType__parents {
  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.03em;

  margin-left: 27px;

  color: #6D6E6F;
}

@media screen and (max-width: 920px) {
  .eventItem {
    border: none;
  }

  .eventItem__informationContainer {
    display: flex;
    flex-direction: column;
    gap: 11px;
  }
}

@media screen and (max-width: 480px) {
  .eventView__arrow {
    display: none;
  }

  .eventView__arrow_open {
    display: none;
  }
}