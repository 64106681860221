.backToTheSpaces {
  display: flex;
  align-items: center;

  width: 516px;
  margin-bottom: 82px;

  cursor: pointer;

  background: var(--mock-color);
}

.backToTheSpaces__icon {
  width: 13px;
  height: 22px;
  margin-left: 5px;
}

.backToTheSpaces__title {
  padding: 15px 0 15px 10px;

  font-weight: 700;
}
