.skill {
    display: flex;
    align-items: center;
}

.skillLevel {
    font-weight: bold;
}

.iconCheckMark {
    display: inline;
    position: relative;
    top: 6px;
    & svg {
        cursor: default;
    }
}
