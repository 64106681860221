.external-module {
    max-width: 1024px;
    width: -webkit-fill-available;
    padding: 40px;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.external-module__title {
    font-family: 'Exo-2';
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;

    margin-bottom: 35px;
}

.external-module__form-container {
    width: 100%;
    background: #FFFFFF;
    border-radius: 30px;
}

.external-module__action-panel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 45px;
    margin-top: 20px;
    position: relative;
}

.external-module__action-panel-loader {
    position: absolute;
    margin-right: 5px;
    margin-top: 3px;
}
