.evaluationPoint {
  box-sizing: border-box;
  padding: 20px 89px 51px 180px;
  min-width: 876px;
  max-width: 876px;
  border: 1px solid #000;
  margin-bottom: 20px;
}

.evaluationPoint__saved {
  width: 516px;
  margin: 50px 0 50px 180px;
}

.evaluationPoint__input {
  margin-bottom: 20px;
}

.evaluationPoint__container {
  margin-bottom: 50px;
}

.evaluationPoint__buttons {
  display: flex;
  justify-content: flex-end;

  margin-top: 39px;
}

.evaluationPoint__buttons_margin_right {
  margin-right: 24px;
}

.evaluationPoint__iconHover {
  display: none;
}

.evaluationPoint__iconHover_visible {
  position: absolute;
  top: 12px;
  right: 40px;

  display: flex;

  margin-right: 5px;
  padding: 2px;

  border: 3px solid var(--mock-color);
  border-radius: 3px;
}

.evaluationPoint__warning {
  width: max-content;
  padding: 2px 5px;

  color: red;
  border: 1px solid #ff0000;
  border-radius: 3px;
  font-size: 12px;
}

.evaluationPoint__remove {
  width: max-content;
  height: min-content;
  border: 3px solid var(--mock-color);
  background-color: none;
}

.evaluationPoint__remove:hover {
  background-color: var(--mock-color);
}

.evaluationPoint__send {
  width: max-content;

  background-color: var(--mock-color);
}

.evaluationPoint__send:hover {
  background-color: #f3ae00;
}

.evaluationPoint__block {
  margin-bottom: 40px;
}

.evaluationPoint__arrow {
  margin-bottom: 20px;
  margin-left: 425px;
}

.evaluationPoint__error {
  margin-top: 24px;
  color: red;
}
