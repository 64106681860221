.absencesAccordion {

}

.headerClassname {
    display: flex;
    align-items: center;
}

.headerClassname_active {
    background-color: #F8F9FE;
    margin-top: -1px;
    padding-top: 1px;

    display: flex;
    align-items: center;
}

.headerClassname_active .absencesAccordion__header {
    border-bottom: 1px solid #F8F9FE;
}

.headerClassname_active:hover .absencesAccordion__header_noBorder {
    border-bottom: none;
}

.headerClassname_active .absencesAccordion__header:hover .absencesAccordion__header_add {
    display: block;
}

.headerClassname_active:hover .absencesAccordion__header_noBorder {
    border-bottom: none;
}

.absencesAccordion__header {
    width: 100%;

    height: 37px;

    display: grid;
    grid-template-columns: 125px auto;

    border-bottom: 1px solid #ECEAE9;

    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: Exo-2, sans-serif;
    letter-spacing: -0.03em;
}

.absencesAccordion__header_add {
    display: none;
}

.headerClassname:hover {
    background-color: #F8F9FE;
    margin-top: -1px;
    padding-top: 1px;
}

.headerClassname:hover .absencesAccordion__header {
    border-bottom: 1px solid #F8F9FE;
}

.headerClassname:hover .absencesAccordion__header_bti {
}

.absencesAccordion__header:hover .absencesAccordion__header_add {
    display: block;
}

.absencesAccordion__header_noBorder {
    border: none;
}

.headerClassname:hover .absencesAccordion__header_noBorder {
    border-bottom: none;
}

.absencesAccordion__header_bti {
    display: flex;
    //align-items: center;
    gap: .2rem;

    padding: 11px 0 9px 0;
}

.absencesAccordion__header_dark {
    color: #161719;
}

.absencesAccordion__header_gray {
    color: #6D6E6F;
}

.absencesAccordion__header_absence {
    display: flex;
    //justify-content: flex-end;
    width: 100%;
}

.absencesAccordion__absence {
    display: grid;
    grid-template-columns: 125px auto;

    margin-left: 27px;

    border-bottom: 1px solid #E7E7E7;
}

.absencesAccordion__absence:hover {
    background-color: #F8F9FE;
    //padding-top: 12px;
    border-bottom: 1px solid #F8F9FE;
    border-top: 1px solid #F8F9FE;
    margin-top: -1px;
}

.absencesAccordion__absence_add {
    padding: 11px 0 9px 0;
    width: fit-content;

    font-size: 13px;
    line-height: 16px;
    color: #0B42DC;
    font-family: Exo-2, sans-serif;
}

.absencesAccordion__absence_lighterBorder {
    border-bottom: 1px solid rgba(236, 234, 233, .3);
}

.absencesAccordion__items {
    display: flex;
    flex-direction: column;
}

