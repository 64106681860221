.skill-add-with-nest {
  margin-bottom: 20px;

  color: black;
}

.skill-add-with-nest__thame {
  margin-bottom: 25px;
}

.skill-add-with-nest__form {
  position: relative;

  display: flex;

  margin-top: 20px;
  margin-bottom: 45px;
}

.skill-add-with-nest__label {
  display: flex;
  flex-direction: column;

  width: 270px;
  margin-bottom: 17px;
}

.skill-add-with-nest__input {
  height: 30px;
  margin-top: 5px;
}

.skill-add-with-nest__textarea {
  height: 95px;
  margin-top: 5px;

  resize: none;
}

.skill-add-with-nest__item {
  display: flex;
  flex-direction: column;

  margin-bottom: 25px;

  border: 1px solid #000;
}

.skill-with-nest__head {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;
}

.skill-add-with-nest__head-title {
  width: 100%;
  padding-top: 7px;

  cursor: pointer;
  text-align: center;

  font-size: 27px;
}

.skill-add-with-nest__head-title--hidden {
  display: none;
}

.skill-add-with-nest__head-number {
  position: absolute;
  left: 0;

  padding-left: 35px;

  background: var(--mock-color);
}

.item__input__group {
  margin-bottom: 2rem;
}

.skill-with-nest__hidden {
  display: none;
}

.skill-add-with-nest__input-block {
  padding: 0 50px;
}

.skill-add-with-nest__section-wrapper {
  display: flex;
}

.skill-with-neat__button-delete {
  position: absolute;
  top: 10px;
  right: 10px;

  cursor: pointer;
}

.skill-with-nest__button-delete--hidden {
  display: none;
}

.skill-with-nest__icon-container {
  display: inline-flex;
  align-items: center;

  cursor: pointer;
}

.skill-with-nest__bottom-icon-container {
  display: inline-flex;
  align-items: center;

  margin-top: 20px;

  cursor: pointer;
}

.skill-with-nest__icon-text {
  margin-left: 10px;
}

.dependency__add-from {
  display: flex;
}

.dependency__dependency-card {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 190px;
  margin-bottom: 6px;
  padding: 2px 0 2px 5px;

  background: var(--mock-color);
}

.dependency__text-field {
  width: 247px;
  height: 30px;
  margin-top: 6px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.yellow-plus-26px {
  width: 26px;
  height: 26px;

  cursor: pointer;
}
.skill-add-with-nest__input--red-border {
  height: 30px;
  margin-top: 5px;

  border: 1px solid red;

  &:focus {
    outline: none !important;
    box-shadow: 0 0 2px red;
  }
}
