.article {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.article__date {
    font-family: 'Exo-2';
    font-size: 16px;
    font-weight: 600;
}
