@import "../../../../scheduleStyles/filterColors";


.attendanceSection{
    display: flex;
    flex-direction: column;
}

.attendance__buttonContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.attendance__SaveEditBtn {
    margin: 32px 0 30px 0;
    background-color: $color_selection;
    height: 36px;
    width: 155px;
    border-radius: 10px;
    font-size: 14px;
}

.schedulePage_eventsSection_search {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 20px 10px 20px;
}

.attendance__allStudentsToggler{
    width: 100%;
}