.header__subtitle {
  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  letter-spacing: -0.03em;

  color: #6D6E6F;
  padding: 10px 0;

  border-bottom: 1px solid #ECEAE9;
  border-top: 1px solid #ECEAE9;

  background-color: white;

  z-index: 2;

  position: sticky;
  top: 127px;
}

.subspace {
  display: flex;
  align-items: center;
  margin: 10px 0 30px;
  cursor: pointer;
}

.subspace__container {
  display: flex;
  gap: 56px;

  margin-top: 22px;
}

.subspace__wrapper {
  display: grid;
  grid-template-columns: 386px auto;

  &_noGrid {
    display: unset;
  }
}

.subspace__open {
  display: flex;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

.subspace__title {
  font-family: "Exo-2";
  font-weight: 600;
  font-size: 16px;

  cursor: pointer;
}

.subspace__subtitle {
  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #6D6E6F;

  margin-left: 8px;
  margin-top: 15px;
}

.subspace__infoCard {
  max-width: 386px;
  position: sticky;
  top: 167px;
  height: max-content;
}

.tabs {
  padding: 20px 0;

  border-bottom: 1px solid #ECEAE9;
}

.tabs__list {
  display: flex;
  background: #F8F8F8;
  border: 1px solid #C2C2C2;
  border-radius: 6px;
  width: max-content;
}

.tabs__tab {
  color: #0d0d0d;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  cursor: pointer;

  padding: 5px;


  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  letter-spacing: -0.03em;

  &:last-child {
    margin-left: -1px;
    margin-right: 0;
  }
}

.tabs__tab_selected {
  cursor: default;
  background: #0B42DC;
  border-radius: 6px;
  padding: 5px;

  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  letter-spacing: -0.03em;

  color: #FFFFFF;
}

.tabs__tab_point {
  position: absolute;
  right: -12px;
  top: 43%;

  >svg {
    display: block;
  }
}

.subspace__electivePoint {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subspace__button {
  display: flex;

  justify-content: center;
  align-self: center;

  margin-top: 60px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;

  align-items: flex-start;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
}

.popup__container {
  position: relative;
  top: 50%;
  left: 50%;

  max-width: 640px;
  max-height: 90%;
  padding: 54px;

  transform: translate(-50%, -50%);

  background: #ffffff;

  box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
  border-radius: 15px;

  font-size: 14px;
  line-height: 1.5;

  overflow-y: scroll;
  z-index: 100;
}

.popup__container::-webkit-scrollbar {
  display: none;
}

.popup__title {
  font-family: "Exo-2";
  font-style: normal;
  font-weight: bold;
  font-size: 22px;

  color: #000000;
}

.popup__subtitle {
  font-family: "Exo-2";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-top: 48px;
  margin-bottom: 28px;

  color: #000000;
}

.popup__buttonContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.popup__cross {
  position: absolute;
  right: 40px;
  top: 40px;
}

@media screen and (max-width: 1280px) {
  .subspace__container {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

  .subspace__infoCard {
    position: static;
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .subspace__wrapper {
    display: flex;
    flex-direction: column;
  }



  .header__subtitle {
    border-top: 1px solid #ECEAE9;

    background-color: white;

    z-index: 2;

    position: sticky;
    top: 133px;
  }

  .instruction {
    font-family: 'Exo-2';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.03em;
  }

  .instruction_close {
    max-height: 100px;
    overflow: hidden;
    margin-top: -20px;

    cursor: pointer;
  }

  .instruction_close::before {
    content: "";
    display: block;
    height: 20px;
    position: relative;
    top: 80px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f8f9fe 75%)
  }
}