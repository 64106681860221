.filters {
    width: 100%;
    display: flex;

    position: sticky;
    top: 138px;

    background-color: #FFFFFF;
    z-index: 3;

}

.filters__item {
    font-family: 'Exo-2';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.03em;
    color: #6D6E6F;
    border: 1px solid #ECEAE9;
    border-right: none;
}

.filters__item_noFocus {
    font-family: 'Exo-2';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.03em;
    color: #6D6E6F;
    border: 1px solid #ECEAE9;
    border-right: none;
    cursor: default;
}

.filters__item:first-child {
    border-left: 1px solid rgba(0, 0, 0, 0.0);
}


.filters__item:hover {
    background-color: rgba(11, 66, 220, 0.03);
    cursor: pointer;
}

.filters__item:focus-within {
    border: 1px solid #0B42DC;
    background-color: #FFFFFF;

    cursor: default;
}

// .filters__item:last-child {
//     border-right: 1px solid #ECEAE9;
// }