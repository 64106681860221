.addRemoveButton {
  display: flex;
  flex-direction: row;

  box-sizing: border-box;
  padding: 0;

  cursor: pointer;

  border: none;
  background: #fff;
}

.addRemoveButton__icon {
  width: 25px;
  height: 25px;
}

.addRemoveButton__title {
  font-size: 18px;
}

.addRemoveButton_disabled {
  cursor: auto;

  color: #C4C4C4;
}




