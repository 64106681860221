.moduleMainCharactersTeacher__title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}

.moduleMainCharactersTeacher__empty {
  font-size: 20px;
  font-weight: 700;
  margin-top: 40px;
  text-align: center;
}

.moduleMainCharactersTeacher__list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  padding: 0 0 8px 0;
}

.moduleMainCharactersTeacher__list::-webkit-scrollbar {
  display: none;
}

.moduleMainCharactersTeacher__item {
  display: flex;
  align-items: center;
  min-height: 55px;
  padding: 8px 12px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
}

.moduleMainCharactersTeacher__photo {
  min-width: 40px;
  min-height: 40px;
  margin-right: 14px;
}

.moduleMainCharactersTeacher__teacherName {
  font-weight: 700;
  word-break: break-word;
}

.moduleMainCharactersTeacher__teacherRole {
  word-break: break-all;
}
