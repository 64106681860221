.evaluation__studentList_container{
    display: flex;
    align-items: center;
    width: 100%;
    height: 54px;
    margin: 8px 0;
}

.evaluation__studentsList{
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    margin: 0 20px;
}

.evaluation__studentData{
    display: flex;
    align-items: center;
    flex: 0 1 85%;
}

.evaluation__studentAvatar{
    margin-right: 14px;
}

.evaluation__studentAvatar_image {
    display: block;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 10px;
}

.evaluation_dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgb(250, 0, 0);
}

.evaluation_arrowBlock {
    margin: 0 20px 0 16px;
}


.evaluation__buttonContainer{
    display: flex;
    justify-content: center;

    margin-right: 17px;
}

.evaluation__button{
    width: 20px;
    height: 20px;
    margin-left: 5px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #5F5F5F;
    border-radius: 5px;
}

.evaluation__checkmark {
    width: 18px;
    height: 14px;
    fill: none;
}

.evaluation__buttonChecked{
    width:18px;
    height: 14px;
}
