.onlineRoomList {
  max-width: 1150px;
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 200px 220px;
  background: #fff;
  border-radius: 15px;
  box-shadow: var(--default-shadow);
  border: 1px solid #cccccc;
}

.onlineRoom {
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 8px 16px 4px;
  border-bottom: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
  &:last-child {
    border-bottom: none;
  }
  &:nth-child(3n) {
    border-right: none;
  }
  &:nth-child(3n - 1) {
    justify-content: center;
  }
}

.title {
  font-size: 22px;
  font-weight: bold;
}

.onlineRoom_url {
  font-size: 20px;
}

.onlineRoom__icons {
  display: flex;
  justify-content: space-evenly;
}

.onlineRoom__crossIcon {
  margin-top: -12px;
}

.edibleOnlineRoom__input__container {
  padding: 0;
}

.edibleOnlineRoom__input {
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  font-size: 18px;
}

.edibleOnlineRoom__capacityInput {
  text-align: center;
}

.addOnlineRoom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 16px;
  cursor: pointer;
  gap: 12px;
}
