.tabs {
  &__list {
    display: flex;
    gap: 12px;
    margin-bottom: 24px;
  }

  &__link {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 18px;
    font-size: 14px;
    text-align: center;
    background: #fff;
    box-shadow: var(--default-shadow);
    border-radius: 15px;
    cursor: pointer;
    transition: transform .2s ease;

    &_selected {
      cursor: default;
      background: var(--extra-gradient);
      transform: scale(.99);
      box-shadow: none;
    }
  }
}

@media screen and (max-width: 980px) {
  .tabs {
    width: 100%;
    margin: 12px 0;


    &__list {
      width: 100%;
      margin-bottom: 0;
    }
  }
}
