.weekDaysPickerWithTime {
    display: flex;

    gap: 17px;
}

.weekDaysPickerWithTime__time {
    display: flex;

    gap: 9px;
}

.weekDaysPickerWithTime__time_separator {
    display: flex;
    justify-content: center;
    align-items: center;
}
