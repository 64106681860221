.space-modules__filters {
    display: flex;
    align-items: baseline;
    margin-bottom: 24px;
}

.space-modules-filter {
    width: 400px;

    &:not(:last-of-type) {
        margin-right: 40px;
    }
}

.space-modules-filter-reset {
    display: flex;
    align-items: center;
    background: var(--main-gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
}

.space-modules-filter-reset__title {
    margin-left: 9px;
    font-weight: 700;
    font-size: 16px;
    background: var(--main-gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.space-module {
    display: block;
    width: 100%;
    padding: 25px 50px;
    box-shadow: var(--default-shadow);
    border-radius: 30px;
    background-color: #fff;
    position: relative;

    &:not(:last-of-type) {
        margin-bottom: 30px;
    }
}

.space-module__semester {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #717171;
    margin-bottom: 5px;
}

.space-module__name {
    max-width: 90%;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.02em;
    font-family: "Exo-2";
    font-weight: 700;
}

.space-module__more-button {
    position: absolute;
    background:var(--main-gradient);
    background-clip: text;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    top: calc(50% - 10px);
    right: 50px;
    color: transparent;
}