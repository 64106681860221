.filters {
  display: flex;
  margin-bottom: 24px;
}

.filter__submitButton {
  width: 110px;
  margin: 24px 0;
  height: 40px;
}

.filters__item {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &:not(:last-of-type) {
    margin-right: 40px;
  }
}

.filters__itemName {
  width: 100%;
}

.filters__checkbox {
  width: fit-content;

  input {
    margin: 10px;
    cursor: pointer;
  }

  label {
    cursor: pointer;
  }
}