.alert {
    display: flex;
    padding: 12px 18px;
    gap: 12px;
    margin: 0 0 12px 0;
    border-radius: 15px;
    background-color: white;
    box-shadow: var(--default-shadow);

    svg {
        flex-shrink: 0;
    }
}