.switcher {
  display: inline-flex;
  align-items: center;
}

.switcher input {
  width: 0;
  height: 0;
  opacity: 0;
}

.switcher__slider {
  width: 40px;
  height: 20px;
  margin-right: 12px;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  transition: 0.4s;
  border-radius: 10px;
  background: var(--main-gradient);
  &::before {
    position: absolute;
    z-index: 1;
    bottom: 3px;
    left: 3px;
    width: 14px;
    height: 14px;
    content: "";
    transition: 0.4s;
    border-radius: 50%;
    background: var(--main-gradient);
  }
  &::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    background: #fff;
    border-radius: 10px;
  }
}

input:checked + .switcher__slider {
  background: #fff;
  border: inherit;
}

input:checked + .switcher__slider::before {
  left: 23px;
  background: #fff;
  bottom: 4px;
  width: 12px;
  height: 12px;
}

input:checked + .switcher__slider::after {
  background: var(--main-gradient);
}

.switcher__text {
  font-size: 14px;
}
