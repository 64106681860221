.buildingFormGroup {
  margin: 20px 0 30px;
  max-width: 333px;
}

.formGroupName {
  display: flex;
  flex-direction: row;
  margin-bottom: 17px;
}

.formGroupName__label {
  display: flex;
  flex-direction: column;
  font-family: 'Exo-2';
  font-weight: 600;
  font-size: 16px;
}

.formGroupName__input {
  box-sizing: border-box;
  width: 403px;
  height: 30px;

  border: 1px solid #000000;
}

.formGroupAddress {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 17px;
}

.formGroupAddress__label {
  display: flex;
  align-items: flex-end;
}

.formGroupAddress__input {
  box-sizing: border-box;
  width: 403px;
  height: 30px;
}

.formGroupZone {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 17px;
}

.formGroupZone__label {
  display: flex;
  align-items: flex-end;
}

.formGroupZone__select {
  box-sizing: border-box;
  height: 30px;
  margin-left: 12px;
  border: none;
  background-color: #fff;
  box-shadow: 2px 2px 6px rgba(217, 217, 217, 0.25);
  border-radius: 10px;
  font-size: 14px;
}

.buildingFormGroup__input {
  margin-bottom: 40px;
}
