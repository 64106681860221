.preview_saved {
  margin: 50px 230px 30px 90px;
  padding: 25px 90px 50px;
}

.preview_notSaved {
  width: 876px;
  border: #000 1px solid;
  padding: 25px 80px 50px 180px;
  margin: 50px 230px 30px 2px;
}

.preview__minmax {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 8px 0 40px;
}

.preview__minmaxMin {
  font-size: 18px;
  color: #000;
  margin: 5px 136px 3px 0;
}

.preview__prerequisites {
  width: 601px;
  margin: 30px 0 0;
}

.preview__info {
  margin: 0 0 50px;
  &:last-child {
    margin: 0;
  }
}

.preview__infoElement {
  margin: 10px 0 0;
}
