.emptySlot {
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin: 12px;

  border: 1px solid #C4C4C4;
}

.emptySlot_waiting {
  z-index: 100;

  cursor: pointer;
}

.emptySlot_showParametersMode {
  box-sizing: border-box;

  cursor: auto;

  border: 1px solid #C4C4C4;
}

.emptySlot_hideEmptySlot {
  border: none;
  cursor: auto;
}
