.trajectoryForm__buttons {
    display: flex;
    flex-direction: row;
    margin-bottom: 56px;
    justify-content: space-between;
}

.trajectoryForm__saveButtons {
    display: flex;
    justify-content: space-between;
}

.trajectoryForm__button {
    margin-right: 24px;
}

.trajectoryForm__main {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.trajectoryForm__list {
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.trajectoryForm__disabled {
    color: #000;
    text-align: center;
    margin-top: 122px;
    padding-left: 150px;
}