.assignment {
    padding: 12px 24px;
    box-shadow: var(--default-shadow);
    border-radius: 15px;
}

.assignment__button {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 600;
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
}

.assignment__buttonText {
    display: inline-flex;
    background-clip: text;
    background-image: var(--main-gradient);
    -webkit-text-fill-color: transparent;
}

.assignment__title {
    font-family: 'Exo-2';
    font-size: 16px;
    font-weight: 400;
    margin: 8px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.assignment__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.assignment__wrapper {
    display: flex;
    align-items: center;
    gap: 24px;
}

@media screen and (max-width: 768px) {
    .assignment {
        padding: 12px;
    }

    .assignment__footer {
        width: 100%;
        flex-direction: column;
        gap: 12px;
    }

    .assignment__wrapper {
        width: 100%;
        gap: 12px;
        justify-content: flex-start;
    }

    .assignment__button {
        align-self: flex-end;
        justify-self: flex-end;
    }
}
