.waves__title {
  font-size: 16px;
  font-weight: 500;
}

.waves__infoItem {
  font-size: 14px;
}

.waves__icon {
  width: 18px;
  height: 18px;
  position: relative;
  top: 3px;
  left: 5px;
  cursor: pointer;
}

.waves__edit {
  display: flex;
}
