.addEquipments {
    position: sticky;

    top: 69px;
    padding: 20px 0 20px;

    z-index: 4;

    background-color: white;
}

.addEquipments__button {
    display: flex;
    flex-direction: row;
    
    gap: 5px;

    color: #0B42DC;
    background-color: white;
}

.addEquipments__optionsContainer {
    font-family: 'Exo-2';
    font-size: 14px;
    border: 1px solid rgba(11, 66, 220, 0.3);
    border-radius: 6px;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.07);
    
    height: 34px;
    width: 159px;

    background-color: white;
    position: absolute;
    display: flex;
    flex-direction: column;

    z-index: 4;
}

.addEquipments__option {
    flex: 1;
    display:flex;
    align-items: center;
    padding-left: 14px;
    cursor: pointer;
}

.addEquipments__option:hover {
    background: rgba(11, 66, 220, 0.05);
}