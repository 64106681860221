@import "src/admin/NewModule/assets/styles/colors";

.container {
    display: flex;
    flex-direction: column;
    margin: 0;
    word-wrap: break-word;
}

.label {
    font-size: 18px;
    color: #000;
    margin: 0 6px 3px 0;
}

.input {
    min-height: 30px;
    box-sizing: border-box;
    margin: 0 6px 0 0;
    width: 100%;
    border: 1px solid #000;
    padding-left: 5px;

    &::placeholder {
      color: var(--placeholder-color);
    }
}

.number-label {
    display: flex;
    flex-direction: row;
    margin: 0 6px 0 0;
}

.number-input {
    max-width: 70px;
    margin: 0 12px 0 0;
    padding-left: 0;
    text-align: center;

    &::placeholder {
      font-size: 14px;
    }
}

.input-input {
    display: flex;
    flex-direction: row;
    align-content: center;
}

.bold {
  font-weight: bold;
}

.number-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
