.assignments {
  // display: flex;
  // align-items: center;
  // min-height: 22px;
  // word-break: break-word;
  // width: 100%;
  // position: absolute;
  // bottom: 0;
  // padding-left: 4px;
  // background: #E2FDFB;
  // // border-left: 3px solid #95D2DF;
  // cursor: pointer;
  // user-select: none;

  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: absolute;
  bottom: 0;
  background: #E2FDFB;
  cursor: pointer;
}

.assignments_selected {
  // background: var(--mock-color);
}
