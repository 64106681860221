.attendance__seeAllStudents_wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  padding-bottom: 15px;
}

.attendance__seeAllStudents {
  background: var(--main-gradient);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 10px;
}
