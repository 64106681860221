.textarea {
    display: flex;
    flex-direction: column;

    margin: 0;

    word-wrap: break-word;
}

.textarea__item {
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    margin: 0;
    padding: 5px 5px 0;
    resize: none;
    border: 1px solid #000;

    &::placeholder {
      color: #C4C4C4;
    }
}

.textarea__item_description {
    height: 126px;
}

.textarea__item_autosize {
    height: 50px;
}
.textarea__label {
    margin: 0 0 3px 0;
    color: #000;
    font-size: 18px;
}

.textarea__label_accent {
    font-weight: bold;
}