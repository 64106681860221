.create-invitation-button {
    margin-bottom: 30px;
}

.invitation-list-container {
    padding: 20px;
    box-shadow: var(--default-shadow);
    border-radius: 30px;
    background: #FFFFFF;
}

.invitation-list {
    margin: 0;
    box-shadow: none;
    font-family: 'Manrope';
    font-size: 14px;
    line-height: 20px;
}

.invitation-list__header {
    height: 44px;
    font-weight: 700;

    td {
        border-right: 1px dashed #ccc;
        border-bottom: 1px dashed #ccc;
    }

    td:last-of-type {
        border-right: none;
    }
}

.invitation-list__body {
    font-weight: 400;

    td {
        height: 44px;
        border-right: 1px dashed #ccc;
        border-bottom: 1px dashed #ccc;
    }

    td:last-of-type {
        border-right: none;
    }
}
