.goButton {
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
    border-radius: 15px;
    padding: 12px 12px 12px 12px;
    cursor: pointer;
}

.goButton_rotated {
    transform: rotate(180deg);
    box-shadow: 0px -2px 6px rgba(24, 20, 50, 0.13);
}

