.date {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 29px;

    padding: 9px 0;

    &_disabled {
        pointer-events: none;
        opacity: .6;
    }

    &_active {
        background-color: #F8F9FE;
    }

    &:last-child {
        border-right: 1px solid #ECEAE9;
    }
}

.date__additional:hover {
    z-index: 1;

    display: block;

    width: max-content;

    & > div {
        color: #000;
        background-color: #fff;
        border: 1px solid #F3F3F3;
    }

    & .meetingInfo {
        width: max-content;
    }
}

.date__info {
    position: relative;

    display: flex;
    justify-content: center;

    width: 26px;
    padding-left: 3px;

    font-size: 13px;
    line-height: 16px;
    font-family: Exo-2, sans-serif;
    font-weight: 500;
}

.date__info_newMonth {
    border-left: 1px solid #A7A8AA;
}

.date__info_month {
    position: absolute;
    top: -100%;
    left: 30%;
    z-index: 1;

    font-size: 9px;
    font-family: Exo-2, sans-serif;
    font-weight: 500;
}

.date__additional {
    position: absolute;
    top: 70%;
    left: 80%;

    z-index: 1;

    display: block;

    width: max-content;

    & > div {
        color: #000;
        background-color: #fff;
        border: 1px solid #F3F3F3;
    }

    & .meetingInfo {
        width: max-content;
    }
}

.date__additional_positionRight {
    left: unset;
    right: 20px;
}

.meetingInfo {
    width: 0;
}

