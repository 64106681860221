.spoiler {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 15px 25px;
  border: 1px solid black;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
}

.spoiler__tittle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.spoiler__triangle {
  position: relative;

  width: 0;
  height: 0;
  margin-right: 24px;

  transition: transform 0.5s;
  transform: rotate(0deg);

  border: 10px solid transparent;
  border-top-width: 6px;
  border-right-width: 0px;
  border-bottom-width: 6px;
  border-left-color: black;

  &::after {
    position: absolute;
    top: -3px;
    right: 3px;

    width: 0;
    height: 0;

    content: "";
    transition: opacity 0.7s;

    opacity: 1;
    border: 5px solid transparent;
    border-top-width: 3px;
    border-right-width: 0px;
    border-bottom-width: 3px;
    border-left-color: white;
  }
}

.spoiler__staticTriangle {
  &::after {
    border-left-color: black;
  }
}

.spoiler__triangle_active {
  transform: rotate(90deg);

  &::after {
    opacity: 1;
    border-left-color: white;
  }
}

.spoiler__list {
  overflow: hidden;
  overflow-y: auto;
  max-height: 500px;
  margin-left: 65px;
  transition: max-height 0.7s ease-in;
  &::-webkit-scrollbar-track {
    border: 1px solid #f6f6f6;
    border-radius: 10px 30px 10px 10px;
  }
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(196, 196, 196, 0.2);
    border-radius: 6px;
  }
}

.spoiler__module_list {
  display: flex;
  flex-direction: column;
  margin-left: 0;
}

.spoiler__list_height_null {
  max-height: 0;

  transition: max-height 0.2s ease-out;
}

.spoiler__item {
  width: 100%;
  margin-bottom: 5px;
  padding: 4px 12px;
  margin-top: 15px;
  background-color: #AFD6f0;

  &:last-child {
    margin-bottom: 0;
  }
}

.spoiler__item_module {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 5px;
  padding: 4px 12px;
  margin-top: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}
