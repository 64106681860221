.eventsList__header {
  margin: 0 0 20px 182px;
}

.eventsList__event {
  display: flex;
  align-items: center;
  flex-direction: row;
  &:last-child {
    margin-bottom: 30px;
  }
}

.eventsList__event_transparent {
  opacity: 0.5;
}

.eventsList__icon {
  margin: 0 0 0 17px;
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.eventsList__wideIcon {
  margin: 0 0 0 37px;
}

.eventsList__arrows {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.eventsList__container {
  display: flex;
  flex-direction: row-reverse;
}

.eventsList__mainBlock {
  flex-grow: 1;
}

// eventListTabs

.eventListTabs {
  display: flex;
}

.eventListTabs__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 154px;
  height: 60px;
  margin-right: 7px;
  margin-bottom: 7px;
  padding: 5px;
  font-size: 14px;
  text-align: center;
  box-shadow: var(--default-shadow);
  border-radius: 10px;
  cursor: pointer;

  &:last-child {
    margin-left: -1px;
  }
}

.eventListTabs__item_active {
  cursor: default;
  background: var(--extra-gradient);
  mix-blend-mode: normal;
}

.eventListTabs__text {
  padding: 20px 30px;
  cursor: pointer;
}
