.interval {
    display:flex;
}

.interval__longDash {
    width:24px;
    height: 1px;
    margin-top: 18px;
    margin-left: 12px;
    margin-right: 12px;
}

.interval__startTextInput {
    width:48px;
    height:20px;
    background-color: transparent;
    border:none;
    outline: none;
}

.interval__endTextInput {
    width:48px;
    height:20px;
    background-color: transparent;
    border:none;
    outline: none;
}

.interval__grayBox {
    padding: 9px;
    background-color: rgba(249, 249, 249, 1);
    border: 1px solid #C4C4C4;
}

.interval__grayBox:nth-child(2), .interval__grayBox:nth-child(5){
    border-left:none;
}

.interval__clock {
    width:19px;
    height: 19px;
}

.interval__delete {
    width:19px;
    height: 19px;
    fill:  #C4C4C4;
    margin-left: 15px;
    margin-top: 12px;
    cursor: pointer;
}