$background-color: rgba(11, 66, 220, 0.05);

.loadReportButton_wrapper {
    min-width: 208px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadReportButton {
    padding: 1px 0 1px 1px;

    width: fit-content;

    outline: 2px solid transparent;

    font-family: 'Exo 2', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;

    border-radius: 6px;
}

.loadReportButton_text {
    display: flex;
    gap: 7px;
    align-items: center;
    justify-content: center;

    background: linear-gradient(90deg, #00AA57, #00733B);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.loadReportButton:hover {
    padding: 0;
    background: $background-color;
    outline-color: $background-color;
}

.loadReportButton:hover .loadReportButton_text {
    color: #000;
    -webkit-text-fill-color: unset;
}

.loadReportButton:active {
    background: $background-color;

    outline-color: transparent;
}

.loadReportButton:active .loadReportButton_text {
    color: transparent;
    background: linear-gradient(90deg, rgba(0, 170, 87, .5), rgba(0, 115, 59, .5));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}