.Tabs {
    display: flex;
    gap: 18px;
}

.Tabs__input {
    display: none;
}

.Tabs__input_active + .Tabs__title {
    background: var(--main-gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Tabs__input_active + .Tabs__title::after {
    content: "";
}

.Tabs__title {
    position: relative;
    font-family: "Exo-2";
    font-weight: 600;
    padding: 0 0 4px 0;
    cursor: pointer;

    &::after {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 2px;
        background: var(--main-gradient);
    }
}
