.dropDown {
    width: 100%;

    position: relative;
}
.dropDown__body {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    padding: 1px;

    border-radius: 6px;
}

.dropDown__body_default {
    background-color: #C7C7C7;
}

.dropDown__body_default:hover {
    background-color: #0B42DC;
}

.dropDown__body_default:hover rect {
    fill: #F3F6FD;
}

.dropDown__body_default:hover path {
    stroke: #070709;
}

.dropDown__body_gradient {
    background: linear-gradient(90deg, #0B42DC , #17E984);
}

.dropDown__body_gradient rect {
    fill: #F8F9FE;
}

.dropDown__body_gradient path {
    stroke: #0B42DC;
}

.dropDown__body_gradient .dropDown__body_container {
    background-color: #F8F9FE;
}

.dropDown__body_container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    padding: 7px 5px 7px 12px;

    background-color: #FFF;

    border-radius: 5px;

    pointer-events: none;
}


.dropDown__currentOption {
    display: flex;
    align-items: center;

    font-size: 13px;
    line-height: 16px;
    color: #161719;
    font-family: Exo-2, sans-serif;
    letter-spacing: -0.03em;
    font-weight: 500;
}

.dropDown__arrow {
    display: flex;
    align-items: center;
}

.dropDown__options {
    position: absolute;
    top: 110%;

    width: 100%;

    background-color: #FFF;

    border: 1px solid rgba(11, 66, 220, 0.3);
    border-radius: 6px;
}