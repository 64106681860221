.ruler__timeSlot {
    width:10px;
    height:80px;
    padding: 0px;
    position: sticky;
    top: 0;
    z-index: 10;
    border: none;
    padding: none;
}

.ruler__timeSlotContent {
    position: absolute;
    width:1px;
    height: 1px;
}

.ruler__timeSlotContentText {
    position: absolute;
    height: 15px;
    width: 90px;
    left: -46px;
    top: -15px;
    transform: rotate(-90deg);
    border: none;
    font-size: 10px;
    padding: 0px;
}
