.studentModulesPage {
  margin-bottom: 50px;
}

.studentModulesPage__header {
  position: sticky;
  top: 0;
  border-bottom: 1px solid #ECEAE9;
  background-color: white;

  z-index: 3;

  height: 64px;
}

.StudentModulesPage__info p {
  color: #c4c4c4;
}

.studentModulesPage h1 {
  margin-bottom: 4px;
}

.studentModulesPage__title {
  font-family: "Exo-2";
  font-weight: bold;
  font-size: 26px;
}

.studentModulesPage__period {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #c4c4c4;
  padding: 10px 0;

  border-bottom: 1px solid #ECEAE9;
}

@media screen and (max-width: 980px) {
  .studentModulesPage {
    width: 100%;

    margin: 0 auto;
    margin: 0;
  }

  .studentModulesPage__info h1 {
    font-size: 22px;
    margin-top: 0;
  }

  .studentModulesPage__brightness {
    filter: brightness(55%);
  }

  .studentModulesPage__info p {
    font-size: 14px;
  }

  .studentModulesPage__info {
    margin: 0 7% 32px;
  }
}

@media screen and (max-width: 480px) {

  .studentModulesPage__info {
    margin: 0 6% 32px;
  }

  .studentModulesPage__period {
    border-top: 1px solid #ECEAE9;
  }
}