.studentWorkload {
    display: flex;
    align-items: center;
    gap: 30px;

    padding-left: 30px;
    margin-bottom: -1px;

    border-bottom: 1px solid #ECEAE9;
    border-top: 1px solid #ECEAE9;

    &_active {
        position: relative;
        z-index: 3;

        height: 39px;

        background-color: #F8F9FE;

        border: none;
    }

    &:hover {
        position: relative;
        z-index: 2;

        height: 39px;

        background-color: #F8F9FE;

        border: none;
    }

    &__title {
        padding: 8px 0 8px 0;
        width: 140px;
        font-size: 14px;
        font-family: Exo-2, sans-serif;
        font-weight: 500;
    }

    &__label {
        padding: 8px 0 8px 16px;
        width: 82px;
        font-size: 13px;

        display: flex;
        align-items: center;

        position: relative;

        color: #6D6E6F;

        font-family: Exo-2, sans-serif;
        font-weight: 500;

        cursor: pointer;

        &:focus-within {
            padding-left: 0;
            color: transparent;
            border: none;
        }

        &:first-child {
            padding-left: 30px;
        }

        &:hover:not(.studentWorkload__label:focus-within) {
            border: 1px solid #DBE1F0;
            height: 39px;
            padding-left: 15px;
        }
    }

    &__input {
        padding: 0 0 0 16px;
        height: 0;
        width: 0;
        font-size: 13px;

        border: none;
        outline: none;

        &:focus {
            position: absolute;
            left: 0;
            width: 82px;
            height: 39px;

            border: 1px solid #0B42DC;

            padding-left: 15px;
        }

    }
}