.filters {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
}

.filters__row {
  display: flex;
  width: 100%;
  gap: 12px;
}

.filters__calendarName {
  // margin-bottom: 5px;
}

.filters__buttons {
  display: flex;
  align-items: center;
  margin: 24px 0;
  font-size: 14px;
  /* убрать mr после реворка саджестов */
}

.filters__resetButton {
  display: flex;
  align-items: center;
  margin-right: 48px;
  background: var(--main-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.filters__resetButtonText {
  margin-left: 9px;
  font-weight: 700;
  font-size: 16px;
  background: var(--main-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.filters__submitButton {
  color: #fff;
  padding: 12px 17px;
  border-radius: 10px;
  background: var(--main-gradient);
  cursor: pointer;
}

// input

.filters__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.filters__itemName {
  width: 100%;
  // margin-bottom: 5px;
}

.filters__itemInput {
  // height: 80px;
  width: 100%;
}

// calendar

.filters__calendar {
  width: 100%;
  // width: 30%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .calendar {
    box-shadow: none;
  }

  .button_filterCalendarBlack {
    width: 90%;
    height: 100%;
    background: red;
    box-shadow: none;
  }

  .react-datepicker__close-icon {
    position: relative;
  }

  .button_filterCalendar {
    box-shadow: none;
  }
}

.filters .react-datepicker-wrapper {
  width: 100%;
  display: flex;
}
