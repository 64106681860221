.page__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.hide {
    display: none;
}
