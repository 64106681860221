.universitySpecialDaysView__extraType {
    margin-bottom: 15px;
}

.universitySpecialDaysView__title {
    font-family: 'Exo-2';
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 11px;
}

.universitySpecialDaysView__buttonPanel {
    align-self: flex-end;
}
