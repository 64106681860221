.moduleTabsContent {
    overflow: scroll;

    height: 400px;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}