.button {
  width: 155px;
  box-sizing: border-box;
  color: #000;
  font-size: 14px;
  max-height: 52px;
  border-radius: 10px;
  padding: 10px 11px;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border: none;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}

.button_undesirable {
  width: max-content;
  border: 3px solid var(--mock-color);
  height: min-content;

  //&:hover {
  //  background-color: var(--mock-color);
  //}
}

.button_send {
  width: max-content;
  background-color: var(--mock-color);

  //&:hover {
  //  background-color: #f3ae00;
  //}
}

.button_send_module {
  // width: max-content;
  background-color: var(--mock-color);

  //&:hover {
  //  background-color: #f3ae00;
  //}
}

.button_ok {
  background-color: var(--mock-color);
  width: 78px;
  border-radius: 16px;
  height: 28px;
  align-self: center;
  margin-top: 18px;

  //&:hover {
  //  background: #ffbe1b;
  //}
}

.button_add {
  width: max-content;
  height: 100%;
  padding: 0;
}

.button_add_border {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #aaaaaa;
  border-radius: 8px;
  width: max-content;
  height: 30px;
  padding: 5px 5px;
  margin-right: 11px;
}

.button_disabled {
  background: #c4c4c4;
}
