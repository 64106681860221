@import "../../../../scheduleStyles/filterColors";

.weekScheduleHeader {
  position: relative;
  width: 100%;
  user-select: none;
}

.weekScheduleHeader__eventFilterMenu {
  position: absolute;
  bottom: 0;
  left: 4px;
}

.weekScheduleHeader__dates {
  display: flex;
  justify-content: flex-end;

  width: calc(100% - 32px - 15px);
  height: 25px;
  margin-bottom: 12px;
  margin-left: 32px;
}

.date {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 7);
  margin-right: 2px;
}

.dayOfMonth_isToday {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 25px;
  height: 25px;
  background: var(--main-gradient);
  color: #fff;

  border-radius: 50%;
}

@media screen and (max-width: 1200px) {
  .weekScheduleHeader__dates {
    width: calc(100% - 15px - 35px);
  }
}

@media screen and (max-width: 768px) {
  .weekScheduleHeader__eventFilterMenu {
    bottom: 11px;
    left: 4px;
  }

  .weekScheduleHeader__dates {
    width: calc(100% - 15px - 20px);
  }
}

@media screen and (max-width: 480px) {
  .weekScheduleHeader__eventFilterMenu {
    bottom: 18px;
    left: 7px;
  }
  .weekScheduleHeader__dates {
    font-size: 12px;
  }

  .date {
    flex-direction: column;

    padding: 0 5px;
    span {
      padding: 5px 0;
    }
  }
}

@media print {
  .weekScheduleHeader__dates {
    width: calc(100% - 32px);
  }
}
