.module-statistics__container {
    display: flex;
    flex-direction: column;
    margin: 0 24px;
}

.menu_hide {
    display: none;
}

.header__title {
    cursor: pointer;

    &:hover {
        color: #0B42DC;

        border-bottom: 1px solid #0B42DC;
    }
}

.module-statistics__back {
    display: flex;
    align-items: center;
    background: var(--main-gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    margin-bottom: 15px;
}

.module-statistics__back-title {
    margin-left: 9px;
    font-weight: 700;
    font-size: 16px;
    background: var(--main-gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.module-statistics__title {
    font-family: 'Exo-2';
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    margin-bottom: 24px;
    width: fit-content;

    &:hover {
        text-decoration: underline;
    }
}

.module-statistics-tabs__list {
    display: flex;
    gap: 16px;
    margin: 24px 0;
}

.module-statistics-tab__link {
    color: #0d0d0d;
    font-weight: 600;
    text-align: center;
    position: relative;
    border-image-slice: 1;
    background: #0d0d0d;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0 0 4px 0;
    cursor: pointer;

    &::before {
      content: '';
      width: 0;
      height: 2px;
      background-image: var(--main-gradient);
      position: absolute;
      top: 100%;
      left: auto;
      right: 0;
      transition: width .1s ease;
      transition-delay: .1s;
    }

    &_selected {
      position: relative;
      background: var(--main-gradient);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: default;

      &::before {
        content: '';
        width: 100%;
        left: 0;
        right: auto;
        transition-delay: 0s;
      }
    }

    &_disabled {
        opacity: .3;
        cursor: not-allowed;
    }
}

.module-statistics__report {
    border-radius: 30px;
    overflow-x: scroll;
    width: fit-content;
    max-width: 100%;
    position: relative;

    td, th {
        border: 1px dashed #C4C4C4;
    }
}

.module-statistics-report__header {
    z-index: 2;
    tr:first-of-type {
        border-radius: 30px 30px 0px 0px;
        td {
            border-top: none;
        }
        td:first-of-type {
            border-left: none;
            border-radius: 30px 0px 0px 0px;
            padding: 12px;
            font-family: 'Exo-2';
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }
        td:last-of-type {
            border-right: none;
            border-radius: 0px 30px 0px 0px;
        }
    }
}

.module-statistics-report__evaluation-point {
    padding: 12px;
    font-family: 'Exo-2';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    min-width: 170px;

    &:hover {
        text-decoration: underline;
    }
}

.module-statistics__mode-switcher {
    width: fit-content;
    display: flex;
    user-select: none;

    &_disabled {
        pointer-events: none;
        opacity: .4;
        cursor: not-allowed;

        > label {
            cursor: not-allowed;
        }
    }
}

.module-statistics-report__evaluation-skill {
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    min-width: 170px;
}

.module-statistics-report__content {
    tr td:first-of-type, th:first-of-type {
        border-left: none;
    }
    tr td:last-of-type {
        border-right: none;
    }
    tr:last-of-type {
        td, th {
            border-bottom: none;
        }
        th:first-of-type {
            border-left: none;
            border-radius: 0px 0px 0px 30px;
        }
        td:last-of-type {
            border-right: none;
            border-radius: 0px 0px 30px 0px;
        }
    }
}

.module-statistics__filters {
    margin: 25px 0;
    display: flex;
}

.module-statistics__skill-type-filter {
    min-width: 260px;
    margin-right: 25px;
    z-index: 4;
}

.module-statistics__filter-label {
    display: block;
    width: fit-content;
    font-family: 'Exo-2';
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
    user-select: none;
}

.module-skill-type-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 38px;
    padding: 0 12px;
    border-radius: 10px;
    box-shadow: var(--default-shadow);
    background-color: #fff;
    position: relative;
    cursor: pointer;

    &_active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
    }
}

.module-skill-type-filter__selected-option {
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: block;
}

.module-skill-type-filter__option-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
    background-color: #FFF;

    div {
        padding: 4px 12px;
        font-size: 14px;
        word-break: break-word;
        border-top: 1px solid #ccc;
        cursor: pointer;
    }
}

.module-statistics__filter {
    width: 260px;
    z-index: 3;
    margin-right: 25px;
}