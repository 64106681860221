.unitOfTimeSelect {
    width: 156px;
    position: relative;
    font-size: 14px;
    cursor: pointer;
}

.unitOfTimeSelect__select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 29px;
    padding: 0 12px;
    background: #FFF;
    border-radius: 10px;
    box-shadow: var(--default-shadow);
}

.unitOfTimeSelect__select_isOpen {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.arrowIcon {
    transition: transform .1s;
}

.arrowIcon_isOpen {
    transform: rotate(180deg);
}

.unitOfTimeSelect__optionsList {
    width: 100%;
    position: absolute;
    z-index: 5;
    box-shadow: var(--default-shadow);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
}

.unitOfTimeSelect__option {
    display: flex;
    align-items: center;
    height: 29px;
    padding: 0 12px;
    position: relative;
    background: #FFF;

    &::before {
        content: '';
        width: 144px;
        height: 1px;
        position: absolute;
        top: 0;
        left: 6px;
        background: #F6F6F6;
    }
}

@media screen and (max-width: 1200px) {
    .unitOfTimeSelect {
        display: none;
    }
}

@media print {
    .unitOfTimeSelect {
        display: none;
    }
}
