.actionButton {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 516px;
  margin: 0.5px 0 0 180px;
}

.actionButton__circle {
  display: flex;
  align-items: center;
  flex-direction: column;

  color: #c4c4c4;
}

.actionButton__singleCircle {
  margin: -5.5px 0 0 0;
}

.actionButton__buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 516px;
}

.actionButton__eventsButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  padding: 5px 0 5px 180px;
}

.actionButton__eventButton {
  width: 245px;
  height: 44px;
  padding: 12px 0;

  text-align: center;
  white-space: nowrap;

  border: 1px solid black;
  background-color: #afd6f0;
}

.actionButton__connectionsButtons {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 5px 0 5px 180px;
}

.actionButton__connectionButton {
  width: 516px;
  height: 44px;
  margin-bottom: 10px;
  padding: 12px 0;

  text-align: center;
  white-space: nowrap;

  border: 1px solid black;
}

.actionButton__buttons__removeButton {
  margin: -5px 0 0 360px;
}

.actionButton__saveConnection {
  position: sticky;
  margin: 0 0 0 375px;
  bottom: 100px;
}
