.statusCheckList {
    display: flex;
    flex-direction: column;
    padding: 8px 18px;
    margin-top: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.26);
}

.statusCheckList__label {
    font-weight: 300;
    font-size: 12px;
    color: #000;
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    &:last-child {
        margin-bottom: 0px;
    }
}

.statusCheckList__input {
    position: relative;

    width: 12px;
    height: 12px;
    margin-right: 9px;

    outline: none;

    appearance: none;
    &::before {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 12px;
        height: 12px;

        content: '';
        transform: translate(-50%, -50%);

        border: 1px solid #adb5bd;
        background-color: white;
        box-sizing: border-box;
      }
    &:checked::before {
        border: 1px solid rgb(68, 66, 66);
    }
    &:checked::after {
        position: absolute;
        top: 50%;
        left: 50%;

        visibility: visible;

        width: 8px;
        height: 6px;

        content: '';
        transform: translate(-50%, -50%);

        background-image: url("../../../assets/Check.svg");
      }
}