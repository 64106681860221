.slotGroup {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  cursor: auto;
}

.slotGroup__figure {
  box-sizing: border-box;
  width: 16px;
  height: 16px;

  cursor: pointer;

  border: 1px solid #000000;
  border-radius: 50%;
  background: #ffffff;
}

.slotGroup__figure_dashed {
  border: 1px dashed #000000;
}

.slotGroup__figure_active {
  border-width: 4px;
}

.slotGroup__figure_withoutPointer {
  cursor: auto;
}

.slotGroup__figure_readyToRemove {
  cursor: pointer;

  border-width: 2px;
  border-color: #ff0000;
}

.slotGroup__figure_readyToConnect {
  border: 1px solid #c4c4c4;
}

.slotGroup__figure_setPrevious {
  cursor: auto;

  border: 1px solid var(--mock-color);
  background: var(--mock-color);
}

.slotGroup__figure__entity_warning {
  border: 2px dashed #ff0000;
}

.slotGroup .slotGroup__toolTipText {
  position: absolute;
  z-index: 100;
  bottom: 125%;
  left: 50%;

  visibility: hidden;

  width: 120px;
  margin-left: -60px;
  padding: 5px 0;

  transition: opacity 0.3s;
  text-align: center;

  opacity: 0;
  color: #000000;
  border: 2px solid #ff0000;
  border-radius: 6px;
  background-color: #ffffff;
}

.slotGroup .slotGroup__toolTipText::after {
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 50%;

  margin-left: -5px;

  content: "";

  border-width: 5px;
  border-style: solid;
  border-color: #ff0000 transparent transparent transparent;
}

.slotGroup:hover .slotGroup__toolTipText {
  font-size: 12px;
  display: block;
  visibility: visible;

  opacity: 1;
}
