.module__name {
    border: 1px solid #B0B0B0;
    border-bottom: none;
    font-size: 12px;
    width: 150px;
}

.module__name::-webkit-scrollbar {
    display: none;
}

.module__tooltip {
    height: inherit;
    display: block;
    overflow: hidden;

    &:hover {
        position: relative;
        text-overflow: initial;
        height: fit-content;
        background: #ffffff;
        white-space: normal;
        text-decoration: underline;
    }
}