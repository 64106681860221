.items {
    margin-top: 15px;

    display: flex;
    flex-direction: column;
    //padding-left: 44px;

    &__container {
        display: grid;
        grid-template-columns: 227px 189px 306px;

        height: 39px;

        border-bottom: 1px solid #ECEAE9;
        border-top: 1px solid #ECEAE9;

        margin-bottom: -1px;

        &:last-child {
            border-bottom: 1px solid #fff;
        }

        &:hover {
            position: relative;
            z-index: 2;

            background-color: #F8F9FE;
            border: none;
            height: 39px;

            &:last-child {
                height: 39px;
            }
        }

        &_module {
            height: 39px;
            border-bottom: 1px solid #fff;
            align-items: center;


            &:hover {
                border: none;
                height: 39px;
            }
        }

        &_priorities {
            height: 39px;
            border-top: 1px solid #fff;
            align-items: center;

            &:hover {
                border: none;
                height: 39px;
            }
        }
    }

    &__item {
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;

        padding: 9px 0 11px 10px;

        &:first-child {
            padding-left: 44px;
        }

        &:last-child {
            padding: 0 0 0 10px;
        }

        &_noVerticalPadding {
            font-size: 14px;
            line-height: 17px;

            display: flex;
            align-items: center;

            padding: 9px 0 11px 0;
        }
    }

    &__nav {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #0B42DC;
        font-family: Exo-2, sans-serif;
    }
}

.items__result {
    font-size: 13px;
    line-height: 16px;
    color: #000;

    &_blue {
        color: #0B42DC;
    }

    &_green {
        color: #00733B;
    }

    &_gray {
        color: #ABABAB;
    }
}

.selectionButton {
    padding: 0 12px;
    height: 29px;

    &_noPadding {
        padding: 0;
    }
}