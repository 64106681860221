.savedEvent {
  position: relative;
  cursor: pointer;
  width: 516px;
  min-height: 53px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 40px 16px 66px;
  margin-bottom: 19px;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
}

.savedEvent__title {
  font-size: 18px;
  font-weight: 700;
  margin: 0 18px 0 0;
  max-width: 370px;
  overflow: hidden;
}

.savedEvent__title_name {
  font-weight: normal;
  margin: 0;
  word-wrap: break-word;
}

.savedEvent__icons {
  width: 50px;
  display: flex;
  justify-content: space-between;
}

.savedEvent_valid {
  border: 1px solid #000;
}

.savedEvent_error {
  border: 1px solid red;
}

.savedEvent__warning {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  left: 32px;
}

.savedEvent__warning_success {
  background: #43E28CD1;
}

.savedEvent__warning_warning {
  background: #E49702;
}

.savedEvent__warning_danger {
  background: #FF0000;
}
